import {Dispatch} from "redux";
import {dailyGGRLastSevenDaysActionTypes, dailyGGRLastSevenDaysAction} from "../../types/dashboard/dailyGGRLastSevenDays";
import axios from "axios";

export const dailyGGRLastSevenDays = (operator:string): any => {
    return async (dispatch: Dispatch<dailyGGRLastSevenDaysAction>) => {
        try {
            dispatch({type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_ggr_last_seven_days?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_TODAY_LAST_SEVEN_DAYS_DATA_ERROR, payload: 'Something wend wrong check dailyGGRLastSevenDays action controller'});
        }
    }
}
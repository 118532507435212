import {
    CountryLeaguesAction,
    CountryLeaguesActionTypes,
    CountryLeaguesState}
    from "../../types/sportsdata/countryLeaguesTypes";

const initialState: CountryLeaguesState = {
    countryLeaguesData: [],
    loading: false,
    error: null,
};

export const countryLeaguesReducer = (state = initialState, action: CountryLeaguesAction): CountryLeaguesState => {
    switch (action.type) {
        case CountryLeaguesActionTypes.FETCH_COUNTRY_LEAGUES_DATA:
            return { ...state, loading: true, error: null, countryLeaguesData: [] };
        case CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_SUCCESS:
            return { ...state, loading: false, error: null, countryLeaguesData: action.payload };
        case CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, countryLeaguesData: [] };
        default:
            return state;
    }
};

import {Dispatch} from "redux";
import {CountryLeaguesAction, CountryLeaguesActionTypes} from "../../types/sportsdata/countryLeaguesTypes";

import axios from "axios";

export const getCountryLeaguesData = (id:string): any => {
    return async (dispatch: Dispatch<CountryLeaguesAction>) => {
        try {
            dispatch({type: CountryLeaguesActionTypes.FETCH_COUNTRY_LEAGUES_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/countries/${id}/leagues`
            });
            dispatch({type: CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_ERROR, payload: 'Something wend wrong check COUNTRY Leagues action controller'});
        }
    }
}
export interface getBetPublisherState {
    getBetPublisherData: any;
    loadingStatus: boolean;
    errorMessage: null | string;
}


export enum getBetPublisherActionTypes {
    FETCH_BETPUBLISHER_DATA = "FETCH_BETPUBLISHER_DATA",
    FETCH_BETPUBLISHER_DATA_SUCCESS = "FETCH_BETPUBLISHER_DATA_SUCCESS",
    FETCH_BETPUBLISHER_DATA_ERROR = "FETCH_BETPUBLISHER_DATA_ERROR",
    CLEAR_BETPUBLISHER_STATE = "CLEAR_BETPUBLISHER_STATE",
}

export type getBetPublisherAction =
    | { type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA }
    | { type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_SUCCESS; payload: any[] }
    | { type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_ERROR; payload: string }
    | { type: getBetPublisherActionTypes.CLEAR_BETPUBLISHER_STATE; payload: {}};


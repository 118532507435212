export interface updateCountryState {
    updateCountryData: any[];
    responceStatus: boolean;
    error: null | string;
}


export enum updateCountryActionTypes {
    FETCH_UPDATE_COUNTRY_DATA = "FETCH_UPDATE_COUNTRY_DATA",
    FETCH_UPDATE_COUNTRY_DATA_SUCCESS = "FETCH_UPDATE_COUNTRY_DATA_SUCCESS",
    FETCH_UPDATE_COUNTRY_DATA_ERROR = "FETCH_UPDATE_COUNTRY_DATA_ERROR",
}

export type updateCountryAction =
    | { type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA }
    | { type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_SUCCESS; payload: any[] }
    | { type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_ERROR; payload: string };
import {AdminNotificationAction, AdminNotificationState, AdminNotificationActionTypes} from "../../../../types/admins/operators/admin/adminNotificationTypes";

const initialState: AdminNotificationState = {
    adminNotificationData: [],
    loading: false,
    error: null,
};

export const adminNotificationReducer = (state = initialState, action: AdminNotificationAction): AdminNotificationState => {
    switch (action.type) {
            case AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA:
            return { ...state, loading: true, error: null, adminNotificationData: [] };
        case AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_SUCCESS:
            return { ...state, loading: false, error: null, adminNotificationData: action.payload };
        case AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, adminNotificationData: [] };
        default:
            return state;
    }
};

import {confirmResultsHalfTimeState, confirmResultsHalfTimeActionTypes, confirmResultsHalfTimeAction } from "../../types/sportsdata/confirmResultsHalfTime";


const initialState: confirmResultsHalfTimeState = {
    confirmResultsHalfTimeData: [],
    loading: false,
    error: null,
};

export const confirmResultsHalfTimeReducer = (state = initialState, action: confirmResultsHalfTimeAction): confirmResultsHalfTimeState => {
    switch (action.type) {
        case confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA:
            return { ...state, loading: true, error: null, confirmResultsHalfTimeData: [] };
        case confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_SUCCESS:
            return { ...state, loading: false, error: null, confirmResultsHalfTimeData: action.payload };
        case confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, confirmResultsHalfTimeData: [] };
        default:
            return state;
    }
};


export interface dailyGGRCurrentMonthGraphState {
    dailyGGRCurrentMonthGraphData: any;
    loading: boolean;
    error: null | string;
}


export enum dailyGGRCurrentMonthGraphActionTypes {
    FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA = "FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA",
    FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_SUCCESS = "FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_SUCCESS",
    FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_ERROR = "FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_ERROR",
}

export type dailyGGRCurrentMonthGraphAction =
    | { type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA }
    | { type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_SUCCESS; payload: any[] }
    | { type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_ERROR; payload: string };


export interface MarketDetailsState {
    marketsDetailsData: any[];
    loading: boolean;
    error: null | string;
}


export enum MarketDetailsActionTypes {
    FETCH_MARKET_DETAILS_DATA = "FETCH_MARKET_DETAILS_DATA",
    FETCH_MARKET_DETAILS_DATA_SUCCESS = "FETCH_MARKET_DETAILS_DATA_SUCCESS",
    FETCH_MARKET_DETAILS_DATA_ERROR = "FETCH_MARKET_DETAILS_DATA_ERROR",
}

export type MarketDetailsAction =
    | { type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA }
    | { type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_SUCCESS; payload: any[] }
    | { type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_ERROR; payload: string };
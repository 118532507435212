import {SmartbetPreviewActionTypes, SmartbetPreviewAction, SmartbetPreviewState} from "../../../types/composer/SmartBet/smartbetPreview";

const initialState: SmartbetPreviewState = {
    smartbetPreviewData: [],
    loading: false,
    error_responce: null,
};

export const smartBetPreviewReducer = (state = initialState, action: SmartbetPreviewAction): SmartbetPreviewState => {
    switch (action.type) {
        case SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA:
            return { ...state, loading: true, error_responce: null, smartbetPreviewData: [] };
        case SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, smartbetPreviewData: action.payload };
        case SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, smartbetPreviewData: [] };
        default:
            return state;
    }
};

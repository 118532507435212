import {Dispatch} from "redux";
import {EditAdminOperatorActionTypes, EditAdminOperatorAction} from "../../../../types/admins/operators/admin/editAdminOperatorTypes";
import axios from "axios";
interface OperatorProps {
    enabled: boolean;
    id: string;
    name: string;
    company_name: string;
    income_tax: number;
    currency: string;
    language: string;
    timezone: string | null;
    webhook_url: string | null;
    website_url: string;
    pam_token: string;
    betengine_token: string;
    betcard_token: string;
    is_active: boolean;
    admins: string[];
    created: string;
    updated: string;
}

export const editAdminOperatorData = (id:string, data: OperatorProps): any => {
    return async (dispatch: Dispatch<EditAdminOperatorAction>) => {
        try {
            dispatch({type: EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA});
            const response = await axios({
                method: 'patch',
                url: `/api/operators/${id}/info`,
                data: data,
                withCredentials: false
            });
            dispatch({type: EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA_ERROR, payload: 'Something wend wrong check Edite Admin Operators action controller'});
        }
    }
}
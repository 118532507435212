import {Dispatch} from "redux";
import {SmartBetsCreateNewActionTypes, SmartBetsCreateNewAction} from "../../../types/composer/SmartBet/smartBetsCreateNew";
import axios from "axios";




export const getSmartBetsCreateNewData = (data: any): any => {
    return async (dispatch: Dispatch<SmartBetsCreateNewAction>) => {
        try {
            dispatch({type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA});
            const response = await axios({
                method: 'post',
                data: data,
                url: '/api/composer/smartbets',
                withCredentials: false
            });
            dispatch({type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join('/n');
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({
                type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_ERROR,
                payload: errorMessage
            });
        }
    }
}
export const clearSmarbetCreateDataState = () => {

    return (dispatch: Dispatch<SmartBetsCreateNewAction>) => {
        dispatch({
            type: SmartBetsCreateNewActionTypes.CLEAR_SMART_BETS_CRATE_STATE,
            payload: {}
        });
    };
};
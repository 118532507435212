import {ResultsActionTypes, ResultsAction, ResultsState} from "../../types/sportsdata/resultsTypes";


const initialState: ResultsState = {
    resultsData: [],
    loading: false,
    error: null,
};

export const resultsReducer = (state = initialState, action: ResultsAction): ResultsState => {
    switch (action.type) {
        case ResultsActionTypes.FETCH_RESULTS_DATA:
            return { ...state, loading: true, error: null, resultsData: [] };
        case ResultsActionTypes.FETCH_RESULTS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, resultsData: action.payload };
        case ResultsActionTypes.FETCH_RESULTS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, resultsData: [] };
        default:
            return state;
    }
};

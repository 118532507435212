export interface OperatorCreateState {
    operatorCreateDataVal: any;
    loading: boolean;
    error_responce: null | string;
}


export enum OperatorCreateActionTypes {
    FETCH_OPERATOR_CRATE_DATA = "FETCH_OPERATOR_CRATE_DATA",
    FETCH_OPERATOR_CRATE_DATA_SUCCESS = "FETCH_OPERATOR_CRATE_DATA_SUCCESS",
    FETCH_OPERATOR_CRATE_DATA_ERROR = "FETCH_OPERATOR_CRATE_DATA_ERROR",
}

export type OperatorCreateAction =
    | { type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA }
    | { type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_SUCCESS; payload: any[] }
    | { type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_ERROR; payload: string };
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useIntl} from 'react-intl'
import './SidebarMenuMain.css'
/*Import modules sidebar menu*/
import {SidebarMenuSportsdata} from "./components-menu/SidebarMenuSportsdata";
import {SidebarMenuComposer} from "./components-menu/SidebarMenuComposer";
import {SidebarMenuBetengine} from "./components-menu/SidebarMenuBetengine";
import {useAuth, getSessionStoragedCurrentOperator, getStoragedCurrentOperator} from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
    const sessionOperator = getSessionStoragedCurrentOperator();
    let operator = getStoragedCurrentOperator();
    const intl = useIntl();
    const location = useLocation();
    const { currentUser } = useAuth();
    const [currentLocation, setCurrentLocation] = useState('');
    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location.pathname]);



    return (
    <div className='sidebar-custom'>
        {
            operator || sessionOperator ?
                <SidebarMenuItem to='/dashboard' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.DASHBOARD'})} custom='la-tachometer-alt' />
                : null
        }
        {
            currentUser?.is_superuser ? <SidebarMenuItem to='/sportsdata/settings/leagues' icon='abstract-26' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.SPORTSDATA'})} fontIcon='bi-layers' />
            : <SidebarMenuItem to='sportsdata/pre-match/countries' icon='abstract-26' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.SPORTSDATA'})} fontIcon='bi-layers' />
        }
        {
            currentUser?.is_superuser ?
                <SidebarMenuItem to='/composer/logs/live-log' icon='wrench' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.BETCOMPOSER'})} fontIcon='bi-layers' />
                :
                <SidebarMenuItem to='/composer/bet-card/quicktip' icon='wrench' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.BETCOMPOSER'})} fontIcon='bi-layers' />
        }
        {
            operator || sessionOperator ?
                <SidebarMenuItem to='publisher/edits' title={'Bet Publisher'} fontIcon='bi-layers' custom='la-bullhorn' />
                :null
        }
      <SidebarMenuItem to='/betengine/tickets' icon='switch' title={intl.formatMessage({id: 'SIDEBAR.NAVIGATION.BETENGINE'})} fontIcon='bi-layers' />
        {
            currentLocation.includes('/sportsdata') && <SidebarMenuSportsdata />
        }
        {
            currentLocation.includes('/composer') && <SidebarMenuComposer />
        }
        {
            currentLocation.includes('/betengine/tickets') && <SidebarMenuBetengine />
        }
    </div>
  )
}

export {SidebarMenuMain}

import {Dispatch} from "redux";
import {JackpotActionTypes, JackpotAction} from "../../../types/composer/jackpot/jackpotTypes";
import axios from "axios";

export const getJackpotData = (id:string): any => {
    return async (dispatch: Dispatch<JackpotAction>) => {
        try {
            dispatch({type: JackpotActionTypes.FETCH_JACKPOT_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/composer/jackpots/${id}`,
                withCredentials: false
            });
            dispatch({type: JackpotActionTypes.FETCH_JACKPOT_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: JackpotActionTypes.FETCH_JACKPOT_DATA_ERROR, payload: 'Something wend wrong check Jackpot action controller'});
        }
    }
}
import {MarketDetailsAction, MarketDetailsActionTypes, MarketDetailsState} from "../../types/composer/marketDetails";


const initialState: MarketDetailsState = {
    marketsDetailsData: [],
    loading: false,
    error: null,
};

export const marketDetailsReducer = (state = initialState, action: MarketDetailsAction): MarketDetailsState => {
    switch (action.type) {
        case MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA:
            return { ...state, loading: true, error: null, marketsDetailsData: [] };
        case MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, marketsDetailsData: action.payload };
        case MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, marketsDetailsData: [] };
        default:
            return state;
    }
};

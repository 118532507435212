import axios from "axios";
import {Dispatch} from "redux";
import {updateCountryAction, updateCountryActionTypes} from "../../types/sportsdata/updateCountryTypes";

export const updateCountryData = (id:string, updatedCountryData: object): any => {
    return async (dispatch: Dispatch<updateCountryAction>) => {
        try {
            dispatch({type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/sportsdata/countries/${id}`,
                data: updatedCountryData,
                withCredentials: false,
            });
            dispatch({type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_ERROR, payload: 'Something wend wrong check UPDATE COUNTRY action controller'});
        }
    }
}
import {ResultActionTypes, ResultAction, ResultState} from "../../types/sportsdata/resultTypes";


const initialState: ResultState = {
    resultData: [],
    loading: false,
    error: null,
};

export const resultReducer = (state = initialState, action: ResultAction): ResultState => {
    switch (action.type) {
        case ResultActionTypes.FETCH_RESULT_DATA:
            return { ...state, loading: true, error: null, resultData: [] };
        case ResultActionTypes.FETCH_RESULT_DATA_SUCCESS:
            return { ...state, loading: false, error: null, resultData: action.payload };
        case ResultActionTypes.FETCH_RESULT_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, resultData: [] };
        default:
            return state;
    }
};

export interface QuicktipPreviewState {
    quicktipPreviewData: any;
    loading: boolean;
    error_responce: null | string;
}


export enum QuicktipPreviewActionTypes {
    FETCH_QUICKTIP_PREVIEW_DATA = "FETCH_QUICKTIP_PREVIEW_DATA",
    FETCH_QUICKTIP_PREVIEW_DATA_SUCCESS = "FETCH_QUICKTIP_PREVIEW_DATA_SUCCESS",
    FETCH_QUICKTIP_PREVIEW_DATA_ERROR = "FETCH_QUICKTIP_PREVIEW_DATA_ERROR",
}

export type QuicktipPreviewAction =
    | { type: QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA }
    | { type: QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA_ERROR; payload: string };
import {LeagueState, LeagueAction, LeagueActionTypes} from "../../types/sportsdata/leagueTypes";


const initialState: LeagueState = {
    leagueData: [],
    loading: false,
    error: null,
};

export const leagueReducer = (state = initialState, action: LeagueAction): LeagueState => {
    switch (action.type) {
        case LeagueActionTypes.FETCH_LEAGUE_DATA:
            return { ...state, loading: true, error: null, leagueData: [] };
        case LeagueActionTypes.FETCH_LEAGUE_DATA_SUCCESS:
            return { ...state, loading: false, error: null, leagueData: action.payload };
        case LeagueActionTypes.FETCH_LEAGUE_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, leagueData: [] };
        default:
            return state;
    }
};

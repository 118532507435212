export interface AdminOperatorState {
    adminOperatorData: any;
    loading: boolean;
    error: null | string;
}


export enum AdminOperatorActionTypes {
    FETCH_ADMIN_OPERATOR_DATA = "FETCH_ADMIN_OPERATOR_DATA",
    FETCH_ADMIN_OPERATOR_DATA_SUCCESS = "FETCH_ADMIN_OPERATOR_DATA_SUCCESS",
    FETCH_ADMIN_OPERATOR_DATA_ERROR = "FETCH_ADMIN_OPERATOR_DATA_ERROR",
}

export type AdminOperatorAction =
    | { type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA }
    | { type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_SUCCESS; payload: any[] }
    | { type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_ERROR; payload: string };


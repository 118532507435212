import {getBetPublisherAction, getBetPublisherActionTypes, getBetPublisherState} from "../../types/betPublisher/getBetPublisherSettings";

export const initialState: getBetPublisherState = {
    getBetPublisherData: {},
    loadingStatus: false,
    errorMessage: null,
};

export const getBetPublisherReducer = (state = initialState, action: getBetPublisherAction): getBetPublisherState => {
    switch (action.type) {
        case getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA:
            return { ...state, loadingStatus: true, errorMessage: null };
        case getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_SUCCESS:
            return { ...state, loadingStatus: false, errorMessage: null, getBetPublisherData: action.payload };
        case getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_ERROR:
            return { ...state, loadingStatus: false, errorMessage: action.payload, getBetPublisherData: {} };
        case getBetPublisherActionTypes.CLEAR_BETPUBLISHER_STATE:
            return { ...state, loadingStatus: false, errorMessage: null, getBetPublisherData: {} };
        default:
            return state;
    }
}

export interface LiveLogsState {
    liveLogsData: any[];
    loading: boolean;
    error: null | string;
}


export enum LiveLogsActionTypes {
    FETCH_LIVE_LOGS_DATA = "FETCH_LIVE_LOGS_DATA",
    FETCH_LIVE_LOGS_DATA_SUCCESS = "FETCH_LIVE_LOGS_DATA_SUCCESS",
    FETCH_LIVE_LOGS_DATA_ERROR = "FETCH_LIVE_LOGS_DATA_ERROR",
}

export type LiveLogsAction =
    | { type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA }
    | { type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_SUCCESS; payload: any[] }
    | { type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_ERROR; payload: string };
export interface updateBetPublisherState {
    updatedBetPublisherData: any;
    loading: boolean;
    error: null | string;
}


export enum updateBetPublisherActionTypes {
    UPDATE_BETPUBLISHER_DATA = "UPDATE_BETPUBLISHER_DATA",
    UPDATE_BETPUBLISHER_DATA_SUCCESS = "UPDATE_BETPUBLISHER_DATA_SUCCESS",
    UPDATE_BETPUBLISHER_DATA_ERROR = "UPDATE_BETPUBLISHER_DATA_ERROR",
    CLEAR_UPDATE_BETPUBLISHER_STATE = "CLEAR_UPDATE_BETPUBLISHER_STATE",
}

export type updateBetPublisherAction =
    | { type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA }
    | { type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_SUCCESS; payload: any[] }
    | { type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_ERROR; payload: string }
    | { type: updateBetPublisherActionTypes.CLEAR_UPDATE_BETPUBLISHER_STATE; payload: {}};


import axios from "axios";
import { Dispatch } from "redux";
import { uploadLinkLogoAction, uploadLinkLogoActionTypes } from "../../types/betPublisher/uploadLinkLogo";

export const uploadLinkLogo = (
    operator: string,
    ticket_type: string,
    fileUrl: string,
    formatValue: string): any => {
    return async (dispatch: Dispatch<uploadLinkLogoAction>) => {
        try {
            dispatch({ type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA });
            const responseBlob = await fetch(fileUrl);
            const blob = await responseBlob.blob();
            const file = new File([blob], formatValue, { type: blob.type });
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios({
                method: 'post',
                url: `/api/composer/upload?operator=${operator}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: false,
            });

            dispatch({ type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail;
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({ type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_ERROR, payload: errorMessage });
        }
    }
}

export const clearStateUploadLinkLogo = () => {
    return (dispatch: Dispatch<uploadLinkLogoAction>) => {
        dispatch({
            type: uploadLinkLogoActionTypes.CLEAR_UPDATE_UPLOAD_LINK_LOGO_STATE,
            payload: {}
        });
    };
};

export interface BetengineTicketsState {
    betEngineTicketsData: any;
    loading: boolean;
    error: null | string;
}


export enum BetengineTicketsActionTypes {
    FETCH_BETENGINE_TICKETS_DATA = "FETCH_BETENGINE_TICKETS_DATA",
    FETCH_BETENGINE_TICKETS_DATA_SUCCESS = "FETCH_BETENGINE_TICKETS_DATA_SUCCESS",
    FETCH_BETENGINE_TICKETS_DATA_ERROR = "FETCH_BETENGINE_TICKETS_DATA_ERROR",
}

export type BetengineTicketsAction =
    | { type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA }
    | { type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_SUCCESS; payload: any }
    | { type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_ERROR; payload: string };


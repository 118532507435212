import axios from "axios";
import {Dispatch} from "redux";
import {updateLeagueActionTypes, updateLeagueAction} from "../../types/sportsdata/updateLeagueTypes";

export const updateLeagueData = (id:string, updatedCountryData: object): any => {
    return async (dispatch: Dispatch<updateLeagueAction>) => {
        try {
            dispatch({type: updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/sportsdata/leagues/${id}`,
                data: updatedCountryData,
                withCredentials: false,
            });
            dispatch({type: updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA_ERROR, payload: 'Something wend wrong check UPDATE LEAGUE action controller'});
        }
    }
}
export interface ErrorResponse {
    type: string;
    loc: string[];
    msg: string;
    input: string;
    url: string;
}

export interface JackpotCreateState {
    jackpotCreateDataVal: any;
    loading: boolean;
    error_responce: string | ErrorResponse | null;
}

export enum JackpotCreateActionTypes {
    CRATE_JACKPOT_DATA = "CRATE_JACKPOT_DATA",
    CRATE_JACKPOT_DATA_SUCCESS = "CRATE_JACKPOT_DATA_SUCCESS",
    CRATE_JACKPOT_DATA_ERROR = "CRATE_JACKPOT_DATA_ERROR",
    CLEAR_JACKPOT_CRATE_STATE = 'CLEAR_JACKPOT_CRATE_STATE'
}

export type JackpotCreateAction =
    | { type: JackpotCreateActionTypes.CRATE_JACKPOT_DATA }
    | { type: JackpotCreateActionTypes.CRATE_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: JackpotCreateActionTypes.CRATE_JACKPOT_DATA_ERROR; payload: string | ErrorResponse }
    | { type: JackpotCreateActionTypes.CLEAR_JACKPOT_CRATE_STATE; payload?: any; };
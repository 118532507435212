import {Dispatch} from "redux";
import { SuperbetAction, SuperbetActionTypes } from "../../../types/composer/Superbet/superbetTypes";
import axios from "axios";


export const getSuperbetData = (id: string): any => {
    
    return async (dispatch: Dispatch<SuperbetAction>) => {
        try {
            dispatch({type: SuperbetActionTypes.FETCH_SUPERBET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/composer/superbets/${id}`,
                withCredentials: false
            });
            dispatch({type: SuperbetActionTypes.FETCH_SUPERBET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SuperbetActionTypes.FETCH_SUPERBET_DATA_ERROR, payload: 'Something wend wrong check Superbets action controller'});
        }
    }
}
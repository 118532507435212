import {Dispatch} from "redux";
import { SmartBetsActionTypes, SmartBetsAction} from "../../../types/composer/SmartBet/smartBets";
import axios from "axios";


export const getSmartBetsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/smartbets?operator=${operator}` : `/api/composer/smartbets`;
    return async (dispatch: Dispatch<SmartBetsAction>) => {
        try {
            dispatch({type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA_ERROR, payload: 'Something wend wrong check Smart Bets action controller'});
        }
    }
}
import {Dispatch} from "redux";
import {confirmResultsFullTimeAction, confirmResultsFullTimeActionTypes} from "../../types/sportsdata/confirmResultsFullTime";
import axios from "axios";

export const confirmResultsFullTime = (id:string): any => {
    return async (dispatch: Dispatch<confirmResultsFullTimeAction>) => {
        try {
            dispatch({type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/sportsdata/results/${id}/confirm_full_time`,
                withCredentials: false
            });
            dispatch({type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_ERROR, payload: 'Something wend wrong check Confirm Results Full Time action controller'});
        }
    }
}
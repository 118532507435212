import {Dispatch} from "redux";
import {SmartBetAction, SmartBetActionTypes} from "../../../types/composer/SmartBet/smartBetTypes";
import axios from "axios";

export const getSmartJackpotData = (id:string): any => {
    return async (dispatch: Dispatch<SmartBetAction>) => {
        try {
            dispatch({type: SmartBetActionTypes.FETCH_SMART_BET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/composer/smartbets/${id}`,
                withCredentials: false
            });
            dispatch({type: SmartBetActionTypes.FETCH_SMART_BET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SmartBetActionTypes.FETCH_SMART_BET_DATA_ERROR, payload: 'Something wend wrong check Smart BET action controller'});
        }
    }
}
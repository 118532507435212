export interface uploadLinkLogoState {
    uploadLinkLogoResponce: any;
    responceLoadingStatus: boolean;
    responceError: null | string;
}


export enum uploadLinkLogoActionTypes {
    UPLOAD_LINK_LOGO_DATA = "UPLOAD_LINK_LOGO_DATA",
    UPLOAD_LINK_LOGO_DATA_SUCCESS = "UPLOAD_LINK_LOGO_DATA_SUCCESS",
    UPLOAD_LINK_LOGO_DATA_ERROR = "UPLOAD_LINK_LOGO_DATA_ERROR",
    CLEAR_UPDATE_UPLOAD_LINK_LOGO_STATE = "CLEAR_UPDATE_UPLOAD_LINK_LOGO_STATE",
}

export type uploadLinkLogoAction =
    | { type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA }
    | { type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_SUCCESS; payload: any[] }
    | { type: uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_ERROR; payload: string }
    | { type: uploadLinkLogoActionTypes.CLEAR_UPDATE_UPLOAD_LINK_LOGO_STATE; payload: {}};


export interface QuicktipRemovingState {
    quicktipRemovingData: any[];
    loading: boolean;
    error: null | string;
}


export enum QuicktipRemovingActionTypes {
    FETCH_QUICKTIP_REMOVING_DATA = "FETCH_QUICKTIP_REMOVING_DATA",
    FETCH_QUICKTIP_REMOVING_DATA_SUCCESS = "FETCH_QUICKTIP_REMOVING_DATA_SUCCESS",
    FETCH_QUICKTIP_REMOVING_DATA_ERROR = "FETCH_QUICKTIP_REMOVING_DATA_ERROR",
}

export type QuicktipRemovingAction =
    | { type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA }
    | { type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_ERROR; payload: string };
export interface QuicktipsState {
    quicktipsData: any[];
    loading: boolean;
    error: null | string;
}


export enum QuicktipsActionTypes {
    FETCH_QUICKTIPS_DATA = "FETCH_QUICKTIPS_DATA",
    FETCH_QUICKTIPS_DATA_SUCCESS = "FETCH_QUICKTIPS_DATA_SUCCESS",
    FETCH_QUICKTIPS_DATA_ERROR = "FETCH_QUICKTIPS_DATA_ERROR",
}

export type QuicktipsAction =
    | { type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA }
    | { type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_ERROR; payload: string };


import {AdminApiAction, AdminApiActionTypes, AdminApiState} from "../../../../types/admins/operators/admin/adminApiTypes";

const initialState: AdminApiState = {
    adminApiData: [],
    loading: false,
    error: null,
};

export const adminApiReducer = (state = initialState, action: AdminApiAction): AdminApiState => {
    switch (action.type) {
            case AdminApiActionTypes.FETCH_ADMIN_API_DATA:
            return { ...state, loading: true, error: null, adminApiData: [] };
        case AdminApiActionTypes.FETCH_ADMIN_API_DATA_SUCCESS:
            return { ...state, loading: false, error: null, adminApiData: action.payload };
        case AdminApiActionTypes.FETCH_ADMIN_API_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, adminApiData: [] };
        default:
            return state;
    }
};

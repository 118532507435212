import {AddGoalState, AddGoalAction, AddGoalTypes} from "../../types/sportsdata/addGoal";

const initialState: AddGoalState = {
    addGoalData: [],
    responceStatus: false,
    error: null,
};

export const addGoalReducer = (state = initialState, action: AddGoalAction): AddGoalState => {
    switch (action.type) {
        case AddGoalTypes.FETCH_ADD_GOAL_DATA:
            return { ...state, responceStatus: true, error: null, addGoalData: [] };
        case AddGoalTypes.FETCH_ADD_GOAL_DATA_SUCCESS:
            return { ...state, responceStatus: false, error: null, addGoalData: action.payload };
        case AddGoalTypes.FETCH_ADD_GOAL_DATA_ERROR:
            return { ...state, responceStatus: false, error: action.payload, addGoalData: [] };
        default:
            return state;
    }
};
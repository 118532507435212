import { RemoveSuperbetAction, RemoveSuperbetActionTypes, RemoveSuperbetState } from "../../../types/composer/Superbet/removeSuperbetTypes";

const initialState: RemoveSuperbetState = {
    removeSuperbetData: [],
    loading: false,
    error: null,
};

export const removeSuperbetReducer = (state = initialState, action: RemoveSuperbetAction): RemoveSuperbetState => {
    switch (action.type) {
        case RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA:
            return { ...state, loading: true, error: null, removeSuperbetData: [] };
        case RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, removeSuperbetData: action.payload };
        case RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, removeSuperbetData: [] };
        default:
            return state;
    }
};

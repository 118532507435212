import {SuperOperatorAction, SuperOperatorActionTypes, SuperOperatorState} from "../../../types/admins/operators/superOperatorTypes";

const initialState: SuperOperatorState = {
    superOperatorData: [],
    loading: false,
    error: null,
};

export const superOperatorReducer = (state = initialState, action: SuperOperatorAction): SuperOperatorState => {
    switch (action.type) {
            case SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA:
            return { ...state, loading: true, error: null, superOperatorData: [] };
        case SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, error: null, superOperatorData: action.payload };
        case SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, superOperatorData: [] };
        default:
            return state;
    }
};

export interface EditAdminApiState {
    editAdminApiData: any;
    loading: boolean;
    error: null | string;
}


export enum EditAdminApiActionTypes {
    FETCH_EDIT_ADMIN_API_DATA = "FETCH_EDIT_ADMIN_API_DATA",
    FETCH_EDIT_ADMIN_API_DATA_SUCCESS = "FETCH_EDIT_ADMIN_API_DATA_SUCCESS",
    FETCH_EDIT_ADMIN_API_DATA_ERROR = "FETCH_EDIT_ADMIN_API_DATA_ERROR",
}

export type EditAdminApiAction =
    | { type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA }
    | { type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_SUCCESS; payload: any[] }
    | { type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_ERROR; payload: string };


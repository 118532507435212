export interface dailyTicketsLastSevenDaysState {
    dailyTicketsLastSevenDaysData: any;
    loading: boolean;
    error: null | string;
}


export enum dailyTicketsLastSevenDaysActionTypes {
    FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA = "FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA",
    FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA_SUCCESS = "FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA_SUCCESS",
    FETCH_DAIYLY_TICKETS_TODAY_LAST_SEVEN_DAYS_DATA_ERROR = "FETCH_DAIYLY_TICKETS_TODAY_LAST_SEVEN_DAYS_DATA_ERROR",
}

export type dailyTicketsLastSevenDaysAction =
    | { type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA }
    | { type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA_SUCCESS; payload: any[] }
    | { type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_TODAY_LAST_SEVEN_DAYS_DATA_ERROR; payload: string };


import React, {FC, useEffect, useState} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {ColumnChartWidget} from "../../componenets/ColumnChartWidget";
import {useIntl} from "react-intl";

interface TI {
    currency: string,
    operator: string
}

const DailySalesLastSevenDaysContainer: FC<TI> = ({currency, operator}) => {
    const {dailySalesLastSevenDays} = useAction();
    const {dailySalesLastSevenDaysData} = useTypedSelector((state) => state.dailySalesLastSevenDaysReducer);
    const intl = useIntl();

    useEffect(()=> {
        if (operator) {
            dailySalesLastSevenDays(operator);
        }
    }, [operator])
    return(
        <>
            {
                Object.keys(dailySalesLastSevenDaysData).length > 0 ?
                    <ColumnChartWidget
                        daily={false}
                        currencyVal={currency}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='info'
                        chartHeight='200px'
                        title={intl.formatMessage({id: 'DASHBOARD.dailySalesLastSevenDaysData'})}
                        hintText={intl.formatMessage({id: 'DASHBOARD.dailySalesLastSevenDaysDataHint'})}
                        graphTitle={intl.formatMessage({id: 'DASHBOARD.dailySales'})}
                        data={dailySalesLastSevenDaysData}
                    />
                    :null
            }
        </>
    )
}

export default DailySalesLastSevenDaysContainer;
import {QuickbetCreateAction, QuickbetCreateActionTypes, QuickbetCreateState} from "../../../types/composer/Quickbet/quickbetCreate";

const initialState: QuickbetCreateState = {
    quickbetCreateDataVal: {},
    loading: false,
    error_responce: null,
};

export const quickbetCreateReducer = (state = initialState, action: QuickbetCreateAction): QuickbetCreateState => {
    switch (action.type) {
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA:
            return { ...state, loading: true, error_responce: null, quickbetCreateDataVal: {} };
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, quickbetCreateDataVal: action.payload };
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, quickbetCreateDataVal: {} };
        default:
            return state;
    }
};

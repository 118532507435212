export interface ErrorLogsState {
    errorLogsData: any[];
    loading: boolean;
    error: null | string;
}


export enum ErrorLogsActionTypes {
    FETCH_ERROR_LOGS_DATA = "FETCH_ERROR_LOGS_DATA",
    FETCH_ERROR_LOGS_DATA_SUCCESS = "FETCH_ERROR_LOGS_DATA_SUCCESS",
    FETCH_ERROR_LOGS_DATA_ERROR = "FETCH_ERROR_LOGS_DATA_ERROR",
}

export type ErrorLogsAction =
    | { type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA }
    | { type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_SUCCESS; payload: any[] }
    | { type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_ERROR; payload: string };
export interface dailyGGRCurrentMonthState {
    dailyGGRCurrentMonthData: any;
    loading: boolean;
    error: null | string;
}


export enum dailyGGRCurrentMonthActionTypes {
    FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA = "FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA",
    FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_SUCCESS = "FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_SUCCESS",
    FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_ERROR = "FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_ERROR",
}

export type dailyGGRCurrentMonthAction =
    | { type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA }
    | { type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_SUCCESS; payload: any[] }
    | { type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_ERROR; payload: string };


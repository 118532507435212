import React, {FC, useEffect, useState} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {DonutWidget} from "../../componenets/DonutWidget";
import {useIntl} from "react-intl";

interface TI {
    currency: string,
    operator: string
}

const DailySalesCurrentMonthContainer: FC<TI> = ({currency, operator}) => {
    const {dailySalesCurrentMonth} = useAction();
    const {dailySalesCurrentMonthData} = useTypedSelector((state) => state.dailySalesCurrentMonthReducer);
    const intl = useIntl();

    useEffect(()=> {
        if (operator) {
            dailySalesCurrentMonth(operator);
        }
    }, [operator])
    return(
        <>
            {
                Object.keys(dailySalesCurrentMonthData).length > 0 ?
                    <DonutWidget
                        data={dailySalesCurrentMonthData}
                        title={intl.formatMessage({id: 'DASHBOARD.dailySalesCurrentMonthData'})}
                        hintText={intl.formatMessage({id: 'DASHBOARD.dailySalesCurrentMonthDataHint'})}
                        currencyVal={currency}
                    />
                    :null
            }
        </>
    )
}

export default DailySalesCurrentMonthContainer;
import {Dispatch} from "redux";
import {AdminNotificationAction, AdminNotificationActionTypes} from "../../../../types/admins/operators/admin/adminNotificationTypes";
import axios from "axios";


export const getAdminNotificationData = (id:string): any => {
    return async (dispatch: Dispatch<AdminNotificationAction>) => {
        try {
            dispatch({type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/operators/${id}/sms_notification_settings`,
                withCredentials: false
            });
            dispatch({type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_ERROR, payload: 'Something wend wrong check Admin Notification action controller'});
        }
    }
}
import {JackpotRemovingAction, JackpotRemovingActionTypes, JackpotRemovingState} from "../../../types/composer/jackpot/jackpotRemoveTypes";

const initialState: JackpotRemovingState = {
    jackpotRemovingData: [],
    loading: false,
    error: null,
};

export const jackpotRemovingReducer = (state = initialState, action: JackpotRemovingAction): JackpotRemovingState => {
    switch (action.type) {
        case JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA:
            return { ...state, loading: true, error: null, jackpotRemovingData: [] };
        case JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_SUCCESS:
            return { ...state, loading: false, error: null, jackpotRemovingData: action.payload };
        case JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, jackpotRemovingData: [] };
        default:
            return state;
    }
};

import React, {FC, useEffect} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import {getSessionStoragedCurrentOperator, getSessionStoragedOperatorCurrency, getStoragedCurrentOperator} from "../../auth";
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import MonthTableContainer from "./widgets/MonthTableContainer";
import DailySalesTodayContainer from "./widgets/DailySalesTodayContainer";
import DailySalesCurrentMonthContainer from "./widgets/DailySalesCurrentMonthContainer";
import DailySalesLastSevenDaysContainer from "./widgets/DailySalesLastSevenDaysContainer";
import DailyTicketsLastSevenDaysContainer from "./widgets/DailyTicketsLastSevenDaysContainer";
import DailyGGRLastSevenDaysContainer from "./widgets/DailyGGRLastSevenDaysContainer";
import DailyGGRCurrentMonthContainer from "./widgets/DailyGGRCurrentMonthContainer";
import DailyGGRCurrentMonthGraphContainer from "./widgets/DailyGGRCurrentMonthGraphContainer";
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";


const Dashboard: FC = () => {
    let navigate = useNavigate();
    let operator = getStoragedCurrentOperator();
    const sessionOperator = getSessionStoragedCurrentOperator();
    const sessionCurrency = getSessionStoragedOperatorCurrency();
    const {getAdminOperatorData} = useAction();
    const {adminOperatorData} = useTypedSelector((state) => state.adminOperatorReducer);
    const intl = useIntl();
    let currencyVal = adminOperatorData.currency || sessionCurrency || null;

    if (operator) {
        operator = operator;
    } else if (sessionOperator) {
        operator = sessionOperator
    }

    useEffect(()=> {
        if (operator) {
            getAdminOperatorData(operator);
        } else {
            navigate('/composer/logs/live-log');
        }
    }, [operator])

    return (
        <>
            <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}

                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12 mb-xl-12'>

                    {
                        operator || sessionOperator ?
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0 pt-5">
                                    <div className='card-header-tools'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className="card-label fw-bold fs-3 mb-1">{intl.formatMessage({id: 'DASHBOARD.TITLE'})}</span>
                                            <span className="text-muted mt-1 fw-semibold fs-7">{intl.formatMessage({id: 'DASHBOARD.TITLE.HINT'})}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row g-5 g-xl-10'>
                                        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-2">
                                            {
                                                operator ?
                                                    <DailyGGRCurrentMonthGraphContainer operator={operator}
                                                                                        currency={currencyVal}/>
                                                    : null
                                            }
                                        </div>
                                        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                                            <div className="row">
                                                {operator ?
                                                    <DailySalesTodayContainer currency={currencyVal}
                                                                              operator={operator}/>
                                                    : null
                                                }
                                                {operator ?
                                                    <DailySalesCurrentMonthContainer operator={operator}
                                                                                     currency={currencyVal}/>
                                                    : null
                                                }
                                                {
                                                    operator ?
                                                        <DailyGGRCurrentMonthContainer operator={operator}
                                                                                       currency={currencyVal}/> :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5">
                                            <div className="row">
                                                {
                                                    operator ?
                                                        <DailySalesLastSevenDaysContainer currency={currencyVal}
                                                                                          operator={operator}/>
                                                        : null
                                                }
                                                {
                                                    operator ?
                                                        <DailyTicketsLastSevenDaysContainer operator={operator}
                                                                                            currency={currencyVal}/>
                                                        : null
                                                }
                                                {
                                                    operator ?
                                                        <DailyGGRLastSevenDaysContainer operator={operator}
                                                                                        currency={currencyVal}/>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {
                                        operator ?
                                            <MonthTableContainer operator={operator} currency={currencyVal}/>
                                            : null
                                    }
                                </div>
                            </div>
                            : <Alert>Loading</Alert>
                    }
                </div>
                {/* end::Col */}
            </div>
        </>
    )
}

export {Dashboard}
import React, {FC, useEffect, useState} from 'react';
import { useAuth } from '../../../auth';
import * as authHelper from '../AuthHelpers';
import { useIntl } from 'react-intl';
import {Dropdown} from "react-bootstrap";
import './SidebarSwitchOperator.css';

interface SidebarSwitchOperatorProps {}

const SidebarSwitchOperator: FC<SidebarSwitchOperatorProps> = () => {
    const [currentItem, setCurrentItem] = useState('');
    const { currentUser, currentOperator, setCurrentOperator } = useAuth();
    const currentStoredOperator = authHelper.getStoragedCurrentOperator();
    const currentSessionOperator = authHelper.getSessionStoragedCurrentOperator();

    const intl = useIntl();
    const isOperatorsAvailable = currentUser?.operators && currentUser?.operators.length > 0;
    useEffect(()=> {
        if (currentItem.length > 0) {
            setCurrentOperator(currentItem);
            authHelper.setStorageCurrentOperator(currentItem);
        }

    }, [currentItem])

    const handleChangeOperator = (name: string) => {
        setCurrentItem(name);
    };

    return (
        <>
            {isOperatorsAvailable ? (
                <Dropdown drop="up" >
                    <Dropdown.Toggle variant="outline-warning" id="dropdown-basic">
                        <i className="icon-xl la la-industry fs-2hx" /> <br/> <span className='fs-8 operator-name'>{currentItem.length > 0 ? currentItem : currentStoredOperator}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {currentUser?.operators.map((item: string, index) => (
                            <Dropdown.Item key={index+item} onClick={() => handleChangeOperator(item)} className={item === currentStoredOperator || item === currentItem ? 'active' : ''}>
                                <span className='fs-8'>{item}</span>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            ): (
                currentSessionOperator ? (
                  <span className='badge badge-light-primary mb-10'>
                    <i className="icon-xl la la-industry fs-2hx" />
                      <span className='fs-8 operator-name'>{currentSessionOperator}</span>
                  </span>
                ) : null
            )}
        </>
    );
};

export default SidebarSwitchOperator;

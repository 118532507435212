import {OperatorCreateAction, OperatorCreateActionTypes, OperatorCreateState} from "../../../types/admins/operators/operatorCreateTypes";

const initialState: OperatorCreateState = {
    operatorCreateDataVal: {},
    loading: false,
    error_responce: null,
};

export const operatorCreateReducer = (state = initialState, action: OperatorCreateAction): OperatorCreateState => {
    switch (action.type) {
        case OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA:
            return { ...state, loading: true, error_responce: null, operatorCreateDataVal: {} };
        case OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, operatorCreateDataVal: action.payload };
        case OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, operatorCreateDataVal: {} };
        default:
            return state;
    }
};

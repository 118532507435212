export interface ResultState {
    resultData: any;
    loading: boolean;
    error: null | string;
}


export enum ResultActionTypes {
    FETCH_RESULT_DATA = "FETCH_RESULT_DATA",
    FETCH_RESULT_DATA_SUCCESS = "FETCH_RESULT_DATA_SUCCESS",
    FETCH_RESULT_DATA_ERROR = "FETCH_RESULT_DATA_ERROR",
}

export type ResultAction =
    | { type: ResultActionTypes.FETCH_RESULT_DATA }
    | { type: ResultActionTypes.FETCH_RESULT_DATA_SUCCESS; payload: any[] }
    | { type: ResultActionTypes.FETCH_RESULT_DATA_ERROR; payload: string };
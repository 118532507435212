export interface dailyGGRLastSevenDaysState {
    dailyGGRLastSevenDaysData: any;
    loading: boolean;
    error: null | string;
}


export enum dailyGGRLastSevenDaysActionTypes {
    FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA = "FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA",
    FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA_SUCCESS = "FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA_SUCCESS",
    FETCH_DAIYLY_GGR_TODAY_LAST_SEVEN_DAYS_DATA_ERROR = "FETCH_DAIYLY_GGR_TODAY_LAST_SEVEN_DAYS_DATA_ERROR",
}

export type dailyGGRLastSevenDaysAction =
    | { type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA }
    | { type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA_SUCCESS; payload: any[] }
    | { type: dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_TODAY_LAST_SEVEN_DAYS_DATA_ERROR; payload: string };


import {Dispatch} from "redux";
import {OperatorsActionTypes, OperatorsAction} from "../../../types/admins/operators/operatorsTypes";
import axios from "axios";


export const getOperatorsData = (): any => {
    return async (dispatch: Dispatch<OperatorsAction>) => {
        try {
            dispatch({type: OperatorsActionTypes.FETCH_OPERATORS_DATA});
            const response = await axios({
                method: 'get',
                url: '/api/operators',
                withCredentials: false
            });
            dispatch({type: OperatorsActionTypes.FETCH_OPERATORS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: OperatorsActionTypes.FETCH_OPERATORS_DATA_ERROR, payload: 'Something wend wrong check SuperAdminOperators action controller'});
        }
    }
}
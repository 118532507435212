export interface SmartBetState {
    smartBetData: any;
    loading: boolean;
    error: null | string;
}


export enum SmartBetActionTypes {
    FETCH_SMART_BET_DATA = "FETCH_SMART_BET_DATA",
    FETCH_SMART_BET_DATA_SUCCESS = "FETCH_SMART_BET_DATA_SUCCESS",
    FETCH_SMART_BET_DATA_ERROR = "FETCH_SMART_BET_DATA_ERROR",
}

export type SmartBetAction =
    | { type: SmartBetActionTypes.FETCH_SMART_BET_DATA }
    | { type: SmartBetActionTypes.FETCH_SMART_BET_DATA_SUCCESS; payload: any[] }
    | { type: SmartBetActionTypes.FETCH_SMART_BET_DATA_ERROR; payload: string };
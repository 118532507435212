import {Dispatch} from "redux";
import {dailySalesTodayActionTypes, dailySalesTodayAction} from "../../types/dashboard/dailySalesToday";
import axios from "axios";


export const getDailySalesTodayData = (operator:string): any => {
    return async (dispatch: Dispatch<dailySalesTodayAction>) => {
        try {
            dispatch({type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_sales_today?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_ERROR, payload: `Something went wrong, please check Widget action controller. ${error}`});
        }
    }
}
export interface ErrorResponse {
    type: string;
    loc: string[];
    msg: string;
    input: string;
    url: string;
}

export interface QuickbetCreateState {
    quickbetCreateDataVal: any;
    loading: boolean;
    error_responce: string | ErrorResponse | null;
}

export enum QuickbetCreateActionTypes {
    FETCH_QUICKBET_CRATE_DATA = "FETCH_QUICKBET_CRATE_DATA",
    FETCH_QUICKBET_CRATE_DATA_SUCCESS = "FETCH_QUICKBET_CRATE_DATA_SUCCESS",
    FETCH_QUICKBET_CRATE_DATA_ERROR = "FETCH_QUICKBET_CRATE_DATA_ERROR",
}

export type QuickbetCreateAction =
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA }
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_ERROR; payload: string | ErrorResponse };
import {BetengineTicketAction, BetengineTicketActionTypes, BetengineTicketState} from "../../types/betengine/betengineTicketTypes";

const initialState: BetengineTicketState = {
    betEngineTicketData: {},
    loading: false,
    error: null,
};

export const betengineTicketReducer = (state = initialState, action: BetengineTicketAction): BetengineTicketState => {
    switch (action.type) {
        case BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA:
            return { ...state, loading: true, error: null, betEngineTicketData: {} };
        case BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, betEngineTicketData: action.payload };
        case BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, betEngineTicketData: {} };
        default:
            return state;
    }
};

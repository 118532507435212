import axios from "axios";
import {Dispatch} from "redux";
import { UpdateSuperbetAction, UpdateSuperbetActionTypes } from "../../../types/composer/Superbet/updateSuperbetTypes";
interface ErrorResponse {
    code: number;
    message: string;
}
export const updateSuperbetData = (id:string, updatedSuperbetData: any): any => {
    return async (dispatch: Dispatch<UpdateSuperbetAction>) => {
        try {
            dispatch({type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/composer/superbets/${id}`,
                data: updatedSuperbetData,
                withCredentials: false,
            });

            dispatch({type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {

            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (error.response.data.detail && error.response.data.detail[0].msg) {
                    errorPayload = {
                        code: error.response.status,
                        message: error.response.data.detail[0].msg,
                    }
                } else {
                    const { code, message } = error.response.data;
                    errorPayload = { code, message };
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }

            dispatch({type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_ERROR, payload: errorPayload});
        }
    }
}

import {dailyGGRCurrentMonthGraphActionTypes, dailyGGRCurrentMonthGraphState, dailyGGRCurrentMonthGraphAction} from "../../types/dashboard/dailyGGRLastCurrentMonthGraph";

const initialState: dailyGGRCurrentMonthGraphState = {
    dailyGGRCurrentMonthGraphData: {},
    loading: false,
    error: null,
};

export const dailyGGRCurrentMonthGraphReducer = (state = initialState, action: dailyGGRCurrentMonthGraphAction): dailyGGRCurrentMonthGraphState => {
    switch (action.type) {
        case dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA:
            return { ...state, loading: true, error: null, dailyGGRCurrentMonthGraphData: {} };
        case dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailyGGRCurrentMonthGraphData: action.payload };
        case dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailyGGRCurrentMonthGraphData: {} };
        default:
            return state;
    }
};

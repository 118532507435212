import {uploadLinkLogoAction, uploadLinkLogoActionTypes, uploadLinkLogoState} from "../../types/betPublisher/uploadLinkLogo";

export const initialState: uploadLinkLogoState = {
    uploadLinkLogoResponce: {},
    responceLoadingStatus: false,
    responceError: null,
};

export const uploadLinkLogoReducer = (state = initialState, action: uploadLinkLogoAction): uploadLinkLogoState => {
    switch (action.type) {
        case uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA:
            return { ...state, responceLoadingStatus: true, responceError: null };
        case uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_SUCCESS:
            return { ...state, responceLoadingStatus: false, responceError: null, uploadLinkLogoResponce: action.payload };
        case uploadLinkLogoActionTypes.UPLOAD_LINK_LOGO_DATA_ERROR:
            return { ...state, responceLoadingStatus: false, responceError: action.payload, uploadLinkLogoResponce: {} };
        case uploadLinkLogoActionTypes.CLEAR_UPDATE_UPLOAD_LINK_LOGO_STATE:
            return { ...state, responceLoadingStatus: false, responceError: null, uploadLinkLogoResponce: {} };
        default:
            return state;
    }
}

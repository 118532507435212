import {Dispatch} from "redux";
import {MarketGroupsActionTypes, MarketGroupsAction} from "../../types/composer/marketGroupsTypes";
import axios from "axios";


export const getMarketGroupsData = (): any => {
    return async (dispatch: Dispatch<MarketGroupsAction>) => {
        try {
            dispatch({type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA});
            const response = await axios({
                method: 'get',
                url: '/api/sportsdata/market_groups',
                withCredentials: false
            });
            dispatch({type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_ERROR, payload: 'Something wend wrong check Market Group action controller'});
        }
    }
}
export interface AdminNotificationState {
    adminNotificationData: any;
    loading: boolean;
    error: null | string;
}


export enum AdminNotificationActionTypes {
    FETCH_ADMIN_NOTIFICATION_DATA = "FETCH_ADMIN_NOTIFICATION_DATA",
    FETCH_ADMIN_NOTIFICATION_DATA_SUCCESS = "FETCH_ADMIN_NOTIFICATION_DATA_SUCCESS",
    FETCH_ADMIN_NOTIFICATION_DATA_ERROR = "FETCH_ADMIN_NOTIFICATION_DATA_ERROR",
}

export type AdminNotificationAction =
    | { type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA }
    | { type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_SUCCESS; payload: any[] }
    | { type: AdminNotificationActionTypes.FETCH_ADMIN_NOTIFICATION_DATA_ERROR; payload: string };


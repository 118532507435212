import {JackpotCreateAction, JackpotCreateActionTypes, JackpotCreateState} from "../../../types/composer/jackpot/jackpotCreateTypes";

const initialState: JackpotCreateState = {
    jackpotCreateDataVal: {},
    loading: false,
    error_responce: null
};

export const jackpotCreateReducer = (state = initialState, action: JackpotCreateAction): JackpotCreateState => {
    switch (action.type) {
        case JackpotCreateActionTypes.CRATE_JACKPOT_DATA:
            return { ...state, loading: true, error_responce: null, jackpotCreateDataVal: {} };
        case JackpotCreateActionTypes.CRATE_JACKPOT_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, jackpotCreateDataVal: action.payload };
        case JackpotCreateActionTypes.CRATE_JACKPOT_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, jackpotCreateDataVal: {} };
        case JackpotCreateActionTypes.CLEAR_JACKPOT_CRATE_STATE:
            return { ...state, loading: false, error_responce: null, jackpotCreateDataVal: {} };
        default:
            return state;
    }
};

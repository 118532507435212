import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getCSSVariableValue, getCSS} from "../../../../_metronic/assets/ts/_utils";
import {useThemeMode} from "../../../../_metronic/partials";
import {useIntl} from "react-intl";

interface SalesData {
    date: string;
    value: number;
}

interface DataItem {
    total_sales: number;
    total_ggr: number;
    sales_data: SalesData[];
    ggr_data: SalesData[];
}

type Props = {
    data: DataItem
    title: string
    currencyVal: string
}

const GraphChart: React.FC<Props> = ({title, currencyVal, data}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const intl = useIntl();
    const {mode} = useThemeMode()
    const refreshMode = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, currencyVal))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshMode()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])

    return (
        <div className={`card`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'DASHBOARD.SalesThisMonth'})}</span>
                </h3>
            </div>
            <div className='card-body'>
                <div className='card-header pt-5'>
                        <div className="card-title flex-column">
                            <div className=' align-items-center'>
                                <span
                                    className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>{currencyVal}</span>
                                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                                {data.total_ggr / 100}
                            </span>
                            </div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {intl.formatMessage({id: 'DASHBOARD.TotalGGRHint'})}
                                    </Tooltip>
                                }>
                                <span
                                    className='text-gray-400 pt-1 fw-semibold fs-6'>
                                    {intl.formatMessage({id: 'DASHBOARD.TotalGGR'})}
                                    <i className='las la-info-circle m-1'/>
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div className='card-title flex-column'>
                            <div className='align-items-center'>
                                <span
                                    className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>{currencyVal}</span>
                                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                                {data.total_sales / 100}
                            </span>
                            </div>
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip>{intl.formatMessage({id: 'DASHBOARD.TotalSalesHint'})}</Tooltip>}>
                                <span
                                    className='text-gray-400 pt-1 fw-semibold fs-6'>
                                    {intl.formatMessage({id: 'DASHBOARD.TotalSales'})}
                                    <i className='las la-info-circle m-1'/>
                                </span>
                            </OverlayTrigger>
                        </div>
                </div>
                <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
            </div>
        </div>
    )
}

export {GraphChart}

function getChartOptions(height: number, data: DataItem, currency: string): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-success')
    const lightColor = getCSSVariableValue('--bs-primary')

    const salesValues = data.sales_data.map(item => item.value / 100);
    const salesDates = data.sales_data.map(item => item.date);
    const ggrValues = data.ggr_data.map(item => item.value / 100);

    return {
        series: [
            {
                name: 'Sales',
                data: salesValues,
            },
            {
                name: 'GGR',
                data: ggrValues,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'line',
            stacked: false,
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "20%"
            }
        },
        legend: {
            show: true,
            fontSize: '12px',
            labels: {
                colors: labelColor,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [lightColor, baseColor],
        },
        xaxis: {
            categories: salesDates,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            title: {
                text: 'Range',
                style: {
                    color: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ` ${currency}`
                },
            },
        },
        colors: [lightColor, baseColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: baseColor,
            strokeWidth: 3,
        },
    }
}


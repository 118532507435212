export interface JackpotRemovingState {
    jackpotRemovingData: any[];
    loading: boolean;
    error: null | string;
}

export enum JackpotRemovingActionTypes {
    REMOVE_JACKPOT_DATA = "REMOVE_JACKPOT_DATA",
    REMOVE_JACKPOT_DATA_SUCCESS = "REMOVE_JACKPOT_DATA_SUCCESS",
    REMOVE_JACKPOT_DATA_ERROR = "REMOVE_JACKPOT_DATA_ERROR",
}

export type JackpotRemovingAction =
    | { type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA }
    | { type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_ERROR; payload: string };
export interface SmartBetsRemovingState {
    smartBetsRemovingData: any[];
    loading: boolean;
    error: null | string;
}


export enum SmartBetsRemovingActionTypes {
    FETCH_SMART_BETS_REMOVING_DATA = "FETCH_SMART_BETS_REMOVING_DATA",
    FETCH_SMART_BETS_REMOVING_DATA_SUCCESS = "FETCH_SMART_BETS_REMOVING_DATA_SUCCESS",
    FETCH_SMART_BETS_REMOVING_DATA_ERROR = "FETCH_SMART_BETS_REMOVING_DATA_ERROR",
}

export type SmartBetsRemovingAction =
    | { type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA }
    | { type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_SUCCESS; payload: any[] }
    | { type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_ERROR; payload: string };
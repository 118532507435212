import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from "../../../../_metronic/helpers";
import {useThemeMode} from "../../../../_metronic/partials";
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {Alert, Tooltip, OverlayTrigger} from "react-bootstrap";
interface DataItem {
    ticket_type: string;
    value: number;
}
type Props = {
    data: {
        total_sum: number;
        percentage_prev_day: number;
        data: DataItem[];
    }
    title: string
    currencyVal: string,
    hintText: string
}

let labelClass : { [key: string]: string } = {
    smartbet: "bg-success",
    quickbet: "bg-lite",
    quicktip: "bg-primary",
    superbet: "bg-warning",
    jackpot: "bg-info",
}

export const DonutWidget: React.FC<Props> = ({data, title, currencyVal, hintText}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const {mode} = useThemeMode();
    const labels: number[] = data.data ? data.data.map((item: any) => item.ticket_type): [];
    const series: number[] = data.data ? data.data.map((item: any) => item.value / 100) : [];
    const refreshMode = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, labels, series, currencyVal))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshMode()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])

    return(
        <>
            {
                Object.keys(data).length > 0 ?
                    <div className={`card card-flush card card-flush h-md-50 mb-5 mb-xl-10`}>
                        <div className='card-header pt-5'>
                                <div className='card-title d-flex flex-column'>
                                    <div className='d-flex align-items-center'>
                                        <span
                                            className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>{currencyVal}</span>
                                        <span
                                            className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{(data.total_sum / 100).toLocaleString('en-US', {maximumFractionDigits: 2})}
</span>
                                        {data.percentage_prev_day >= 0 ? (
                                            <span className='badge badge-light-success fs-base'>
                                          <KTIcon iconName='arrow-up'
                                                  className='fs-5 text-success ms-n1'/> {data.percentage_prev_day}
                                        </span>
                                        ) : null}
                                    </div>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{hintText}</Tooltip>}>
                                        <span className='text-gray-400 pt-1 fw-semibold fs-6'>
                                            {title}
                                            <i className='las la-info-circle m-1'/>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                        </div>
                        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
                            <div ref={chartRef} id='kt_charts_widget_3_chart' className='text-start'/>
                            <div className="d-flex flex-column content-justify-center flex-row-fluid">
                                {
                                    data.data !== null && data.data.length > 0 ?
                                        data.data.map((item, index) => {
                                            return (
                                                <div className='d-flex fw-semibold align-items-center' key={index}>
                                                    <div
                                                        className={`bullet w-8px h-3px rounded-2 ${labelClass[item.ticket_type]} me-3`}
                                                    />
                                                    <div className='text-gray-500 flex-grow-1 me-4'>{item.ticket_type}</div>
                                                    <div className='fw-bolder text-gray-700 text-xxl-end'>{(item.value / 100).toLocaleString('en-US', { maximumFractionDigits: 2 })} {currencyVal}</div>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    : <Alert>Loading</Alert>
            }
        </>
    );
}

function getChartOptions(height: number, labels: any[], series: any[], currencyVal: string): ApexOptions {
    const colorsMap: { [key: string]: string } = {
        'quickbet': '#b5b5c3',
        'quicktip': '#009ef7',
        'superbet': '#ffc700',
        'smartbet': '#50cd89',
        'jackpot': '#7239ea'
    };

    const chartColors = labels.map(label => colorsMap[label]);
    const borderColor = getCSSVariableValue('bg-danger')
    return {
        series: series,
        labels: labels,
        chart: {
            fontFamily: 'inherit',
            type: 'donut',
            height: 150,
            width: 150,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 1,
            colors: chartColors,
        },
        tooltip: {
            style: {
                fontSize: '10px',
            },
            y: {
                formatter: function (val) {
                    return val.toLocaleString('en-US', { maximumFractionDigits: 2 }) + ` ${currencyVal}`
                },
            },
        },
        colors: chartColors,
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: chartColors,
            strokeWidth: 1,
        },
    }
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetPassword} from "./components/ResetPassword";
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl';

const AuthLayout = () => {
    const intl = useIntl();
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-root'
      style={{
        /*backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,*/
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Wrapper */}
        <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
                <div className="d-flex flex-stack py-2">
                    <div className="me-2" />
                    <div className="m-0">
                        <span className="text-gray-500 fw-bold fs-5 me-2" data-kt-translate="sign-in-head-desc">
                            {intl.formatMessage({id: 'AUTH.LOGIN.SIGHNUP.HINT'})}
                        </span>
                        <Link to='/auth/registration' className='link-primary'>
                            {intl.formatMessage({id: 'AUTH.LOGIN.SIGHNUP.BTN'})}
                        </Link>
                    </div>
                </div>
                <div className="py-20">
                    <Outlet />
                </div>
            </div>
        </div>
        <div className="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat"
             style={{
                 backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg-signin-min.png')})`,
             }}
        />
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}

import {updateSmartBetAction, updateSmartBetActionTypes, updateSmartBetState} from "../../../types/composer/SmartBet/updateSmartBet";

const initialState: updateSmartBetState = {
    updateSmartBetDataResponce: {},
    response: false,
    error: null,
};

export const updateSmartBetsReducer = (state = initialState, action: updateSmartBetAction): updateSmartBetState => {
    switch (action.type) {
        case updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA:
            return { ...state, response: true, error: null, updateSmartBetDataResponce: {} };
        case updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA_SUCCESS:
            return { ...state, response: false, error: null, updateSmartBetDataResponce: action.payload };
        case updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA_ERROR:
            return { ...state, response: false, error: action.payload, updateSmartBetDataResponce: {} };
        case updateSmartBetActionTypes.CLEAR_SMART_BET_STATE:
            return { ...state, response: false, error: null, updateSmartBetDataResponce: {} };
        default:
            return state;
    }
};

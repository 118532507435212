export interface dailySalesLastSevenDaysState {
    dailySalesLastSevenDaysData: any;
    loading: boolean;
    error: null | string;
}


export enum dailySalesLastSevenDaysActionTypes {
    FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA = "FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA",
    FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA_SUCCESS = "FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA_SUCCESS",
    FETCH_DAIYLY_SALES_TODAY_LAST_SEVEN_DAYS_DATA_ERROR = "FETCH_DAIYLY_SALES_TODAY_LAST_SEVEN_DAYS_DATA_ERROR",
}

export type dailySalesLastSevenDaysAction =
    | { type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA }
    | { type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA_SUCCESS; payload: any[] }
    | { type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_TODAY_LAST_SEVEN_DAYS_DATA_ERROR; payload: string };


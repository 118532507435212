import {Dispatch} from "redux";
import {SmartbetPreviewAction, SmartbetPreviewActionTypes} from "../../../types/composer/SmartBet/smartbetPreview";
import axios from "axios";

export const getSmartBetPreviewData = (code:string, options: any ): any => {
    console.log(code);
    console.log(options);
    return async (dispatch: Dispatch<SmartbetPreviewAction>) => {
        try {
            dispatch({type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA});
            const response = await axios({
                method: 'POST',
                url: `/api/composer/smartbets/${code}`,
                data: options,
                withCredentials: false
            });
            dispatch({type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            dispatch({type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_ERROR, payload: `Something went wrong with Smartbet Preview: ${error.message}`});
        }
    }
}
export interface LeagueState {
    leagueData: any[];
    loading: boolean;
    error: null | string;
}


export enum LeagueActionTypes {
    FETCH_LEAGUE_DATA = "FETCH_LEAGUE_DATA",
    FETCH_LEAGUE_DATA_SUCCESS = "FETCH_LEAGUE_DATA_SUCCESS",
    FETCH_LEAGUE_DATA_ERROR = "FETCH_LEAGUE_DATA_ERROR",
}

export type LeagueAction =
    | { type: LeagueActionTypes.FETCH_LEAGUE_DATA }
    | { type: LeagueActionTypes.FETCH_LEAGUE_DATA_SUCCESS; payload: any[] }
    | { type: LeagueActionTypes.FETCH_LEAGUE_DATA_ERROR; payload: string };
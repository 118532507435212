export interface QuickbetRemovingState {
    quickbetRemovingData: any[];
    loading: boolean;
    error: null | string;
}

export enum QuickbetRemovingActionTypes {
    FETCH_QUICKBET_REMOVING_DATA = "FETCH_QUICKBET_REMOVING_DATA",
    FETCH_QUICKBET_REMOVING_DATA_SUCCESS = "FETCH_QUICKBET_REMOVING_DATA_SUCCESS",
    FETCH_QUICKBET_REMOVING_DATA_ERROR = "FETCH_QUICKBET_REMOVING_DATA_ERROR",
}

export type QuickbetRemovingAction =
    | { type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA }
    | { type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_ERROR; payload: string };
import {JackpotAction, JackpotActionTypes, JackpotState} from "../../../types/composer/jackpot/jackpotTypes";

const initialState: JackpotState = {
    jackpotData: [],
    loading: false,
    error: null,
};

export const jackpotReducer = (state = initialState, action: JackpotAction): JackpotState => {
    switch (action.type) {
        case JackpotActionTypes.FETCH_JACKPOT_DATA:
            return { ...state, loading: true, error: null, jackpotData: [] };
        case JackpotActionTypes.FETCH_JACKPOT_DATA_SUCCESS:
            return { ...state, loading: false, error: null, jackpotData: action.payload };
        case JackpotActionTypes.FETCH_JACKPOT_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, jackpotData: [] };
        default:
            return state;
    }
};

import {Dispatch} from "redux";
import axios from "axios";
import {updateDate} from "../../../app/modules/sportsdata/helpers/results";
import {ResultsActionTypes, ResultsAction} from "../../types/sportsdata/resultsTypes";

export const getResultsData = (startDate:any, filter: string ): any => {
    let dateString = updateDate(startDate).date
    return async (dispatch: Dispatch<ResultsAction>) => {
        try {
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/results?start_date=${dateString}&result_status=${filter}`,
                withCredentials: true
            });
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA_ERROR, payload: 'Something wend wrong check RESULTS action controller'});
        }
    }
}
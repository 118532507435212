import * as LiveLogsActionCreators from './composer/liveLogs';
import * as LiveErrorActionCreators from './composer/errorLogs';
import * as SmartBetsActionCreators from './composer/SmartBet/smartBets';
import * as SmartBetsCreateActionCreators from './composer/SmartBet/smartBetCreate';
import * as clearSmarbetCreateDataStateActionCreators from './composer/SmartBet/smartBetCreateNew';
import * as SmartBetsCreateNewDataActionCreators from './composer/SmartBet/smartBetCreateNew';
import * as SmartBetsRemovingActionCreators from './composer/SmartBet/smartBetRemoving';
import * as SmartJackpotActionCreators from './composer/SmartBet/smartBet';
import * as SmartJackpotsUpdatingActionCreators from './composer/SmartBet/updateSmartBet';
import * as SmartJackpotsPreviewActionCreators from './composer/SmartBet/smartBetPreview';
import * as superbetsActionCreators from './composer/Superbet/superbets';
import * as superbetActionCreators from './composer/Superbet/superbet';
import * as superbetsRemovingActionCreators from './composer/Superbet/removeSuperbet';
import * as superbetsUpdatingActionCreators from './composer/Superbet/updateSuprebet';
import * as clearSmarbetUpdateDataStateActionCreators from './composer/Superbet/updateSuprebet';
import * as quicktipActionCreators from './composer/Quicktip/quicktip';
import * as quicktipsActionCreators from './composer/Quicktip/quicktips';
import * as quicktipCreateActionCreators from './composer/Quicktip/quicktipCreate';
import * as quicktipUpdatingActionCreators from './composer/Quicktip/updateQuicktip';
import * as quicktipUpdatingRemovingStateActionCreators from './composer/Quicktip/updateQuicktip';
import * as quicktipRemovingActionCreators from './composer/Quicktip/quicktipRemoving';
import * as quicktipClearErrorActionCreators from './composer/Quicktip/quicktipCreate';

import * as quickbetActionCreators from './composer/Quickbet/quickbet';
import * as quickbetsActionCreators from './composer/Quickbet/quickbets';
import * as quickbetCreateActionCreators from './composer/Quickbet/quickbetCreate';
import * as quickbetUpdatingActionCreators from './composer/Quickbet/updateQuickbet';
import * as clearStateQuicbetUpdatingActionCreators from './composer/Quickbet/updateQuickbet';
import * as quickbetRemovingActionCreators from './composer/Quickbet/quickbetRemoving';

import * as jackpotActionCreators from './composer/Jackpot/jackpot';
import * as jackpotsActionCreators from './composer/Jackpot/jackpots';
import * as jackpotCreateActionCreators from './composer/Jackpot/jackpotCreate';
import * as clearJackpotCreateActionCreators from './composer/Jackpot/jackpotCreate';
import * as jackpotUpdatingActionCreators from './composer/Jackpot/updateJackpot';
import * as clearStateJackpotUpdatingActionCreators from './composer/Jackpot/updateJackpot';
import * as jackpotRemovingActionCreators from './composer/Jackpot/jackpotRemoving';

import * as updateBetPublisherDataActionCreators from './betPublisher/updateBetPublisher'
import * as clearStateBetPublisherUpdateDataActionCreators from './betPublisher/updateBetPublisher'
import * as getBetPublisherStyleDataActionCreators from './betPublisher/getBetPublisher'
import * as clearGetStateBetPublisherDataActionCreators from './betPublisher/getBetPublisher'
import * as clearStateUploadLinkLogoActionCreators from './betPublisher/uploadLinkLogo'
import * as uploadLinkLogoActionCreators from './betPublisher/uploadLinkLogo'

import * as MarketGroupsActionCreators from './composer/marketGroups';
import * as MarketDetailsActionCreators from './composer/marketDetails';
import * as LeaguesSearchActionCreators from './composer/leaguesSearch';
import * as CountriesActionCreators from './sportsdata/countries';
import * as CountriyActionCreators from './sportsdata/country';
import * as UpdateCountryCreators from './sportsdata/updateCountry';
import * as CountriyLeaguesActionCreators from './sportsdata/countryLeagues';
import * as UpdateLeagueCreators from './sportsdata/updateLeague';
import * as TeamsActionCreators from './sportsdata/teams';
import * as LeagueActionCreators from './sportsdata/league';
import * as ResultsActionCreators from './sportsdata/results';
import * as ResultActionCreators from './sportsdata/result';
import * as ResultRemoveGoalActionCreators from './sportsdata/resultRemoveGoal';
import * as ResultConfirmResultsHalfTimeActionCreators from './sportsdata/confirmResultsHalfTime';
import * as ResultConfirmResultsFullTimeActionCreators from './sportsdata/confirmResultsFullTime';
import * as AddGoalActionCreators from './sportsdata/addGoal';
import * as getEventBetActionCreators from './sportsdata/getEventBet';
import * as getBetengineTicketsActionCreators from './betengine/betengineTickets';
import * as getBetengineTicketActionCreators from './betengine/betengenieTicket';
import * as getOperatorsActionCreators from './admins/operators/operators';
import * as getSuperAdminOperatorActionCreators from './admins/operators/superAdminOperator';
import * as editeSuperAdminOperatorActionCreators from './admins/operators/editeSuperAdminOperator';
import * as clearStateSuperAdminUpdateDataActionCreators from './admins/operators/editeSuperAdminOperator';
import * as editeAdminOperatorActionCreators from './admins/operators/admin/editeAdminOperator';
import * as getAdminOperatorActionCreators from './admins/operators/admin/operator';
import * as getAdminApiActionCreators from './admins/operators/admin/adminAPI';
import * as editeAdminApiActionCreators from './admins/operators/admin/editeAdminApi';
import * as getAdminNotificationActionCreators from './admins/operators/admin/notificationAPI';
import * as updateAdminNotificationActionCreators from './admins/operators/admin/editNotification';
import * as clearAdminNotificationStateActionCreators from './admins/operators/admin/editNotification';
import * as getOperatorCreateActionCreators from './admins/operators/operatorCreate';
import * as getProfileEditActionCreators from './admins/profile/editeProfile';
import * as getProfileActionCreators from './admins/profile/profile';
import * as getTimezonesActionCreators from './admins/timezones';
import * as dailySalesToday from './dashboard/dailySalesToday';
import * as dailySalesLastSevenDays from './dashboard/dailySalesLastSevenDays';
import * as dailySalesCurrentMonth from './dashboard/dailySalesCurrentMonth';
import * as dailyTicketsLastSevenDays from './dashboard/dailyTicketsLastSevenDays';
import * as monthTableData from './dashboard/monthTable';
import * as dailyGGRLastSevenDays from './dashboard/dailyGGRLastSevenDays';
import * as dailyGGRCurrentMonth from './dashboard/dailyGGRCurrentMonth';
import * as dailyGGRCurrentMonthGraph from './dashboard/dailyGGRCurrentMonthGraph';
import {clearJackpotCreateDataError} from "./composer/Jackpot/jackpotCreate";


export default {
    ...LiveLogsActionCreators,
    ...LiveErrorActionCreators,
    ...SmartBetsActionCreators,
    ...SmartBetsCreateActionCreators,
    ...SmartBetsCreateNewDataActionCreators,
    ...SmartBetsRemovingActionCreators,
    ...SmartJackpotsUpdatingActionCreators,
    ...SmartJackpotActionCreators,
    ...SmartJackpotsPreviewActionCreators,
    ...quicktipActionCreators,
    ...quicktipsActionCreators,
    ...quicktipCreateActionCreators,
    ...quicktipUpdatingActionCreators,
    ...quicktipRemovingActionCreators,
    ...quickbetActionCreators,
    ...quickbetsActionCreators,
    ...quickbetCreateActionCreators,
    ...quickbetRemovingActionCreators,
    ...quickbetUpdatingActionCreators,
    ...superbetsActionCreators,
    ...superbetActionCreators,
    ...superbetsRemovingActionCreators,
    ...superbetsUpdatingActionCreators,
    ...MarketGroupsActionCreators,
    ...MarketDetailsActionCreators,
    ...LeaguesSearchActionCreators,
    ...CountriesActionCreators,
    ...CountriyActionCreators,
    ...UpdateCountryCreators,
    ...CountriyLeaguesActionCreators,
    ...UpdateLeagueCreators,
    ...TeamsActionCreators,
    ...LeagueActionCreators,
    ...ResultsActionCreators,
    ...ResultActionCreators,
    ...ResultRemoveGoalActionCreators,
    ...ResultConfirmResultsHalfTimeActionCreators,
    ...ResultConfirmResultsFullTimeActionCreators,
    ...AddGoalActionCreators,
    ...getEventBetActionCreators,
    ...getBetengineTicketsActionCreators,
    ...getBetengineTicketActionCreators,
    ...getOperatorsActionCreators,
    ...getSuperAdminOperatorActionCreators,
    ...editeSuperAdminOperatorActionCreators,
    ...clearStateSuperAdminUpdateDataActionCreators,
    ...editeAdminOperatorActionCreators,
    ...getAdminOperatorActionCreators,
    ...getProfileEditActionCreators,
    ...getProfileActionCreators,
    ...getTimezonesActionCreators,
    ...getOperatorCreateActionCreators,
    ...getAdminApiActionCreators,
    ...editeAdminApiActionCreators,
    ...dailySalesToday,
    ...dailySalesLastSevenDays,
    ...dailySalesCurrentMonth,
    ...dailyTicketsLastSevenDays,
    ...monthTableData,
    ...dailyGGRLastSevenDays,
    ...dailyGGRCurrentMonth,
    ...dailyGGRCurrentMonthGraph,
    ...quicktipClearErrorActionCreators,
    ...quicktipUpdatingRemovingStateActionCreators,
    ...clearSmarbetCreateDataStateActionCreators,
    ...clearSmarbetUpdateDataStateActionCreators,
    ...clearStateQuicbetUpdatingActionCreators,
    ...getAdminNotificationActionCreators,
    ...updateAdminNotificationActionCreators,
    ...clearAdminNotificationStateActionCreators,
    ...updateBetPublisherDataActionCreators,
    ...clearStateBetPublisherUpdateDataActionCreators,
    ...getBetPublisherStyleDataActionCreators,
    ...clearGetStateBetPublisherDataActionCreators,
    ...clearStateUploadLinkLogoActionCreators,
    ...uploadLinkLogoActionCreators,
    ...jackpotActionCreators,
    ...jackpotsActionCreators,
    ...jackpotCreateActionCreators,
    ...clearJackpotCreateActionCreators,
    ...jackpotUpdatingActionCreators,
    ...clearStateJackpotUpdatingActionCreators,
    ...jackpotRemovingActionCreators
};
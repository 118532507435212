import {GetEventBetAction, GetEventBetState, GetEventBetTypes} from "../../types/sportsdata/getEventBet";

const initialState: GetEventBetState = {
    getEventBetData: [],
    responceStatus: false,
    error: null,
};

export const getEventBetReducer = (state = initialState, action: GetEventBetAction): GetEventBetState => {
    switch (action.type) {
        case GetEventBetTypes.FETCH_GET_EVENT_BET_DATA:
            return { ...state, responceStatus: true, error: null, getEventBetData: [] };
        case GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_SUCCESS:
            return { ...state, responceStatus: false, error: null, getEventBetData: action.payload };
        case GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_ERROR:
            return { ...state, responceStatus: false, error: action.payload, getEventBetData: [] };
        default:
            return state;
    }
};
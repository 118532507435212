/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from "react-intl";
import SidebarSwithOperator from "../../../../app/modules/auth/core/SwitchOperator/SidebarSwitchOperator";
import React from "react";


const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
        <div className="d-flex flex-center w-100 scroll-px">
            <SidebarSwithOperator />
        </div>
    </div>
  )
}

export {SidebarFooter}

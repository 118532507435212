export interface GetEventBetState {
    getEventBetData: any;
    responceStatus: boolean;
    error: null | string;
}


export enum GetEventBetTypes {
    FETCH_GET_EVENT_BET_DATA = "FETCH_GET_EVENT_BET_DATA",
    FETCH_GET_EVENT_BET_DATA_SUCCESS = "FETCH_GET_EVENT_BET_DATA_SUCCESS",
    FETCH_GET_EVENT_BET_DATA_ERROR = "FETCH_GET_EVENT_BET_DATA_ERROR",
}

export type GetEventBetAction =
    | { type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA }
    | { type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_SUCCESS; payload: any[] }
    | { type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_ERROR; payload: string };
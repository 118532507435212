import axios from 'axios'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'auth-data'
const AUTH_LOCAL_STORAGE_OPERATOR_KEY = 'default-operator-data';
const AUTH_LOCAL_STORAGE_DETAIL_OPERATOR_DATA_KEY = 'detail-operator-data';
const AUTH_SESSION_STORAGE_DETAIL_OPERATOR_DATA_KEY = 'session-detail-operator-data';
const AUTH_SESSION_STORAGE_OPERATOR_KEY = 'session-operator-data'
const AUTH_SESSION_STORAGE_OPERATOR_CYRRENCY = 'session-operator-currency-data'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const setStorageCurrentOperator = (currentOperator:string) => {
    getOperatorInfo(currentOperator);
    const lsValue = JSON.stringify(currentOperator);
    if (!localStorage) {
        return
    }
    localStorage.setItem(AUTH_LOCAL_STORAGE_OPERATOR_KEY, lsValue)
}
const getOperatorInfo = async (operatorName: string) => {
  try {
    const response = <any> await axios.get(`/api/operators/${operatorName}/info`);
  
    let data = {
      company_name: response.data.company_name,
      site: response.data.website_url,
      currency: response.data.currency,
    }
    const lsValue = JSON.stringify(data);
    if (!localStorage) {
        return
    }
    
    localStorage.setItem(AUTH_LOCAL_STORAGE_DETAIL_OPERATOR_DATA_KEY, lsValue)
  } catch (error) {
    console.error('Error while fetching operator info:', error);
    throw error; 
  }
};
const getStoragedOperatorInfo = () => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_DETAIL_OPERATOR_DATA_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const operator = JSON.parse(lsValue) as { site?: string };
    if (operator) {
      return operator;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const removeOperatorInfo = () => {
  if (!localStorage) {
      return
  }

  localStorage.removeItem(AUTH_LOCAL_STORAGE_DETAIL_OPERATOR_DATA_KEY);

}

const getSessionOperatorInfo = async (operatorName: string) => {
  try {
    const response = <any> await axios.get(`/api/operators/${operatorName}`);
    
    let data = {
      website_url: response.data.website_url
    }
    const lsValue = JSON.stringify(data);
    if (!sessionStorage) {
        return
    }
    
    sessionStorage.setItem(AUTH_SESSION_STORAGE_DETAIL_OPERATOR_DATA_KEY, lsValue)
  } catch (error) {
    console.error('Error while fetching operator info:', error);
    throw error; 
  }
};
const getStoragedSessionOperatorInfo = () => {
  if (!sessionStorage) {
    return;
  }

  const lsValue: string | null = sessionStorage.getItem(AUTH_SESSION_STORAGE_DETAIL_OPERATOR_DATA_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const operator = JSON.parse(lsValue) as { website_url?: string };
    if (operator) {
      return operator;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};
const removeStoragedSessionOperatorInfo = () => {
  if (!sessionStorage) {
      return
  }

  sessionStorage.removeItem(AUTH_SESSION_STORAGE_DETAIL_OPERATOR_DATA_KEY);

}

const getStoragedCurrentOperator = () => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_OPERATOR_KEY)
    if (!lsValue) {
        return
    }

    try {
        const operator = JSON.parse(lsValue) as string
        if (operator) {
            // You can easily check auth_token expiration also
            return operator
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setSessionStorageCurrentOperator = (currentOperator:string) => {
  getSessionOperatorInfo(currentOperator);
    const lsValue = JSON.stringify(currentOperator);
    if (!localStorage) {
        return
    }
    sessionStorage.setItem(AUTH_SESSION_STORAGE_OPERATOR_KEY, lsValue)
}
const getSessionStoragedCurrentOperator = () => {
    if (!sessionStorage) {
        return
    }
    const lsValue: string | null = sessionStorage.getItem(AUTH_SESSION_STORAGE_OPERATOR_KEY)
    if (!lsValue) {
        return
    }

    try {
        const operator = JSON.parse(lsValue) as string
        if (operator) {
            // You can easily check auth_token expiration also
            return operator
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}
const removeSessionStoragedCurrentOperator = () => {
    if (!sessionStorage) {
        return
    }

    sessionStorage.removeItem(AUTH_SESSION_STORAGE_OPERATOR_KEY);

}
const setSessionStorageOperatorCurrency = (operatorCurrency:string) => {
    const lsValue = JSON.stringify(operatorCurrency);
    if (!localStorage) {
        return
    }
    sessionStorage.setItem(AUTH_SESSION_STORAGE_OPERATOR_CYRRENCY, lsValue)
}
const getSessionStoragedOperatorCurrency = () => {
    if (!sessionStorage) {
        return
    }
    const lsValue: string | null = sessionStorage.getItem(AUTH_SESSION_STORAGE_OPERATOR_CYRRENCY)
    if (!lsValue) {
        return
    }

    try {
        const operator = JSON.parse(lsValue) as string
        if (operator) {
            // You can easily check auth_token expiration also
            return operator
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}
const removeSessionStoragedOperatorCurreny = () => {
    if (!sessionStorage) {
        return
    }

    sessionStorage.removeItem(AUTH_SESSION_STORAGE_OPERATOR_CYRRENCY);

}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(AUTH_LOCAL_STORAGE_OPERATOR_KEY)
    localStorage.removeItem(AUTH_LOCAL_STORAGE_DETAIL_OPERATOR_DATA_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {
    getAuth,
    setAuth,
    setStorageCurrentOperator,
    getStoragedCurrentOperator,
    removeAuth,
    AUTH_LOCAL_STORAGE_KEY,
    setSessionStorageCurrentOperator,
    getSessionStoragedCurrentOperator,
    removeSessionStoragedCurrentOperator,
    setSessionStorageOperatorCurrency,
    getSessionStoragedOperatorCurrency,
    removeSessionStoragedOperatorCurreny,
    getStoragedOperatorInfo,
    removeOperatorInfo,
    getStoragedSessionOperatorInfo,
    removeStoragedSessionOperatorInfo
}

import {dailyTicketsLastSevenDaysAction, dailyTicketsLastSevenDaysActionTypes, dailyTicketsLastSevenDaysState} from "../../types/dashboard/dailyTicketsLastSevenDays";

const initialState: dailyTicketsLastSevenDaysState = {
    dailyTicketsLastSevenDaysData: {},
    loading: false,
    error: null,
};

export const dailyTicketsLastSevenDaysReducer = (state = initialState, action: dailyTicketsLastSevenDaysAction): dailyTicketsLastSevenDaysState => {
    switch (action.type) {
        case dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA:
            return { ...state, loading: true, error: null, dailyTicketsLastSevenDaysData: {} };
        case dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailyTicketsLastSevenDaysData: action.payload };
        case dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_TODAY_LAST_SEVEN_DAYS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailyTicketsLastSevenDaysData: {} };
        default:
            return state;
    }
};

import axios from "axios";
import { Dispatch } from "redux";
import {getBetPublisherAction, getBetPublisherActionTypes} from "../../types/betPublisher/getBetPublisherSettings";

export const getBetPublisherStyleData = (type: string, operator: string): any => {
    return async (dispatch: Dispatch<getBetPublisherAction>) => {
        try {
            dispatch({ type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/betpublisher/betcard_templates/${type}?operator=${operator}`,
                withCredentials: false,
            });
            dispatch({ type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(`/n`);
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({ type: getBetPublisherActionTypes.FETCH_BETPUBLISHER_DATA_ERROR, payload: errorMessage });
        }
    }
}
export const clearGetStateBetPublisherData = () => {
    return (dispatch: Dispatch<getBetPublisherAction>) => {
        dispatch({
            type: getBetPublisherActionTypes.CLEAR_BETPUBLISHER_STATE,
            payload: {}
        });
    };
};
import {TeamsActionTypes, TeamsAction, TeamsState} from "../../types/sportsdata/teamsTypes";


const initialState: TeamsState = {
    teamsData: {},
    loading: false,
    error: null,
};

export const teamsReducer = (state = initialState, action: TeamsAction): TeamsState => {
    switch (action.type) {
        case TeamsActionTypes.FETCH_TEAMS_DATA:
            return { ...state, loading: true, error: null, teamsData: {} };
        case TeamsActionTypes.FETCH_TEAMS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, teamsData: action.payload };
        case TeamsActionTypes.FETCH_TEAMS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, teamsData: {} };
        default:
            return state;
    }
};
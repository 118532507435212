export interface BetengineTicketState {
    betEngineTicketData: any;
    loading: boolean;
    error: null | string;
}


export enum BetengineTicketActionTypes {
    FETCH_BETENGINE_TICKET_DATA = "FETCH_BETENGINE_TICKET_DATA",
    FETCH_BETENGINE_TICKET_DATA_SUCCESS = "FETCH_BETENGINE_TICKET_DATA_SUCCESS",
    FETCH_BETENGINE_TICKET_DATA_ERROR = "FETCH_BETENGINE_TICKET_DATA_ERROR",
}

export type BetengineTicketAction =
    | { type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA }
    | { type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_SUCCESS; payload: any }
    | { type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_ERROR; payload: string };


import {Dispatch} from "redux";
import {QuickbetsAction, QuickbetsActionTypes} from "../../../types/composer/Quickbet/quickbetsTypes";
import axios from "axios";


export const getQuickbetsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/quickbets?operator=${operator}` : `/api/composer/quickbets`;
    return async (dispatch: Dispatch<QuickbetsAction>) => {
        try {
            dispatch({type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA_ERROR, payload: 'Something wend wrong check Quickbets action controller'});
        }
    }
}
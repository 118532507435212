import {UpdateNotificationActionTypes, updateNotificationState, updateNotificationAction} from "../../../../types/admins/operators/admin/updateNotificationTypes";

const initialState: updateNotificationState = {
    updateNotificationData: {},
    response: false,
    error: null,
};

export const updateNotificationReducer = (state = initialState, action: updateNotificationAction): updateNotificationState => {
    switch (action.type) {
        case UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA:
            return { ...state, response: true, error: null, updateNotificationData: {} };
        case UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA_SUCCESS:
            return { ...state, response: false, error: null, updateNotificationData: action.payload };
        case UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA_ERROR:
            return { ...state, response: false, error: action.payload, updateNotificationData: {} };
        case UpdateNotificationActionTypes.CLEAR_STATE_UPDATE_NOTIFICATION_DATA_ERROR:
            return { ...state, response: false, error: null, updateNotificationData: {} };
        default:
            return state;
    }
};

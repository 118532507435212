export interface SmartBetsCreateNewState {
    smartBetsCreateNewData: any;
    response: boolean;
    error_responce: null | string;
}


export enum SmartBetsCreateNewActionTypes {
    FETCH_SMART_BETS_CRATE_NEW_DATA = "FETCH_SMART_BETS_CRATE_NEW_DATA",
    FETCH_SMART_BETS_CRATE_NEW_DATA_SUCCESS = "FETCH_SMART_BETS_CRATE_NEW_DATA_SUCCESS",
    FETCH_SMART_BETS_CRATE_NEW_DATA_ERROR = "FETCH_SMART_BETS_CRATE_NEW_DATA_ERROR",
    CLEAR_SMART_BETS_CRATE_STATE = "CLEAR_SMART_BETS_CRATE_STATE",
}

export type SmartBetsCreateNewAction =
    | { type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA }
    | { type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_SUCCESS; payload: any[] }
    | { type: SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_ERROR; payload: string }
    | { type: SmartBetsCreateNewActionTypes.CLEAR_SMART_BETS_CRATE_STATE; payload:{} };
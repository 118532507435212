import {QuickbetsAction, QuickbetsActionTypes, QuickbetsState} from "../../../types/composer/Quickbet/quickbetsTypes";

const initialState: QuickbetsState = {
    quickbetsData: [],
    loading: false,
    error: null,
};

export const quickbetsReducer = (state = initialState, action: QuickbetsAction): QuickbetsState => {
    switch (action.type) {
        case QuickbetsActionTypes.FETCH_QUICKBETS_DATA:
            return { ...state, loading: true, error: null, quickbetsData: [] };
        case QuickbetsActionTypes.FETCH_QUICKBETS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quickbetsData: action.payload };
        case QuickbetsActionTypes.FETCH_QUICKBETS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quickbetsData: [] };
        default:
            return state;
    }
};

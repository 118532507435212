import { UpdateSuperbetAction, UpdateSuperbetActionTypes, UpdateSuperbetState } from "../../../types/composer/Superbet/updateSuperbetTypes";
interface ErrorResponse {
    code: number;
    message: string;
}
const initialState: UpdateSuperbetState = {
    updateSuperbetResponce: [],
    loading: false,
    error: null as ErrorResponse | null,
};

export const updateSuperbetReducer = (state = initialState, action: UpdateSuperbetAction): UpdateSuperbetState => {
    switch (action.type) {
        case UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA:
            return { ...state, loading: true, error: null, updateSuperbetResponce: [] };
        case UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, updateSuperbetResponce: action.payload };
        case UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, updateSuperbetResponce: [] };
        default:
            return state;
    }
};

import {leaguesSearchState, LeaguesSearchAction, LeaguesSearchActionTypes} from "../../types/composer/leaguesSearchTypes";

const initialState: leaguesSearchState = {
    leaguesSearchVal: {},
    loading: false,
    error: null,
};

export const leaguesSearchReducer = (state = initialState, action: LeaguesSearchAction): leaguesSearchState => {
    switch (action.type) {
        case LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA:
            return { ...state, loading: true, error: null, leaguesSearchVal: {} };
        case LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_SUCCESS:
            return { ...state, loading: false, error: null, leaguesSearchVal: action.payload };
        case LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, leaguesSearchVal: {} };
        default:
            return state;
    }
};

import {QuickbetRemovingAction, QuickbetRemovingActionTypes, QuickbetRemovingState} from "../../../types/composer/Quickbet/quickbetRemoveTypes";

const initialState: QuickbetRemovingState = {
    quickbetRemovingData: [],
    loading: false,
    error: null,
};

export const quickbetRemovingReducer = (state = initialState, action: QuickbetRemovingAction): QuickbetRemovingState => {
    switch (action.type) {
        case QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA:
            return { ...state, loading: true, error: null, quickbetRemovingData: [] };
        case QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quickbetRemovingData: action.payload };
        case QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quickbetRemovingData: [] };
        default:
            return state;
    }
};

import React, {FC, useEffect, useState} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {ColumnChartWidget} from "../../componenets/ColumnChartWidget";
import {useIntl} from "react-intl";

interface TI {
    currency: string,
    operator: string
}

const DailyTicketsLastSevenDaysContainer: FC<TI> = ({currency, operator}) => {
    const {dailyTicketsLastSevenDays} = useAction();
    const {dailyTicketsLastSevenDaysData} = useTypedSelector((state) => state.dailyTicketsLastSevenDaysReducer);
    const intl = useIntl();

    useEffect(()=> {
        if (operator) {
            dailyTicketsLastSevenDays(operator);
        }
    }, [operator])
    return(
        <>
            {
                Object.keys(dailyTicketsLastSevenDaysData).length > 0 ?
                    <ColumnChartWidget
                        daily={true}
                        currencyVal={currency}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='info'
                        chartHeight='200px'
                        title={intl.formatMessage({id: 'DASHBOARD.dailyTicketsLastSevenDaysData'})}
                        hintText={intl.formatMessage({id: 'DASHBOARD.dailyTicketsLastSevenDaysDataHint'})}
                        graphTitle={intl.formatMessage({id: 'DASHBOARD.dailyTickets'})}
                        data={dailyTicketsLastSevenDaysData}
                    />
                    :null
            }
        </>
    )
}

export default DailyTicketsLastSevenDaysContainer;
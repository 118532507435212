export interface ErrorResponse {
    type: string;
    loc: string[];
    msg: string;
    input: string;
    url: string;
}

export interface UpdateQuickbetState {
    updateQuickbetDataResponse: any;
    response: boolean;
    error: string | null;
}

export enum UpdateQuickbetActionTypes {
    FETCH_QUICKBET_JACKPOT_DATA = "FETCH_QUICKBET_JACKPOT_DATA",
    FETCH_QUICKBET_JACKPOT_DATA_SUCCESS = "FETCH_QUICKBET_JACKPOT_DATA_SUCCESS",
    FETCH_QUICKBET_JACKPOT_DATA_ERROR = "FETCH_QUICKBET_JACKPOT_DATA_ERROR",
    CLEAR_QUICKBET_STATE = "CLEAR_QUICKBET_STATE",
}

export type UpdateQuickbetAction =
    | { type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA }
    | { type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_ERROR; payload: string | null }
    | { type: UpdateQuickbetActionTypes.CLEAR_QUICKBET_STATE; payload: {}}

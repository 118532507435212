export interface TeamsState {
    teamsData: any;
    loading: boolean;
    error: null | string;
}


export enum TeamsActionTypes {
    FETCH_TEAMS_DATA = "FETCH_TEAMS_DATA",
    FETCH_TEAMS_DATA_SUCCESS = "FETCH_TEAMS_DATA_SUCCESS",
    FETCH_TEAMS_DATA_ERROR = "FETCH_TEAMS_DATA_ERROR",
}

export type TeamsAction =
    | { type: TeamsActionTypes.FETCH_TEAMS_DATA }
    | { type: TeamsActionTypes.FETCH_TEAMS_DATA_SUCCESS; payload: any[] }
    | { type: TeamsActionTypes.FETCH_TEAMS_DATA_ERROR; payload: string };
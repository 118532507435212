import {Dispatch} from "redux";
import {MarketDetailsActionTypes, MarketDetailsAction} from "../../types/composer/marketDetails";
import axios from "axios";


export const getMarketDetailsData = (marketCode: string): any => {
    return async (dispatch: Dispatch<MarketDetailsAction>) => {
        try {
            dispatch({type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/markets?market_code=${marketCode}`,
                withCredentials: false
            });
            dispatch({type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: MarketDetailsActionTypes.FETCH_MARKET_DETAILS_DATA_ERROR, payload: 'Something wend wrong check Market Details action controller'});
        }
    }
}
import {Dispatch} from "redux";
import { RemoveSuperbetAction, RemoveSuperbetActionTypes } from "../../../types/composer/Superbet/removeSuperbetTypes";
import axios from "axios";


export const removeSuperbetData = (id: string): any => {
    
    return async (dispatch: Dispatch<RemoveSuperbetAction>) => {
        try {
            dispatch({type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA});
            const response = await axios({
                method: 'delete',
                url: `/api/composer/superbets/${id}`,
                withCredentials: false
            });
            dispatch({type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_ERROR, payload: 'Something wend wrong check Remove Superbets action controller'});
        }
    }
}
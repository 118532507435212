import axios from "axios";
import {Dispatch} from "redux";
import {AddGoalTypes, AddGoalAction} from "../../types/sportsdata/addGoal";

export const addGoal = (id:string, addGoalData: object): any => {
    return async (dispatch: Dispatch<AddGoalAction>) => {
        try {
            dispatch({type: AddGoalTypes.FETCH_ADD_GOAL_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/sportsdata/results/${id}/add_goal`,
                data: addGoalData,
                withCredentials: false,
            });
            dispatch({type: AddGoalTypes.FETCH_ADD_GOAL_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: AddGoalTypes.FETCH_ADD_GOAL_DATA_ERROR, payload: 'Something wend wrong check ADD GOAL action controller'});
        }
    }
}
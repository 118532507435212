import {SmartBetsAction, SmartBetsActionTypes, SmartBetsState} from "../../../types/composer/SmartBet/smartBets";

const initialState: SmartBetsState = {
    smartBetsData: [],
    loading: false,
    error: null,
};

export const smartBetsReducer = (state = initialState, action: SmartBetsAction): SmartBetsState => {
    switch (action.type) {
        case SmartBetsActionTypes.FETCH_SMART_BETS_DATA:
            return { ...state, loading: true, error: null, smartBetsData: [] };
        case SmartBetsActionTypes.FETCH_SMART_BETS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, smartBetsData: action.payload };
        case SmartBetsActionTypes.FETCH_SMART_BETS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, smartBetsData: [] };
        default:
            return state;
    }
};

export interface leaguesSearchState {
    leaguesSearchVal: any;
    loading: boolean;
    error: null | string;
}


export enum LeaguesSearchActionTypes {
    FETCH_LEAGUE_SEARCH_DATA = "FETCH_LEAGUE_SEARCH_DATA",
    FETCH_LEAGUE_SEARCH_DATA_SUCCESS = "FETCH_LEAGUE_SEARCH_DATA_SUCCESS",
    FETCH_LEAGUE_SEARCH_DATA_ERROR = "FETCH_LEAGUE_SEARCH_DATA_ERROR",
}

export type LeaguesSearchAction =
    | { type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA }
    | { type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_SUCCESS; payload: any[] }
    | { type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_ERROR; payload: string };
import {Dispatch} from "redux";
import {EditAdminApiActionTypes, EditAdminApiAction} from "../../../../types/admins/operators/admin/editAdminApiTypes";
import axios from "axios";

export const editAdminApiData = (id:string, data: any): any => {
    return async (dispatch: Dispatch<EditAdminApiAction>) => {
        try {
            dispatch({type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA});
            const response = await axios({
                method: 'patch',
                url: `/api/operators/${id}/api_settings`,
                data: data,
                withCredentials: false
            });
            dispatch({type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_ERROR, payload: 'Something wend wrong check Edite Admin API action controller'});
        }
    }
}
import {Dispatch} from "redux";
import axios from "axios";
import {ResultsActionTypes, ResultsAction} from "../../types/sportsdata/resultsTypes";

export const resultRemoveGoal = (id: string, dataInfo: object ): any => {
    return async (dispatch: Dispatch<ResultsAction>) => {
        try {
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/sportsdata/results/${id}/remove_goal`,
                data: dataInfo,
                withCredentials: true
            });
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: ResultsActionTypes.FETCH_RESULTS_DATA_ERROR, payload: 'Something wend wrong check RESULTS action controller'});
        }
    }
}
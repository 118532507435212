export interface CountryState {
    countryData: any[];
    loading: boolean;
    error: null | string;
}


export enum CountryActionTypes {
    FETCH_COUNTRY_DATA = "FETCH_COUNTRY_DATA",
    FETCH_COUNTRY_DATA_SUCCESS = "FETCH_COUNTRY_DATA_SUCCESS",
    FETCH_COUNTRY_DATA_ERROR = "FETCH_COUNTRIES_DATA_ERROR",
}

export type CountryAction =
    | { type: CountryActionTypes.FETCH_COUNTRY_DATA }
    | { type: CountryActionTypes.FETCH_COUNTRY_DATA_SUCCESS; payload: any[] }
    | { type: CountryActionTypes.FETCH_COUNTRY_DATA_ERROR; payload: string };
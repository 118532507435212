export interface OperatorState {
    operatorData: any;
    loading: boolean;
    error: null | string;
}


export enum OperatorActionTypes {
    FETCH_OPERATOR_DATA = "FETCH_OPERATOR_DATA",
    FETCH_OPERATOR_DATA_SUCCESS = "FETCH_OPERATOR_DATA_SUCCESS",
    FETCH_OPERATOR_DATA_ERROR = "FETCH_OPERATOR_DATA_ERROR",
}

export type OperatorAction =
    | { type: OperatorActionTypes.FETCH_OPERATOR_DATA }
    | { type: OperatorActionTypes.FETCH_OPERATOR_DATA_SUCCESS; payload: any[] }
    | { type: OperatorActionTypes.FETCH_OPERATOR_DATA_ERROR; payload: string };


import React, {FC, useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from "../../../../_metronic/partials";
import {getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface Data {
    date: string;
    value: number;
}
type Props = {
    daily: boolean
    className: string
    chartColor: string
    chartHeight: string
    title: string,
    graphTitle: string,
    hintText: string,
    data: {
        total_sum: number;
        data: Data[];
    },
    currencyVal: string
}

export const ColumnChartWidget: React.FC<Props> = ({className, chartColor, chartHeight, title, graphTitle,  data, currencyVal, daily, hintText}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data.data, graphTitle, daily))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, mode])

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
                {/* begin::Hidden */}
                <div className='card-header pt-5'>
                        <div className='card-title d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>{
                                !daily ?
                                    currencyVal
                                    : null
                            }</span>

                                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                                {
                                    !daily ?
                                        (data.total_sum / 100).toLocaleString('en-US', {maximumFractionDigits: 2})
                                        : data.total_sum
                                }
                            </span>
                            </div>
                            <OverlayTrigger placement="top" overlay={<Tooltip>{hintText}</Tooltip>}>
                                <span className='text-gray-400 pt-1 fw-semibold fs-6'>
                                        {title}
                                    <i className='las la-info-circle m-1'/>
                                    </span>
                            </OverlayTrigger>
                        </div>
                </div>

                {/* begin::Chart */}
                <div ref={chartRef} className='mixed-widget-10-chart'></div>
                {/* end::Chart */}
            </div>
        </div>
    )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any[], graphTitle: string, isDaily: boolean): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor);
    let seriesData;
    if (!isDaily) {
        seriesData = [{
            name: graphTitle,
            data: data.map(item => (item.value / 100))
        }]
    } else {
        seriesData = [{
            name: graphTitle,
            data: data.map(item => item.value)
        }]
    }

    return {
        series: seriesData,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: chartHeight,
            width: '100%',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent'],
        },
        xaxis: {
            categories: data.map(item => item.date),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '10px',
                },
            },
        },
        yaxis: {
            show: false,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                formatter: function (value) {
                    return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
                }
            },

        },
        fill: {
            type: 'solid',
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
        },
        colors: [baseColor, secondaryColor],
        grid: {
            padding: {
                top: 10,
            },
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}

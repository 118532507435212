import {EditAdminApiAction, EditAdminApiActionTypes, EditAdminApiState} from "../../../../types/admins/operators/admin/editAdminApiTypes";

const initialState: EditAdminApiState = {
    editAdminApiData: [],
    loading: false,
    error: null,
};

export const editApiOperatorReducer = (state = initialState, action: EditAdminApiAction): EditAdminApiState => {
    switch (action.type) {
            case EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA:
            return { ...state, loading: true, error: null, editAdminApiData: [] };
        case EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_SUCCESS:
            return { ...state, loading: false, error: null, editAdminApiData: action.payload };
        case EditAdminApiActionTypes.FETCH_EDIT_ADMIN_API_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, editAdminApiData: [] };
        default:
            return state;
    }
};

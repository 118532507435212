import {Dispatch} from "redux";
import {LiveLogsAction, LiveLogsActionTypes} from "../../types/composer/liveLogsTypes";
import axios from "axios";


export const getLiveLogsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/betcard_logs?operator=${operator}` : `/api/composer/betcard_logs`;
    return async (dispatch: Dispatch<LiveLogsAction>) => {
        try {
            dispatch({type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_ERROR, payload: 'Something wend wrong check Live Log action controller'});
        }
    }
}
import {QuicktipCreateAction, QuicktipCreateActionTypes, QuicktipCreateState} from "../../../types/composer/Quicktip/quicktipCreate";

const initialState: QuicktipCreateState = {
    quicktipCreateDataVal: {},
    loading: false,
    error_responce: null,
};

export const quicktipCreateReducer = (state = initialState, action: QuicktipCreateAction): QuicktipCreateState => {
    switch (action.type) {
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA:
            return { ...state, loading: true, error_responce: null, quicktipCreateDataVal: {} };
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, quicktipCreateDataVal: action.payload };
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, quicktipCreateDataVal: {} };
        case QuicktipCreateActionTypes.CLEAR_QUICKTIP_CRATE_DATA_ERROR:
            return { ...state, loading: false, error_responce: {}, quicktipCreateDataVal: {} };
        default:
            return state;
    }
};

import axios from "axios";
import {Dispatch} from "redux";
import {GetEventBetTypes, GetEventBetAction} from "../../types/sportsdata/getEventBet";

export const getEventBet = ( eventBetData: any): any => {
    return async (dispatch: Dispatch<GetEventBetAction>) => {
        try {
            dispatch({type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/bets?event_id=${eventBetData.event_id}&bet_id=${eventBetData.bet_id}`,
                data: eventBetData,
                withCredentials: false,
            });
            dispatch({type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            console.log(error);
            dispatch({type: GetEventBetTypes.FETCH_GET_EVENT_BET_DATA_ERROR, payload: 'Something went wrong. Check the stake data you entered or the action controller'});
        }
    }
}
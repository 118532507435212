import {dailySalesLastSevenDaysAction, dailySalesCurrentMonthActionTypes, dailySalesCurrentMonthState} from "../../types/dashboard/dailySalesCurrentMonth";

const initialState: dailySalesCurrentMonthState = {
    dailySalesCurrentMonthData: {},
    loading: false,
    error: null,
};

export const dailySalesCurrentMonthReducer = (state = initialState, action: dailySalesLastSevenDaysAction): dailySalesCurrentMonthState => {
    switch (action.type) {
        case dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA:
            return { ...state, loading: true, error: null, dailySalesCurrentMonthData: {} };
        case dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailySalesCurrentMonthData: action.payload };
        case dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_SEVEN_DAYS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailySalesCurrentMonthData: {} };
        default:
            return state;
    }
};

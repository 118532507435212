import {dailyGGRLastSevenDaysActionTypes, dailyGGRLastSevenDaysState, dailyGGRLastSevenDaysAction} from "../../types/dashboard/dailyGGRLastSevenDays";

const initialState: dailyGGRLastSevenDaysState = {
    dailyGGRLastSevenDaysData: {},
    loading: false,
    error: null,
};

export const dailyGGRLastSevenDaysReducer = (state = initialState, action: dailyGGRLastSevenDaysAction): dailyGGRLastSevenDaysState => {
    switch (action.type) {
        case dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA:
            return { ...state, loading: true, error: null, dailyGGRLastSevenDaysData: {} };
        case dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_LAST_SEVEN_DAYS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailyGGRLastSevenDaysData: action.payload };
        case dailyGGRLastSevenDaysActionTypes.FETCH_DAIYLY_GGR_TODAY_LAST_SEVEN_DAYS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailyGGRLastSevenDaysData: {} };
        default:
            return state;
    }
};

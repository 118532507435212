import {dailySalesTodayAction, dailySalesTodayActionTypes, dailySalesTodayState} from "../../types/dashboard/dailySalesToday";

const initialState: dailySalesTodayState = {
    dailySalesTodayData: {},
    loading: false,
    error: null,
};

export const dailySalesTodayReducer = (state = initialState, action: dailySalesTodayAction): dailySalesTodayState => {
    switch (action.type) {
        case dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA:
            return { ...state, loading: true, error: null, dailySalesTodayData: {} };
        case dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailySalesTodayData: action.payload };
        case dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailySalesTodayData: {} };
        default:
            return state;
    }
};

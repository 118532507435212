import {Dispatch} from "redux";
import {SuperOperatorActionTypes, SuperOperatorAction} from "../../../types/admins/operators/superOperatorTypes";
import axios from "axios";


export const getSuperAdminOperatorData = (id:string): any => {
    return async (dispatch: Dispatch<SuperOperatorAction>) => {
        try {
            dispatch({type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/operators/${id}`,
                withCredentials: false
            });
            dispatch({type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_ERROR, payload: 'Something wend wrong check Super SuperAdminOperators action controller'});
        }
    }
}
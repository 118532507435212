export interface QuickbetState {
    quickbetData: any;
    loading: boolean;
    error: null | string;
}


export enum QuickbetActionTypes {
    FETCH_QUICKBET_DATA = "FETCH_QUICKBET_DATA",
    FETCH_QUICKBET_DATA_SUCCESS = "FETCH_QUICKBET_DATA_SUCCESS",
    FETCH_QUICKBET_DATA_ERROR = "FETCH_QUICKBET_DATA_ERROR",
}

export type QuickbetAction =
    | { type: QuickbetActionTypes.FETCH_QUICKBET_DATA }
    | { type: QuickbetActionTypes.FETCH_QUICKBET_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetActionTypes.FETCH_QUICKBET_DATA_ERROR; payload: string };
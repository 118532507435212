export interface QuickbetsState {
    quickbetsData: any[];
    loading: boolean;
    error: null | string;
}


export enum QuickbetsActionTypes {
    FETCH_QUICKBETS_DATA = "FETCH_QUICKBETS_DATA",
    FETCH_QUICKBETS_DATA_SUCCESS = "FETCH_QUICKBETS_DATA_SUCCESS",
    FETCH_QUICKBETS_DATA_ERROR = "FETCH_QUICKBETS_DATA_ERROR",
}

export type QuickbetsAction =
    | { type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA }
    | { type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetsActionTypes.FETCH_QUICKBETS_DATA_ERROR; payload: string };


import {Dispatch} from "redux";
import {BetengineTicketActionTypes, BetengineTicketAction} from "../../types/betengine/betengineTicketTypes";
import axios from "axios";


export const getBetengineTicketData = (id: string): any => {
    return async (dispatch: Dispatch<BetengineTicketAction>) => {
        try {
            dispatch({type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/betengine/tickets/${id}`,
                withCredentials: false,
            });
            dispatch({type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: BetengineTicketActionTypes.FETCH_BETENGINE_TICKET_DATA_ERROR, payload: 'Something wend wrong check Betengine Ticket action controller'});
        }
    }
}
export interface CountryLeaguesState {
    countryLeaguesData: any[];
    loading: boolean;
    error: null | string;
}


export enum CountryLeaguesActionTypes {
    FETCH_COUNTRY_LEAGUES_DATA = "FETCH_COUNTRY_LEAGUES_DATA",
    FETCH_COUNTRIES_LEAGUES_DATA_SUCCESS = "FETCH_COUNTRIES_DATA_SUCCESS",
    FETCH_COUNTRIES_LEAGUES_DATA_ERROR = "FETCH_COUNTRIES_DATA_ERROR",
}

export type CountryLeaguesAction =
    | { type: CountryLeaguesActionTypes.FETCH_COUNTRY_LEAGUES_DATA }
    | { type: CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_SUCCESS; payload: any[] }
    | { type: CountryLeaguesActionTypes.FETCH_COUNTRIES_LEAGUES_DATA_ERROR; payload: string };
import {CountryState} from "../../types/sportsdata/countryTypes";
import {CountryActionTypes} from "../../types/sportsdata/countryTypes";
import {CountryAction} from "../../types/sportsdata/countryTypes";

const initialState: CountryState = {
    countryData: [],
    loading: false,
    error: null,
};

export const countryReducer = (state = initialState, action: CountryAction): CountryState => {
    switch (action.type) {
        case CountryActionTypes.FETCH_COUNTRY_DATA:
            return { ...state, loading: true, error: null, countryData: [] };
        case CountryActionTypes.FETCH_COUNTRY_DATA_SUCCESS:
            return { ...state, loading: false, error: null, countryData: action.payload };
        case CountryActionTypes.FETCH_COUNTRY_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, countryData: [] };
        default:
            return state;
    }
};

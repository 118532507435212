import {confirmResultsFullTimeActionTypes, confirmResultsFullTimeAction , confirmResultsFullTimeState} from "../../types/sportsdata/confirmResultsFullTime";


const initialState: confirmResultsFullTimeState = {
    confirmResultsFullTimeData: [],
    loading: false,
    error: null,
};

export const confirmResultsFullTimeReducer = (state = initialState, action: confirmResultsFullTimeAction): confirmResultsFullTimeState => {
    switch (action.type) {
        case confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA:
            return { ...state, loading: true, error: null, confirmResultsFullTimeData: [] };
        case confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_SUCCESS:
            return { ...state, loading: false, error: null, confirmResultsFullTimeData: action.payload };
        case confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, confirmResultsFullTimeData: [] };
        default:
            return state;
    }
};


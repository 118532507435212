import {LiveLogsState} from "../../types/composer/liveLogsTypes";
import {LiveLogsActionTypes} from "../../types/composer/liveLogsTypes";
import {LiveLogsAction} from "../../types/composer/liveLogsTypes";


const initialState: LiveLogsState = {
    liveLogsData: [],
    loading: false,
    error: null,
};

export const liveLogsReducer = (state = initialState, action: LiveLogsAction): LiveLogsState => {
    switch (action.type) {
        case LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA:
            return { ...state, loading: true, error: null, liveLogsData: [] };
        case LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, liveLogsData: action.payload };
        case LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, liveLogsData: [] };
        default:
            return state;
    }
};

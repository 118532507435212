import {SmartBetsCreateAction, SmartBetsCreateActionTypes, SmartBetsCreateState} from "../../../types/composer/SmartBet/smartBetsCreate";

const initialState: SmartBetsCreateState = {
    smartBetsCreateData: {},
    loading: false,
    error: null,
};

export const smartBetCreateReducer = (state = initialState, action: SmartBetsCreateAction): SmartBetsCreateState => {
    switch (action.type) {
        case SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA:
            return { ...state, loading: true, error: null, smartBetsCreateData: {} };
        case SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA_SUCCESS:
            return { ...state, loading: false, error: null, smartBetsCreateData: action.payload };
        case SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, smartBetsCreateData: {} };
        default:
            return state;
    }
};

export interface ProfileState {
    profileData: any;
    loading: boolean;
    error: null | string;
}


export enum ProfileActionTypes {
    FETCH_PROFILE_DATA = "FETCH_PROFILE_DATA",
    FETCH_PROFILE_DATA_SUCCESS = "FETCH_PROFILE_DATA_SUCCESS",
    FETCH_PROFILE_DATA_ERROR = "FETCH_PROFILE_DATA_ERROR",
}

export type ProfileAction =
    | { type: ProfileActionTypes.FETCH_PROFILE_DATA }
    | { type: ProfileActionTypes.FETCH_PROFILE_DATA_SUCCESS; payload: any[] }
    | { type: ProfileActionTypes.FETCH_PROFILE_DATA_ERROR; payload: string };


import React from "react";
import {useIntl} from 'react-intl';
import {SidebarMenuItemWithSub} from "../SidebarMenuItemWithSub";
import {SidebarMenuItem} from "../SidebarMenuItem";

const SidebarMenuBetengine = () => {
    const intl = useIntl();
    return(
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'SIDEBAR.TITLE.BETENGINE'})}</span>
                </div>
            </div>
            <SidebarMenuItemWithSub
                to='betengine/tickets'
                title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.BETENGINE'})}
                icon='profile-circle'
                fontIcon='bi-person'
            >
                <SidebarMenuItem to='betengine/tickets' title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.TICKETS'})} hasBullet={true} />


            </SidebarMenuItemWithSub>
        </>

    );
};

export {SidebarMenuBetengine};
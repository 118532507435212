import {Dispatch} from "redux";
import {dailyGGRCurrentMonthActionTypes, dailyGGRCurrentMonthAction} from "../../types/dashboard/dailyGGRLastCurrentMonth";
import axios from "axios";

export const dailyGGRCurrentMonth = (operator:string): any => {
    return async (dispatch: Dispatch<dailyGGRCurrentMonthAction>) => {
        try {
            dispatch({type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_ggr_current_month_cumulative?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_ERROR, payload: 'Something wend wrong check dailyGGRCurrentMonth action controller'});
        }
    }
}
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {resetPassword} from '../core/_requests'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useIntl} from 'react-intl';

const initialValues = {
    password: '',
    changepassword: '',
}



export function ResetPassword() {
    const location = useLocation();
    let navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const intl = useIntl();
    const [loading, setLoading] = useState(false)
    const resetPassSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, `${intl.formatMessage({id: 'notification.password.minsymbols'})}`)
            .max(50, `${intl.formatMessage({id: 'notification.password.maxsymbols'})}`)
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z!@#$%^&*()[\]{}\-+=/.,])([a-zA-Z0-9!@#$%^&*()[\]{}\-+=/.,]+)$/, `${intl.formatMessage({id: 'notification.password.invalid'})}`)
            .required(`${intl.formatMessage({id: 'notification.password.required'})}`),
        changepassword: Yup.string()
            .min(6, `${intl.formatMessage({id: 'notification.password.minsymbols'})}`)
            .max(50, `${intl.formatMessage({id: 'notification.password.maxsymbols'})}`)
            .required(`${intl.formatMessage({id: 'notification.password.required'})}`)
            .oneOf([Yup.ref('password')], `${intl.formatMessage({id: 'notification.password.passwordsnotmatch'})}`),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: resetPassSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let response;
            try {
                if (token) {
                    response = await resetPassword(values.password, token);
                    if (response && typeof response === 'object' && !response.status) {
                        setStatus(`${intl.formatMessage({id: `AUTH.RESER.${response.message}`})}`);
                    } else if (response && typeof response === 'boolean') {
                        navigate("/auth/login");
                    }
                }
            } catch (error) {
                setStatus(`${intl.formatMessage({id: 'AUTH.RESER.PASSWORD.ERROR-TEXT'})}`);
                setSubmitting(false);
            } finally {
                setLoading(false);
            }
        },


    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
                {/* end::Title */}
                <div className="text-gray-500 fw-semibold fs-6" data-kt-translate="general-desc">
                    {intl.formatMessage({id: 'AUTH.RESET.HINT'})}
                </div>
            </div>
            {/* end::Heading */}

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}


            {/* begin::Form group Password */}
            <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.SIGHNUP.PASSWORD.FIELDTITLE'})}</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.SIGHNUP.PASSWORD.FIELDTITLE'})}
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    {intl.formatMessage({id: 'notification.password.createpassrecomendations'})}
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.SIGHNUP.PASSWORD.CONFIRMPASSWORD'})}</label>
                <input
                    type='password'
                    placeholder={intl.formatMessage({id: 'AUTH.REGISTER.SIGHNUP.PASSWORD.CONFIRMPASSWORDTITLE'})}
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                        },
                        {
                            'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                        }
                    )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>{intl.formatMessage({id: "BTN.SUBMIT"})}</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'notification.loading'})}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-5'
                    >
                        {intl.formatMessage({id: "BTN.CANCEL"})}
                    </button>
                </Link>
            </div>
            {/* end::Form group */}
        </form>
    )
}

import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {Sportsdata} from "../modules/sportsdata/containers/Sportsdata";
import {BetEngineTickets} from "../modules/betengine/containers/BetEngineTickets";
import {Dashboard} from "../modules/dashboard/container/Dashboard";
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from "../modules/auth";
const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const LiveLogs = lazy(() => import('../modules/composer/container/LiveLogs'));
  const ErrorLogs = lazy(() => import('../modules/composer/container/ErrorLogs'));
  const Quicktip = lazy(() => import('../modules/composer/container/Quicktip/Quicktip'));
  const Quickbet = lazy(() => import('../modules/composer/container/Quickbet/Quickbet'));
  const Jackpot = lazy(() => import('../modules/composer/container/Jackpot/Jackpot'))
  const SmartBet = lazy(() => import('../modules/composer/container/SmartBet/SmartBet'));
  const Superbets = lazy(() => import('../modules/composer/container/Superbet/Superbets'));
  const Superbet = lazy(() => import('../modules/composer/container/Superbet/Superbet'));
  const Countries = lazy(() => import('../modules/sportsdata/containers/settings/Countries'));
  const Leagues = lazy(() => import('../modules/sportsdata/containers/settings/Leagues'));
  const LeaguesPage = lazy(() => import('../modules/sportsdata/components/settings/LeaguesPage'));
  const Teams = lazy(() => import('../modules/sportsdata/containers/settings/Teams'));
  const Results = lazy(() => import('../modules/sportsdata/containers/settings/Results'));
  const Result = lazy(() => import('../modules/sportsdata/containers/settings/Result'));
  const PreMatchCountries = lazy(() => import('../modules/sportsdata/containers/pre-match/PreMatchCountries'));
  const Events = lazy(() => import('../modules/sportsdata/containers/pre-match/Events'));
  const BetEngineTicket = lazy(() => import('../modules/betengine/containers/BetEngineTicket'));
  const OperatorDetailsPage = lazy(() => import('../modules/accounts/containers/SuperUser/Operator'));
  const Publisher = lazy(() => import('../modules/publisher/container/Publisher'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
          currentUser?.is_superuser ?
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        : <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='sportsdata' element={<Sportsdata />} />
        <Route path='betengine/tickets' element={<BetEngineTickets />} />
        <Route path='/dashboard' element={<Dashboard />} />
          <Route
              path='account/*'
              element={
                  <SuspensedView>
                      <AccountPage />
                  </SuspensedView>
              }
          />
        {/* Lazy Modules COMPOSER */}
          <Route
              path='composer/logs/live-log'
              element={
                  <SuspensedView>
                      <LiveLogs />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/logs/error-log'
              element={
                  <SuspensedView>
                      <ErrorLogs />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/bet-card/quicktip'
              element={
                  <SuspensedView>
                      <Quicktip />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/bet-card/jackpot'
              element={
                  <SuspensedView>
                      <Jackpot />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/bet-card/quickbet'
              element={
                  <SuspensedView>
                      <Quickbet />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/bet-card/smart-bet'
              element={
                  <SuspensedView>
                      <SmartBet />
                  </SuspensedView>
              }
          />
           <Route
              path='composer/bet-card/superbets'
              element={
                  <SuspensedView>
                      <Superbets />
                  </SuspensedView>
              }
          />
          <Route
              path='composer/bet-card/superbets/:id'
              element={
                  <SuspensedView>
                      <Superbet />
                  </SuspensedView>
              }
          />
          {/*<Route
              path='composer/markets'
              element={
                  <SuspensedView>
                      <Markets />
                  </SuspensedView>
              }
          />*/}
          {/* Lazy Modules SPORTSDATA */}
          <Route
              path='sportsdata/settings/countries'
              element={
                  currentUser?.is_superuser ?
                      <SuspensedView>
                          <Countries />
                      </SuspensedView>
                  :     <Navigate to='/error/404' />
              }
          />
          <Route
              path='sportsdata/settings/leagues'
              element={
                  currentUser?.is_superuser ?
                    <SuspensedView>
                        <Leagues />
                    </SuspensedView>
              :  <Navigate to='/error/404' />
              }
          />
          <Route
              path='sportsdata/settings/leagues/:id'
              element={
                  <SuspensedView>
                      <LeaguesPage />
                  </SuspensedView>
              }
          />
          <Route
              path='sportsdata/settings/teams'
              element={
                currentUser?.is_superuser ?
                  <SuspensedView>
                      <Teams />
                  </SuspensedView>
                :  <Navigate to='/error/404' />
              }
          />
          <Route
              path='sportsdata/settings/results'
              element={
                  currentUser?.is_superuser ?
                      <SuspensedView>
                          <Results />
                      </SuspensedView>
                :  <Navigate to='/error/404' />
              }
          />
          <Route
              path='sportsdata/settings/results/:id'
              element={
                  <SuspensedView>
                      <Result />
                  </SuspensedView>
              }
          />
          <Route
              path='sportsdata/pre-match/countries'
              element={
                  <SuspensedView>
                      <PreMatchCountries />
                  </SuspensedView>
              }
          />
          <Route
              path='sportsdata/pre-match/events'
              element={
                  <SuspensedView>
                      <Events />
                  </SuspensedView>
              }
          />
          <Route
              path='betengine/tickets'
              element={
                  <SuspensedView>
                      <BetEngineTickets />
                  </SuspensedView>
              }
          />
          <Route
              path='betengine/tickets/:id'
              element={
                  <SuspensedView>
                      <BetEngineTicket />
                  </SuspensedView>
              }
          />
          <Route
              path='account/operators/:id'
              element={
                  <SuspensedView>
                      <OperatorDetailsPage />
                  </SuspensedView>
              }
          />
          <Route
              path='publisher/edits'
              element={
                  <SuspensedView>
                      <Publisher />
                  </SuspensedView>
              }
          />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import React, {FC} from 'react';
import {useIntl} from "react-intl";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';

interface Statistics {
    day: string;
    tickets_amount: number;
    sales: number;
    win: number;
    balance: number;
    margin: number;
}
interface TableData {
    data: Statistics[];
    currencyVal: string
}

export const MonthTable: FC<TableData> = ({data, currencyVal}) => {
    const intl = useIntl();
    const columnHelper = createColumnHelper<Statistics>();
    const columns = [
        columnHelper.accessor('day', {
            id: 'day',
            cell: info => <div className='text-center'>
                <div>{info.getValue()}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.DAY'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('tickets_amount', {
            id: 'tickets_amount',
            cell: info => <div className='text-center'>
                <div>{info.getValue()}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.TICKET'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('sales', {
            id: 'sales',
            cell: info => <div className='text-center'>
                <div>{(info.getValue() / 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.REVENUE'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('win', {
            id: 'win',
            cell: info => <div className='text-center'>
                <div>{(info.getValue() / 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.WIN'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('balance', {
            id: 'balance',
            cell: info => <div className='text-center'>
                <div>{(info.getValue() / 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.BALANCE'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('margin', {
            id: 'margin',
            cell: info => <div className='text-center'>
                <div>{info.getValue()}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'DASHBOARD.TABLE.MARGIN'})}</span>,
            footer: info => info.column.id,
        }),

    ];
    const table = useReactTable({
        data:data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    return (
        <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12'>
                <h2 className='card-label fw-bold fs-3 mb-10 text-center'><span className=''>Month Report</span></h2>
                <div className="table-responsive">
                    <table
                        className="table table-rounded table-striped border gy-7 gs-7">
                        <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr className='fw-bold text-muted' key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan} className='text-center'>
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td className='text-center' key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                {/*Pagination Start*/}
                <ul className="pagination">
                    <li className="page-item previous">
                        <button
                            className="page-link"
                            onClick={() => table.setPageIndex(0)}
                        >
                            <i className="previous" />
                        </button>
                    </li>
                    <li className="page-item previous">
                        <button
                            className="btn btn-primary"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {intl.formatMessage({id: 'TABLE.PAGINATION.PREVIOUS'})}
                        </button>
                    </li>
                    <li className="page-item previous">
                        <button
                            className="btn btn-primary"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {intl.formatMessage({id: 'TABLE.PAGINATION.NEXT'})}
                        </button>
                    </li>
                    <li className="page-item next">
                        <button
                            className="page-link"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        >
                            <i className="next" />
                        </button>
                    </li>
                </ul>
                {/* ***Pagination End*** */}
            </div>
        </div>
    )
};
export interface QuicktipCreateState {
    quicktipCreateDataVal: any;
    loading: boolean;
    error_responce: any | string;
}

export enum QuicktipCreateActionTypes {
    FETCH_QUICKTIP_CRATE_DATA = "FETCH_QUICKTIP_CRATE_DATA",
    FETCH_QUICKTIP_CRATE_DATA_SUCCESS = "FETCH_QUICKTIP_CRATE_DATA_SUCCESS",
    FETCH_QUICKTIP_CRATE_DATA_ERROR = "FETCH_QUICKTIP_CRATE_DATA_ERROR",
    CLEAR_QUICKTIP_CRATE_DATA_ERROR = 'CLEAR_QUICKTIP_CRATE_DATA_ERROR'
}

export type QuicktipCreateAction =
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA }
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_ERROR; payload: any }
    | { type: QuicktipCreateActionTypes.CLEAR_QUICKTIP_CRATE_DATA_ERROR; payload?: any; };
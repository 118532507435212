import {Dispatch} from "redux";
import {BetengineTicketsActionTypes, BetengineTicketsAction} from "../../types/betengine/betengineTicketsTypes";
import axios from "axios";

export const getBetengineTicketsData = (limit: number, pageNum: number, operator: any): any => {
    const apiURL = operator ?
        `/api/betengine/tickets?limit=${limit}&page=${pageNum}&operator=${operator}`
        : `/api/betengine/tickets?limit=${limit}&page=${pageNum}`;

    return async (dispatch: Dispatch<BetengineTicketsAction>) => {
        try {
            dispatch({type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false,
            });
            dispatch({type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_ERROR, payload: 'Something wend wrong check Betengine Tickets action controller'});
        }
    }
}
import {BetengineTicketsAction, BetengineTicketsActionTypes, BetengineTicketsState} from "../../types/betengine/betengineTicketsTypes";

const initialState: BetengineTicketsState = {
    betEngineTicketsData: {},
    loading: false,
    error: null,
};

export const betengineTicketsReducer = (state = initialState, action: BetengineTicketsAction): BetengineTicketsState => {
    switch (action.type) {
        case BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA:
            return { ...state, loading: true, error: null, betEngineTicketsData: {} };
        case BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, betEngineTicketsData: action.payload };
        case BetengineTicketsActionTypes.FETCH_BETENGINE_TICKETS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, betEngineTicketsData: {} };
        default:
            return state;
    }
};

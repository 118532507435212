import {Dispatch} from "redux";
import { QuickbetAction, QuickbetActionTypes} from "../../../types/composer/Quickbet/quickbetTypes";
import axios from "axios";

export const getQuickbetData = (id:string): any => {
    return async (dispatch: Dispatch<QuickbetAction>) => {
        try {
            dispatch({type: QuickbetActionTypes.FETCH_QUICKBET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/composer/quickbets/${id}`,
                withCredentials: false
            });
            dispatch({type: QuickbetActionTypes.FETCH_QUICKBET_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: QuickbetActionTypes.FETCH_QUICKBET_DATA_ERROR, payload: 'Something wend wrong check Quickbet action controller'});
        }
    }
}
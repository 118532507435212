export interface MarketGroupsState {
    marketsGroupsData: any[];
    loading: boolean;
    error: null | string;
}


export enum MarketGroupsActionTypes {
    FETCH_MARKET_GROUPS_DATA = "FETCH_MARKET_GROUPS_DATA",
    FETCH_MARKET_GROUPS_DATA_SUCCESS = "FETCH_MARKET_GROUPS_DATA_SUCCESS",
    FETCH_MARKET_GROUPS_DATA_ERROR = "FETCH_MARKET_GROUPS_DATA_ERROR",
}

export type MarketGroupsAction =
    | { type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA }
    | { type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_SUCCESS; payload: any[] }
    | { type: MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_ERROR; payload: string };
import {Dispatch} from "redux";
import {EditSuperOperatorAction, EditSuperOperatorActionTypes} from "../../../types/admins/operators/editSuperOperatorTypes";
import axios from "axios";

interface OperatorProps {
    enabled: boolean;
    id: string;
    name: string;
    company_name: string;
    income_tax: number;
    currency: string;
    language: string;
    timezone: string | null;
    webhook_url: string | null;
    website_url: string;
    pam_token: string;
    betengine_token: string;
    betcard_token: string;
    is_active: boolean;
    admins: string[];
    created: string;
    updated: string;
}

export const editeSuperAdminOperatorData = (id:string, data: OperatorProps): any => {
    return async (dispatch: Dispatch<EditSuperOperatorAction>) => {
        try {
            dispatch({type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA});
            const response = await axios({
                method: 'patch',
                url: `/api/operators/${id}`,
                data: data,
                withCredentials: false
            });
            dispatch({type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => {
                    const field = err.loc && err.loc.length > 1 ? err.loc[1] : 'unknown field';
                    return `${err.msg}; Field: ${field}:`;
                }).join(' ');
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }

            dispatch({
                type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_ERROR,
                payload: errorMessage
            });
        }
    }
}

export const clearStateSuperAdminUpdateData = () => {

    return (dispatch: Dispatch<EditSuperOperatorAction>) => {
        dispatch({
            type: EditSuperOperatorActionTypes.CLEAR_STATE_SUPEROPERATOR_UPDATE_DATA,
            payload: {}
        });
    };
};
import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getOperatorData, logoutDataBase} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentOperator: string | undefined
  setCurrentOperator: Dispatch<SetStateAction<string | undefined>>
  setUpdateCurrentUser: Dispatch<SetStateAction<any | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentOperator: undefined,
  setCurrentOperator: () => {},
  setUpdateCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentOperator, setCurrentOperator] = useState<string | undefined>();
  const [updateCurrentUser, setUpdateCurrentUser] = useState<string | undefined>();
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
        if (auth.operators && auth.operators.length > 0) {
            authHelper.setStorageCurrentOperator(auth.operators[0]);
            setCurrentOperator(auth.operators[0]);
        }
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    logoutDataBase();
    authHelper.removeSessionStoragedCurrentOperator();
    authHelper.removeSessionStoragedOperatorCurreny();
    authHelper.removeOperatorInfo();
    authHelper.removeStoragedSessionOperatorInfo();
    

  }
  /*const logoutDatabase*/

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, currentOperator, setCurrentOperator,setUpdateCurrentUser, logout}}>

      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
    const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

    useEffect(() => {
        const requestUser = async () => {
            try {
                if (!didRequest.current) {
                    const data = await getOperatorData();
                    if (data) {
                        setCurrentUser(data);
                    }
                }
            } catch (error) {
                console.error(error);
                if (!didRequest.current) {
                    logout();

                }
            } finally {
                setShowSplashScreen(false);
            }

            return () => (didRequest.current = true);
        };
       /* const checkAuthenticationPeriodically = () => {
            const authenticationCheckInterval = setInterval(() => {
                requestUser();
            }, 600000); // 600000 = 10 min


            return () => clearInterval(authenticationCheckInterval);
        };*/

        if (auth) {
            requestUser();
            // checkAuthenticationPeriodically();
        } else {
            logout();
            setShowSplashScreen(false);
        }
        // eslint-disable-next-line
    }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}

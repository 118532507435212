import {updateJackpotActionTypes, updateJackpotState, updateJackpotAction} from "../../../types/composer/jackpot/updateJackpotTypes";

const initialState: updateJackpotState = {
    updateJackpotData: {},
    loadingStatus: false,
    errorResponce: null,
};

export const updateJackpotReducer = (state = initialState, action: updateJackpotAction): updateJackpotState => {
    switch (action.type) {
        case updateJackpotActionTypes.UPDATE_JACKPOT_DATA:
            return { ...state, loadingStatus: true, errorResponce: null, updateJackpotData: {} };
        case updateJackpotActionTypes.UPDATE_JACKPOT_DATA_SUCCESS:
            return { ...state, loadingStatus: false, errorResponce: null, updateJackpotData: action.payload };
        case updateJackpotActionTypes.UPDATE_JACKPOT_DATA_ERROR:
            return { ...state, loadingStatus: false, errorResponce: action.payload, updateJackpotData: {} };
        case updateJackpotActionTypes.CLEAR_STATE_JACKPOT_UPDATE_DATA_ERROR:
            return { ...state, loadingStatus: false, errorResponce: null, updateJackpotData: {} };
        default:
            return state;
    }
};

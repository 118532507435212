import {updateCountryState, updateCountryAction, updateCountryActionTypes} from "../../types/sportsdata/updateCountryTypes";



const initialState: updateCountryState = {
    updateCountryData: [],
    responceStatus: false,
    error: null,
};

export const updateCountryReducer = (state = initialState, action: updateCountryAction): updateCountryState => {
    switch (action.type) {
        case updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA:
            return { ...state, responceStatus: true, error: null, updateCountryData: [] };
        case updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_SUCCESS:
            return { ...state, responceStatus: false, error: null, updateCountryData: action.payload };
        case updateCountryActionTypes.FETCH_UPDATE_COUNTRY_DATA_ERROR:
            return { ...state, responceStatus: false, error: action.payload, updateCountryData: [] };
        default:
            return state;
    }
};
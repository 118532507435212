import {Dispatch} from "redux";
import {QuickbetCreateActionTypes, QuickbetCreateAction, ErrorResponse} from "../../../types/composer/Quickbet/quickbetCreate";
import axios from "axios";


export const quickbetCreateData = (data: object): any => {
    return async (dispatch: Dispatch<QuickbetCreateAction>) => {
        try {
            dispatch({type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA});
            const response = await axios({
                method: 'post',
                url: '/api/composer/quickbets',
                data:data,
                withCredentials: false
            });
            dispatch({type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorPayload: ErrorResponse | string;
            if (error.response && error.response.data && error.response.data.detail) {
                errorPayload = {
                    type: "error",
                    loc: [], // Assuming no location details available
                    msg: error.response.data.detail[0].msg,
                    input: "", // Assuming no specific input available
                    url: "" // Assuming no specific URL available
                };
            } else {
                errorPayload = "Unknown error occurred";
            }
            dispatch({ type: QuickbetCreateActionTypes.FETCH_QUICKBET_CRATE_DATA_ERROR, payload: errorPayload });
        }
    }
}
import {MarketGroupsAction, MarketGroupsActionTypes, MarketGroupsState} from "../../types/composer/marketGroupsTypes";


const initialState: MarketGroupsState = {
    marketsGroupsData: [],
    loading: false,
    error: null,
};

export const marketGroupsReducer = (state = initialState, action: MarketGroupsAction): MarketGroupsState => {
    switch (action.type) {
        case MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA:
            return { ...state, loading: true, error: null, marketsGroupsData: [] };
        case MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, marketsGroupsData: action.payload };
        case MarketGroupsActionTypes.FETCH_MARKET_GROUPS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, marketsGroupsData: [] };
        default:
            return state;
    }
};

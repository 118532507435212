import axios from "axios";
import { Dispatch } from "redux";
import {updateBetPublisherActionTypes, updateBetPublisherAction} from "../../types/betPublisher/updateBetPublisherSettings";

export const updateBetPublisherData = (id: string, updatedBetPublisherData: object): any => {
    return async (dispatch: Dispatch<updateBetPublisherAction>) => {
        try {
            dispatch({ type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA });
            const response = await axios({
                method: 'put',
                url: `/api/betpublisher/betcard_templates/${id}`,
                data: updatedBetPublisherData,
                withCredentials: false,
            });
            dispatch({ type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(`/n`);
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({ type: updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_ERROR, payload: errorMessage });
        }
    }
}
export const clearStateBetPublisherUpdateData = () => {
    return (dispatch: Dispatch<updateBetPublisherAction>) => {
        dispatch({
            type: updateBetPublisherActionTypes.CLEAR_UPDATE_BETPUBLISHER_STATE,
            payload: {}
        });
    };
};
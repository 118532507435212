import {QuicktipPreviewActionTypes, QuicktipPreviewAction, QuicktipPreviewState} from "../../../types/composer/Quicktip/quicktipPreview";

const initialState: QuicktipPreviewState = {
    quicktipPreviewData: [],
    loading: false,
    error_responce: null,
};

export const quicktipPreviewReducer = (state = initialState, action: QuicktipPreviewAction): QuicktipPreviewState => {
    switch (action.type) {
        case QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA:
            return { ...state, loading: true, error_responce: null, quicktipPreviewData: [] };
        case QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA_SUCCESS:
            return { ...state, loading: false, error_responce: null, quicktipPreviewData: action.payload };
        case QuicktipPreviewActionTypes.FETCH_QUICKTIP_PREVIEW_DATA_ERROR:
            return { ...state, loading: false, error_responce: action.payload, quicktipPreviewData: [] };
        default:
            return state;
    }
};

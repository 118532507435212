import {Dispatch} from "redux";
import {AdminOperatorAction, AdminOperatorActionTypes} from "../../../../types/admins/operators/admin/adminOperatorTypes";
import axios from "axios";


export const getAdminOperatorData = (id:string): any => {
    return async (dispatch: Dispatch<AdminOperatorAction>) => {
        try {
            dispatch({type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/operators/${id}/info`,
                withCredentials: false
            });
            dispatch({type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_ERROR, payload: 'Something wend wrong check Admin Operators action controller'});
        }
    }
}
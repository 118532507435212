export interface EditProfileState {
    editProfileData: any;
    loading: boolean;
    error: null | string;
}


export enum EditProfileActionTypes {
    EDIT_PROFILE_DATA = "EDIT_PROFILE_DATA",
    EDIT_PROFILE_DATA_SUCCESS = "EDIT_PROFILE_DATA_SUCCESS",
    EDIT_PROFILE_DATA_ERROR = "EDIT_PROFILE_DATA_ERROR",
}

export type EditProfileAction =
    | { type: EditProfileActionTypes.EDIT_PROFILE_DATA }
    | { type: EditProfileActionTypes.EDIT_PROFILE_DATA_SUCCESS; payload: any[] }
    | { type: EditProfileActionTypes.EDIT_PROFILE_DATA_ERROR; payload: string };


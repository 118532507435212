export interface TimezonesState {
    timezonesData: any;
    loading: boolean;
    error: null | string;
}


export enum TimezonesActionTypes {
    FETCH_TIMEZONES_DATA = "FETCH_TIMEZONES_DATA",
    FETCH_TIMEZONES_DATA_SUCCESS = "FETCH_TIMEZONES_DATA_SUCCESS",
    FETCH_TIMEZONES_DATA_ERROR = "FETCH_TIMEZONES_DATA_ERROR",
}

export type TimezonesAction =
    | { type: TimezonesActionTypes.FETCH_TIMEZONES_DATA }
    | { type: TimezonesActionTypes.FETCH_TIMEZONES_DATA_SUCCESS; payload: any[] }
    | { type: TimezonesActionTypes.FETCH_TIMEZONES_DATA_ERROR; payload: string };


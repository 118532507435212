import {Dispatch} from "redux";
import {dailySalesLastSevenDaysActionTypes, dailySalesLastSevenDaysAction} from "../../types/dashboard/dailySalesLastSevenDays";
import axios from "axios";


export const dailySalesLastSevenDays = (operator:string): any => {
    return async (dispatch: Dispatch<dailySalesLastSevenDaysAction>) => {
        try {
            dispatch({type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_sales_last_seven_days?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_TODAY_LAST_SEVEN_DAYS_DATA_ERROR, payload: 'Something wend wrong check Profile action controller'});
        }
    }
}
import {updateBetPublisherAction, updateBetPublisherActionTypes, updateBetPublisherState} from "../../types/betPublisher/updateBetPublisherSettings";

export const initialState: updateBetPublisherState = {
    updatedBetPublisherData: {},
    loading: false,
    error: null,
};

export const updateBetPublisherReducer = (state = initialState, action: updateBetPublisherAction): updateBetPublisherState => {
    switch (action.type) {
        case updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA:
            return { ...state, loading: true, error: null };
        case updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_SUCCESS:
            return { ...state, loading: false, error: null, updatedBetPublisherData: action.payload };
        case updateBetPublisherActionTypes.UPDATE_BETPUBLISHER_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, updatedBetPublisherData: {} };
        case updateBetPublisherActionTypes.CLEAR_UPDATE_BETPUBLISHER_STATE:
            return { ...state, loading: false, error: null, updatedBetPublisherData: {} };
        default:
            return state;
    }
}

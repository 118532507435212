import {EditAdminOperatorAction, EditAdminOperatorActionTypes, EditAdminOperatorState} from "../../../../types/admins/operators/admin/editAdminOperatorTypes";

const initialState: EditAdminOperatorState = {
    editAdminOperatorData: [],
    loading: false,
    error: null,
};

export const editAdminOperatorReducer = (state = initialState, action: EditAdminOperatorAction): EditAdminOperatorState => {
    switch (action.type) {
            case EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA:
            return { ...state, loading: true, error: null, editAdminOperatorData: [] };
        case EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, error: null, editAdminOperatorData: action.payload };
        case EditAdminOperatorActionTypes.FETCH_EDIT_ADMIN_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, editAdminOperatorData: [] };
        default:
            return state;
    }
};

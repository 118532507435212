import React from "react";
import {useIntl} from 'react-intl';
import {SidebarMenuItemWithSub} from "../SidebarMenuItemWithSub";
import {SidebarMenuItem} from "../SidebarMenuItem";
import {useAuth} from "../../../../../../app/modules/auth";

const SidebarMenuSportsdata = () => {
    const intl = useIntl();
    const { currentUser, currentOperator, setCurrentOperator } = useAuth();
    return(
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'SIDEBAR.TITLE.SPORTSDATA'})}</span>
                </div>
            </div>
            <SidebarMenuItemWithSub
                to='sportsdata/pre-match/'
                title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.PREMATCH'})}
                icon='calendar-8'
                fontIcon='bi-person'
            >
                <SidebarMenuItem
                    to='sportsdata/pre-match/countries'
                    title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.COUNTRIES'})}
                    hasBullet={true}
                />
                <SidebarMenuItem
                    to='sportsdata/pre-match/events'
                    title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.EVENTS'})}
                    hasBullet={true}
                />
            </SidebarMenuItemWithSub>
            {
                currentUser?.is_superuser ?
                    <SidebarMenuItemWithSub
                        to='sportsdata/settings'
                        title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.SETTINGS'})}
                        icon='setting-4'
                        fontIcon='bi-person'
                    >
                        <SidebarMenuItem
                            to='sportsdata/settings/countries'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.COUNTRIES'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='sportsdata/settings/leagues'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.COMPETITIONS'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='sportsdata/settings/teams'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.TEAMS'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='sportsdata/settings/results'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.RESULTS'})}
                            hasBullet={true}
                        />

                    </SidebarMenuItemWithSub>
                    : null
            }
        </>

    );
};

export {SidebarMenuSportsdata};
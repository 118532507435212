import {Dispatch} from "redux";
import {OperatorCreateActionTypes, OperatorCreateAction} from "../../../types/admins/operators/operatorCreateTypes";
import axios from "axios";


export const operatorCreateData = (data: object): any => {
    return async (dispatch: Dispatch<OperatorCreateAction>) => {
        try {
            dispatch({type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA});
            const response = await axios({
                method: 'post',
                url: '/api/operators',
                data:data,
                withCredentials: false
            });
            dispatch({type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: OperatorCreateActionTypes.FETCH_OPERATOR_CRATE_DATA_ERROR, payload: 'Something wend wrong check Operator Create action controller'});
        }
    }
}
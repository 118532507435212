import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {getSessionStoragedCurrentOperator, getStoragedCurrentOperator, useAuth} from "../../../../../app/modules/auth";
export function MenuInner() {
    const intl = useIntl();
    const sessionOperator = getSessionStoragedCurrentOperator();
    let operator = getStoragedCurrentOperator();
    const { currentUser } = useAuth();
  return (
    <>
        {
            operator || sessionOperator ?
                <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.DASHBOARD'})} to='/dashboard' />
                : null
        }
        {
            currentUser?.is_superuser ?
                <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.SPORTSDATA'})} to='/sportsdata/settings/leagues' />
                :
                <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.SPORTSDATA'})} to='sportsdata/pre-match/countries' />
        }
        {
            currentUser?.is_superuser ?
                <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.BETCOMPOSER'})} to='/composer/logs/live-log' />
                :
                <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.BETCOMPOSER'})} to='/composer/bet-card/quicktip' />
        }
      <MenuItem title={intl.formatMessage({id: 'HEADER.NAVIGATION.BETENGINE'})} to='/betengine/tickets' />
    </>
  )
}

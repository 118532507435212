import {FC} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import * as authHelper from '../modules/auth/core/AuthHelpers'

const AppRoutes: FC = () => {
  const {currentUser} = useAuth();

  const queryParams = new URLSearchParams(window.location.search);
  const nameParam = queryParams.get('operator');
  const currencyParam = queryParams.get('currency');

  if (nameParam) {
    authHelper.setSessionStorageCurrentOperator(nameParam);
  }
  if (currencyParam) {
    authHelper.setSessionStorageOperatorCurrency(currencyParam);
  }

  return (
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                {currentUser.is_superuser ? (
                    <Route index element={<Navigate to='/dashboard' />} />
                ) : (
                    <Route index element={<Navigate to='/dashboard' />} />
                )}
              </>
          ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
          )}
        </Route>
      </Routes>
  )
}

export {AppRoutes}

import {QuicktipState, QuicktipActionTypes, QuicktipAction} from "../../../types/composer/Quicktip/quicktipTypes";

const initialState: QuicktipState = {
    quicktipData: [],
    loading: false,
    error: null,
};

export const quicktipReducer = (state = initialState, action: QuicktipAction): QuicktipState => {
    switch (action.type) {
        case QuicktipActionTypes.FETCH_QUICKTIP_DATA:
            return { ...state, loading: true, error: null, quicktipData: [] };
        case QuicktipActionTypes.FETCH_QUICKTIP_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quicktipData: action.payload };
        case QuicktipActionTypes.FETCH_QUICKTIP_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quicktipData: [] };
        default:
            return state;
    }
};

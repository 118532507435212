import {Dispatch} from "redux";
import {dailyGGRCurrentMonthGraphAction, dailyGGRCurrentMonthGraphActionTypes} from "../../types/dashboard/dailyGGRLastCurrentMonthGraph";
import axios from "axios";

export const dailyGGRCurrentMonthGraph = (operator:string): any => {
    return async (dispatch: Dispatch<dailyGGRCurrentMonthGraphAction>) => {
        try {
            dispatch({type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_ggr_current_month?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailyGGRCurrentMonthGraphActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_GRAPH_DATA_ERROR, payload: 'Something wend wrong check dailyGGRCurrentMonthGraph action controller'});
        }
    }
}
export interface updateJackpotState {
    updateJackpotData: any;
    loadingStatus: boolean;
    errorResponce: null | string;
}


export enum updateJackpotActionTypes {
    UPDATE_JACKPOT_DATA = "UPDATE_JACKPOT_DATA",
    UPDATE_JACKPOT_DATA_SUCCESS = "UPDATE_JACKPOT_DATA_SUCCESS",
    UPDATE_JACKPOT_DATA_ERROR = "UPDATE_JACKPOT_DATA_ERROR",
    CLEAR_STATE_JACKPOT_UPDATE_DATA_ERROR = "CLEAR_STATE_JACKPOT_UPDATE_DATA_ERROR",
}

export type updateJackpotAction =
    | { type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA }
    | { type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA_ERROR; payload: string }
    | { type: updateJackpotActionTypes.CLEAR_STATE_JACKPOT_UPDATE_DATA_ERROR; payload?: any; };


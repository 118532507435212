export interface updateSmartBetState {
    updateSmartBetDataResponce: any;
    response: boolean;
    error: null | string;
}


export enum updateSmartBetActionTypes {
    FETCH_UPDATE_SMART_BET_DATA = "FETCH_UPDATE_SMART_BET_DATA",
    FETCH_UPDATE_SMART_BET_DATA_SUCCESS = "FETCH_UPDATE_SMART_BET_DATA_SUCCESS",
    FETCH_UPDATE_SMART_BET_DATA_ERROR = "FETCH_UPDATE_SMART_BET_DATA_ERROR",
    CLEAR_SMART_BET_STATE = "CLEAR_SMART_BET_STATE",
}

export type updateSmartBetAction =
    | { type: updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA }
    | { type: updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA_SUCCESS; payload: any[] }
    | { type: updateSmartBetActionTypes.FETCH_UPDATE_SMART_BET_DATA_ERROR; payload: string }
    | { type: updateSmartBetActionTypes.CLEAR_SMART_BET_STATE; payload: {} };
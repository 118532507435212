import {Dispatch} from "redux";
import {LeaguesSearchActionTypes, LeaguesSearchAction} from "../../types/composer/leaguesSearchTypes";
import axios from "axios";


export const leaguesSearchData = (name: string): any => {
    return async (dispatch: Dispatch<LeaguesSearchAction>) => {
        try {
            dispatch({type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/leagues?name=${name}`,
                withCredentials: false
            });
            dispatch({type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: LeaguesSearchActionTypes.FETCH_LEAGUE_SEARCH_DATA_ERROR, payload: 'Something wend wrong check Leagues Search action controller'});
        }
    }
}
import {dailyGGRCurrentMonthActionTypes, dailyGGRCurrentMonthState, dailyGGRCurrentMonthAction} from "../../types/dashboard/dailyGGRLastCurrentMonth";

const initialState: dailyGGRCurrentMonthState = {
    dailyGGRCurrentMonthData: {},
    loading: false,
    error: null,
};

export const dailyGGRCurrentMonthReducer = (state = initialState, action: dailyGGRCurrentMonthAction): dailyGGRCurrentMonthState => {
    switch (action.type) {
        case dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA:
            return { ...state, loading: true, error: null, dailyGGRCurrentMonthData: {} };
        case dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailyGGRCurrentMonthData: action.payload };
        case dailyGGRCurrentMonthActionTypes.FETCH_DAIYLY_GGR_CURRENT_MONTH_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailyGGRCurrentMonthData: {} };
        default:
            return state;
    }
};

import {SmartBetAction, SmartBetActionTypes, SmartBetState} from "../../../types/composer/SmartBet/smartBetTypes";

const initialState: SmartBetState = {
    smartBetData: [],
    loading: false,
    error: null,
};

export const smartBetReducer = (state = initialState, action: SmartBetAction): SmartBetState => {
    switch (action.type) {
        case SmartBetActionTypes.FETCH_SMART_BET_DATA:
            return { ...state, loading: true, error: null, smartBetData: [] };
        case SmartBetActionTypes.FETCH_SMART_BET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, smartBetData: action.payload };
        case SmartBetActionTypes.FETCH_SMART_BET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, smartBetData: [] };
        default:
            return state;
    }
};

import {Dispatch} from "redux";
import { SmartBetsRemovingAction, SmartBetsRemovingActionTypes } from "../../../types/composer/SmartBet/smartBetsRemove";
import axios from "axios";


export const getSmartBetsRemovingData = (id:string): any => {
    return async (dispatch: Dispatch<SmartBetsRemovingAction>) => {
        try {
            dispatch({type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA});
            const response = await axios({
                method: 'delete',
                url: `/api/composer/smartbets/${id}`,
                withCredentials: false
            });
            dispatch({type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_ERROR, payload: 'Something wend wrong check Smart Bet Removing action controller'});
        }
    }
}
export interface SmartbetPreviewState {
    smartbetPreviewData: any;
    loading: boolean;
    error_responce: null | string;
}


export enum SmartbetPreviewActionTypes {
    FETCH_SMARTBET_PREVIEW_DATA = "FETCH_SMARTBET_PREVIEW_DATA",
    FETCH_SMARTBET_PREVIEW_DATA_SUCCESS = "FETCH_SMARTBET_PREVIEW_DATA_SUCCESS",
    FETCH_SMARTBET_PREVIEW_DATA_ERROR = "FETCH_SMARTBET_PREVIEW_DATA_ERROR",
}

export type SmartbetPreviewAction =
    | { type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA }
    | { type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_SUCCESS; payload: any[] }
    | { type: SmartbetPreviewActionTypes.FETCH_SMARTBET_PREVIEW_DATA_ERROR; payload: string };
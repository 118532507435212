import {OperatorAction, OperatorActionTypes, OperatorState} from "../../../types/admins/operators/operatorTypes";

const initialState: OperatorState = {
    operatorData: [],
    loading: false,
    error: null,
};

export const operatorReducer = (state = initialState, action: OperatorAction): OperatorState => {
    switch (action.type) {
            case OperatorActionTypes.FETCH_OPERATOR_DATA:
            return { ...state, loading: true, error: null, operatorData: [] };
        case OperatorActionTypes.FETCH_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, error: null, operatorData: action.payload };
        case OperatorActionTypes.FETCH_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, operatorData: [] };
        default:
            return state;
    }
};

import {dailySalesLastSevenDaysAction, dailySalesLastSevenDaysActionTypes,dailySalesLastSevenDaysState} from "../../types/dashboard/dailySalesLastSevenDays";

const initialState: dailySalesLastSevenDaysState = {
    dailySalesLastSevenDaysData: {},
    loading: false,
    error: null,
};

export const dailySalesLastSevenDaysReducer = (state = initialState, action: dailySalesLastSevenDaysAction): dailySalesLastSevenDaysState => {
    switch (action.type) {
        case dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA:
            return { ...state, loading: true, error: null, dailySalesLastSevenDaysData: {} };
        case dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_LAST_SEVEN_DAYS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, dailySalesLastSevenDaysData: action.payload };
        case dailySalesLastSevenDaysActionTypes.FETCH_DAIYLY_SALES_TODAY_LAST_SEVEN_DAYS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, dailySalesLastSevenDaysData: {} };
        default:
            return state;
    }
};

import axios from "axios";
import { Dispatch } from "redux";
import { UpdateQuickbetActionTypes, UpdateQuickbetAction } from "../../../types/composer/Quickbet/updateQuickbetTypes";
export const updateQuickbetData = (id: string, updatedQuickbetData: object): any => {
    return async (dispatch: Dispatch<UpdateQuickbetAction>) => {
        try {
            dispatch({ type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA });
            const response = await axios({
                method: 'put',
                url: `/api/composer/quickbets/${id}`,
                data: updatedQuickbetData,
                withCredentials: false,
            });
            dispatch({ type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(`/n`);
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({ type: UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_ERROR, payload: errorMessage });
        }
    }
}
export const clearQuickbetUpdateDataState = () => {
    return (dispatch: Dispatch<UpdateQuickbetAction>) => {
        dispatch({
            type: UpdateQuickbetActionTypes.CLEAR_QUICKBET_STATE,
            payload: {}
        });
    };
};
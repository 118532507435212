import {UpdateQuicktipActionTypes, updateQuicktipState, updateQuicktipAction} from "../../../types/composer/Quicktip/updateQuicktipTypes";

const initialState: updateQuicktipState = {
    updateQuicktipData: {},
    response: false,
    error: null,
};

export const updateQuicktipReducer = (state = initialState, action: updateQuicktipAction): updateQuicktipState => {
    switch (action.type) {
        case UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA:
            return { ...state, response: true, error: null, updateQuicktipData: {} };
        case UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA_SUCCESS:
            return { ...state, response: false, error: null, updateQuicktipData: action.payload };
        case UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA_ERROR:
            return { ...state, response: false, error: action.payload, updateQuicktipData: {} };
        case UpdateQuicktipActionTypes.CLEAR_STATE_QUICKTIP_UPDATE_DATA_ERROR:
            return { ...state, response: false, error: null, updateQuicktipData: {} };
        default:
            return state;
    }
};

export interface EditSuperOperatorState {
    editSuperOperatorData: any;
    loading: boolean;
    error: null | string;
}


export enum EditSuperOperatorActionTypes {
    FETCH_EDIT_SUPER_OPERATOR_DATA = "FETCH_EDIT_SUPER_OPERATOR_DATA",
    FETCH_EDIT_SUPER_OPERATOR_DATA_SUCCESS = "FETCH_EDIT_SUPER_OPERATOR_DATA_SUCCESS",
    FETCH_EDIT_SUPER_OPERATOR_DATA_ERROR = "FETCH_EDIT_SUPER_OPERATOR_DATA_ERROR",
    CLEAR_STATE_SUPEROPERATOR_UPDATE_DATA = "CLEAR_STATE_SUPEROPERATOR_UPDATE_DATA",
}

export type EditSuperOperatorAction =
    | { type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA }
    | { type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_SUCCESS; payload: any[] }
    | { type: EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_ERROR; payload: string }
    | { type: EditSuperOperatorActionTypes.CLEAR_STATE_SUPEROPERATOR_UPDATE_DATA; payload: {} };


import { UpdateQuickbetActionTypes, UpdateQuickbetAction, UpdateQuickbetState } from "../../../types/composer/Quickbet/updateQuickbetTypes";

export const initialState: UpdateQuickbetState = {
    updateQuickbetDataResponse: {},
    response: false,
    error: null,
};

export const updateQuickbetReducer = (state = initialState, action: UpdateQuickbetAction): UpdateQuickbetState => {
    switch (action.type) {
        case UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA:
            return { ...state, response: true, error: null };
        case UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_SUCCESS:
            return { ...state, response: false, error: null, updateQuickbetDataResponse: action.payload };
        case UpdateQuickbetActionTypes.FETCH_QUICKBET_JACKPOT_DATA_ERROR:
            return { ...state, response: false, error: action.payload, updateQuickbetDataResponse: {} };
        case UpdateQuickbetActionTypes.CLEAR_QUICKBET_STATE:
            return { ...state, response: false, error: null, updateQuickbetDataResponse: {} };
        default:
            return state;
    }
}

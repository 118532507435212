import {EditSuperOperatorState, EditSuperOperatorActionTypes, EditSuperOperatorAction} from "../../../types/admins/operators/editSuperOperatorTypes";


const initialState: EditSuperOperatorState = {
    editSuperOperatorData: [],
    loading: false,
    error: null,
};

export const editeSuperOperatorReducer = (state = initialState, action: EditSuperOperatorAction): EditSuperOperatorState => {
    switch (action.type) {
            case EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA:
            return { ...state, loading: true, error: null, editSuperOperatorData: [] };
        case EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, error: null, editSuperOperatorData: action.payload };
        case EditSuperOperatorActionTypes.FETCH_EDIT_SUPER_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, editSuperOperatorData: [] };
        case EditSuperOperatorActionTypes.CLEAR_STATE_SUPEROPERATOR_UPDATE_DATA:
            return { ...state, loading: false, error: null, editSuperOperatorData: {} };
        default:
            return state;
    }
};

import {QuicktipCreateActionTypes} from "./quicktipCreate";

export interface updateQuicktipState {
    updateQuicktipData: any;
    response: boolean;
    error: null | string;
}


export enum UpdateQuicktipActionTypes {
    FETCH_QUICKTIP_JACKPOT_DATA = "FETCH_QUICKTIP_JACKPOT_DATA",
    FETCH_QUICKTIP_JACKPOT_DATA_SUCCESS = "FETCH_QUICKTIP_JACKPOT_DATA_SUCCESS",
    FETCH_QUICKTIP_JACKPOT_DATA_ERROR = "FETCH_QUICKTIP_JACKPOT_DATA_ERROR",
    CLEAR_STATE_QUICKTIP_UPDATE_DATA_ERROR = "CLEAR_STATE_QUICKTIP_UPDATE_DATA_ERROR",
}

export type updateQuicktipAction =
    | { type: UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA }
    | { type: UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: UpdateQuicktipActionTypes.FETCH_QUICKTIP_JACKPOT_DATA_ERROR; payload: string }
    | { type: UpdateQuicktipActionTypes.CLEAR_STATE_QUICKTIP_UPDATE_DATA_ERROR; payload?: any; };
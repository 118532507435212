export interface dailySalesTodayState {
    dailySalesTodayData: any;
    loading: boolean;
    error: null | string;
}


export enum dailySalesTodayActionTypes {
    FETCH_DAIYLY_SALES_TODAY_DATA = "FETCH_DAIYLY_SALES_TODAY_DATA",
    FETCH_DAIYLY_SALES_TODAY_DATA_SUCCESS = "FETCH_DAIYLY_SALES_TODAY_DATA_SUCCESS",
    FETCH_DAIYLY_SALES_TODAY_DATA_ERROR = "FETCH_DAIYLY_SALES_TODAY_DATA_ERROR",
}

export type dailySalesTodayAction =
    | { type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA }
    | { type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_SUCCESS; payload: any[] }
    | { type: dailySalesTodayActionTypes.FETCH_DAIYLY_SALES_TODAY_DATA_ERROR; payload: string };


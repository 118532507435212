import {Dispatch} from "redux";
import {QuicktipRemovingActionTypes, QuicktipRemovingAction} from "../../../types/composer/Quicktip/quicktipRemoveTypes";
import axios from "axios";


export const quicktipRemovingData = (id:string): any => {
    return async (dispatch: Dispatch<QuicktipRemovingAction>) => {
        try {
            dispatch({type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA});
            const response = await axios({
                method: 'delete',
                url: `/api/composer/quicktips/${id}`,
                withCredentials: false
            });
            dispatch({type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_ERROR, payload: 'Something wend wrong check Quicktips Removing action controller'});
        }
    }
}
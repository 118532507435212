export interface monthTableState {
    monthTableData: any;
    loading: boolean;
    error: null | string;
}


export enum monthTableActionTypes {
    FETCH_MONTH_TABLE_DATA = "FETCH_MONTH_TABLE_DATA",
    FETCH_MONTH_TABLE_DATA_SUCCESS = "FETCH_MONTH_TABLE_DATA_SUCCESS",
    FETCH_MONTH_TABLE_DATA_ERROR = "FETCH_MONTH_TABLE_DATA_ERROR",
}

export type monthTableAction =
    | { type: monthTableActionTypes.FETCH_MONTH_TABLE_DATA }
    | { type: monthTableActionTypes.FETCH_MONTH_TABLE_DATA_SUCCESS; payload: any[] }
    | { type: monthTableActionTypes.FETCH_MONTH_TABLE_DATA_ERROR; payload: string };


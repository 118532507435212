import {Dispatch} from "redux";
import axios from "axios";
import {ResultActionTypes, ResultAction} from "../../types/sportsdata/resultTypes";

export const getResultData = ( id: string ): any => {
    return async (dispatch: Dispatch<ResultAction>) => {
        try {
            dispatch({type: ResultActionTypes.FETCH_RESULT_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/results/${id}`,
                withCredentials: true
            });
            dispatch({type: ResultActionTypes.FETCH_RESULT_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: ResultActionTypes.FETCH_RESULT_DATA_ERROR, payload: 'Something wend wrong check RESULT action controller'});
        }
    }
}
import {Dispatch} from "redux";
import {CountriesAction, CountriesActionTypes} from "../../types/sportsdata/countriesTypes";
import axios from "axios";


export const getCountriesData = (): any => {
    return async (dispatch: Dispatch<CountriesAction>) => {
        try {
            dispatch({type: CountriesActionTypes.FETCH_COUNTRIES_DATA});
            const response = await axios({
                method: 'get',
                url: '/api/sportsdata/countries',
                withCredentials: false
            });
            dispatch({type: CountriesActionTypes.FETCH_COUNTRIES_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: CountriesActionTypes.FETCH_COUNTRIES_DATA_ERROR, payload: 'Something wend wrong check COUNTRIES action controller'});
        }
    }
}
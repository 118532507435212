import {ProfileAction, ProfileActionTypes, ProfileState} from "../../../types/admins/profile/profileTypes";

const initialState: ProfileState = {
    profileData: {},
    loading: false,
    error: null,
};

export const profileReducer = (state = initialState, action: ProfileAction): ProfileState => {
    switch (action.type) {
            case ProfileActionTypes.FETCH_PROFILE_DATA:
            return { ...state, loading: true, error: null, profileData: {} };
        case ProfileActionTypes.FETCH_PROFILE_DATA_SUCCESS:
            return { ...state, loading: false, error: null, profileData: action.payload };
        case ProfileActionTypes.FETCH_PROFILE_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, profileData: {} };
        default:
            return state;
    }
};

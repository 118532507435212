import {SmartBetsCreateNewAction, SmartBetsCreateNewActionTypes, SmartBetsCreateNewState} from "../../../types/composer/SmartBet/smartBetsCreateNew";

const initialState: SmartBetsCreateNewState = {
    smartBetsCreateNewData: {},
    response: false,
    error_responce: null,
};

export const smartBetsCreateNewReducer = (state = initialState, action: SmartBetsCreateNewAction): SmartBetsCreateNewState => {
    switch (action.type) {
        case SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA:
            return { ...state, response: true, error_responce: null, smartBetsCreateNewData: {} };
        case SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_SUCCESS:
            return { ...state, response: false, error_responce: null, smartBetsCreateNewData: action.payload };
        case SmartBetsCreateNewActionTypes.FETCH_SMART_BETS_CRATE_NEW_DATA_ERROR:
            return { ...state, response: false, error_responce: action.payload, smartBetsCreateNewData: {} };
        case SmartBetsCreateNewActionTypes.CLEAR_SMART_BETS_CRATE_STATE:
            return { ...state, response: false, error_responce: null, smartBetsCreateNewData: {} };
        default:
            return state;
    }
};

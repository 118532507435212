import axios from "axios";
import {Dispatch} from "redux";
import {updateJackpotActionTypes, updateJackpotAction} from "../../../types/composer/jackpot/updateJackpotTypes";

export const updateJackpot = (id:string, updatedJackpotData: object): any => {
    return async (dispatch: Dispatch<updateJackpotAction>) => {
        try {
            dispatch({type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA});
            const response = await axios({
                method: 'put',
                url: `/api/composer/jackpots/${id}`,
                data: updatedJackpotData,
                withCredentials: false,
            });
            dispatch({type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(' ');
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({
                type: updateJackpotActionTypes.UPDATE_JACKPOT_DATA_ERROR,
                payload: errorMessage
            });
        }
    }
}

export const clearStateJackpotUpdateData = () => {

    return (dispatch: Dispatch<updateJackpotAction>) => {
        dispatch({
            type: updateJackpotActionTypes.CLEAR_STATE_JACKPOT_UPDATE_DATA_ERROR,
            payload: {}
        });
    };
};
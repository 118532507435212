import {combineReducers} from "redux";
import {liveLogsReducer} from "./composer/liveLogsReducer";
import {errorLogsReducer} from "./composer/errorLogsReducer";
import {smartBetsReducer} from "./composer/SmartBet/smartBetsReducer";
import {smartBetCreateReducer} from "./composer/SmartBet/smartBetCreateReducer";
import {smartBetsCreateNewReducer} from "./composer/SmartBet/smartBetCreateNewReducer";
import {smartBetsRemovingReducer} from "./composer/SmartBet/smartBetRemovingReducer";
import {smartBetReducer} from "./composer/SmartBet/smartBetReducer";
import {quicktipReducer} from "./composer/Quicktip/quicktipReducer";
import {quicktipsReducer} from "./composer/Quicktip/quicktipsReducer";
import {quicktipCreateReducer} from "./composer/Quicktip/quicktipCreateReducer";
import {quicktipRemovingReducer} from "./composer/Quicktip/quicktipRemovingReducer";
import {updateQuicktipReducer} from "./composer/Quicktip/updateQuicktipReducer";
import {quickbetReducer} from "./composer/Quickbet/quickbetReducer";
import {quickbetsReducer} from "./composer/Quickbet/quickbetsReducer";
import {quickbetCreateReducer} from "./composer/Quickbet/quickbetCreateReducer";
import {quickbetRemovingReducer} from "./composer/Quickbet/quickbetRemovingReducer";
import {updateQuickbetReducer} from "./composer/Quickbet/updateQuickbetReducer";

import {updateBetPublisherReducer} from "./betPublisher/updateBetPublisherReducer";
import {getBetPublisherReducer} from "./betPublisher/getBetPublisherReducer";
import {uploadLinkLogoReducer} from "./betPublisher/uploadLinkLogoReducer";

import {jackpotCreateReducer} from "./composer/Jackpot/jackpotCreateReducer";
import {jackpotRemovingReducer} from "./composer/Jackpot/jackpotRemovingReducer";
import {jackpotReducer} from "./composer/Jackpot/jackpotReducer";
import {jackpotsReducer} from "./composer/Jackpot/jackpotsReducer";
import {updateJackpotReducer} from "./composer/Jackpot/updateJackpotReducer";

import {quicktipPreviewReducer} from "./composer/Quicktip/quicktipPreviewReducer";
import {smartBetPreviewReducer} from "./composer/SmartBet/smartBetPreviewReducer";
import {updateSmartBetsReducer} from "./composer/SmartBet/updatedSmartBetReducer";
import {marketGroupsReducer} from "./composer/marketGroupsReducer";
import {marketDetailsReducer} from "./composer/marketDetailsReducer";
import {leaguesSearchReducer} from "./composer/leaguesSearchReducer";
import {countriesReducer} from "./sportsdata/countriesReducer";
import {countryReducer} from "./sportsdata/countryReducer";
import {updateCountryReducer} from "./sportsdata/updateCountryReducer";
import {countryLeaguesReducer} from "./sportsdata/countryLeaguesReducer";
import {updateLeagueReducer} from "./sportsdata/updateLeagueReducer";
import {teamsReducer} from "./sportsdata/teamsReducer";
import {leagueReducer} from "./sportsdata/leagueReducer";
import {resultsReducer} from "./sportsdata/resultsReducer";
import {resultReducer} from "./sportsdata/resultReducer";
import {resultRemoveGoalReducer} from "./sportsdata/resultRemoveGoalReducer";
import {confirmResultsFullTimeReducer} from "./sportsdata/confirmResultsFullTimeReducer";
import {confirmResultsHalfTimeReducer} from "./sportsdata/confirmResultsHalfTimeReducer";
import {addGoalReducer} from "./sportsdata/addGoalReducer";
import {getEventBetReducer} from "./sportsdata/getEventBetReducer";
import {betengineTicketsReducer} from "./betengine/betEngineTicketsReducer";
import {betengineTicketReducer} from "./betengine/betengineTicketReducer";
import {operatorsReducer} from "./admins/operators/operatorsReducer";
import {operatorReducer} from "./admins/operators/operatorReducer";
import {superOperatorReducer} from "./admins/operators/superOperatorReducer";
import {adminNotificationReducer} from "./admins/operators/admin/adminNotificationReducer";
import {editeSuperOperatorReducer} from "./admins/operators/editSuperOperatorReducer";
import {editAdminOperatorReducer} from "./admins/operators/admin/editAdminOperatorReducer";
import {adminOperatorReducer} from "./admins/operators/admin/adminOperatorReducer";
import {operatorCreateReducer} from "./admins/operators/operatorCreateReducer";
import {editeProfileReducer} from "./admins/profile/editProfileReducer";
import {profileReducer} from "./admins/profile/profileReducer";
import {timezonesReducer} from "./admins/timezonesReducer";
import {adminApiReducer} from "./admins/operators/admin/adminApiReducer";
import {editApiOperatorReducer} from "./admins/operators/admin/editApiOperatorReducer";
import {dailySalesTodayReducer} from "./dashboard/dailySalesTodayReducer";
import {dailySalesLastSevenDaysReducer} from "./dashboard/dailySalesLastSevenDaysReducer";
import {dailySalesCurrentMonthReducer} from "./dashboard/dailySalesCurrentMonthReducer";
import {dailyTicketsLastSevenDaysReducer} from "./dashboard/dailyTicketsLastSevenDaysReducer";
import {dailyGGRCurrentMonthReducer} from "./dashboard/dailyGGRCurrentMonthReducer";
import {monthTableReducer} from "./dashboard/monthTableReducer";
import {dailyGGRLastSevenDaysReducer} from "./dashboard/dailyGGRLastSevenDaysReducer";
import {dailyGGRCurrentMonthGraphReducer} from "./dashboard/dailyGGRCurrentMonthGraphReducer";
import {superbetsReducer} from "./composer/Superbet/superbetsReducer";
import {superbetReducer} from "./composer/Superbet/superbetReducer";
import {removeSuperbetReducer} from "./composer/Superbet/removeSuperbetReducer";
import {updateSuperbetReducer} from "./composer/Superbet/updateSuperbetReducer";
import {updateNotificationReducer} from "./admins/operators/admin/editNotificationReducer";

export const rootReducer = combineReducers({
    jackpotCreateReducer:jackpotCreateReducer,
    jackpotRemovingReducer: jackpotRemovingReducer,
    jackpotReducer: jackpotReducer,
    jackpotsReducer: jackpotsReducer,
    updateJackpotReducer:updateJackpotReducer,
    liveLogsReducer: liveLogsReducer,
    errorLogsReducer:errorLogsReducer,
    smartBetsReducer:smartBetsReducer,
    smartBetCreateReducer:smartBetCreateReducer,
    smartBetsCreateNewReducer:smartBetsCreateNewReducer,
    smartBetsRemovingReducer:smartBetsRemovingReducer,
    smartBetPreviewReducer:smartBetPreviewReducer,
    smartBetReducer:smartBetReducer,
    quicktipReducer:quicktipReducer,
    quicktipsReducer:quicktipsReducer,
    quicktipCreateReducer:quicktipCreateReducer,
    quicktipRemovingReducer:quicktipRemovingReducer,
    quicktipPreviewReducer:quicktipPreviewReducer,
    quickbetReducer:quickbetReducer,
    quickbetsReducer:quickbetsReducer,
    quickbetCreateReducer:quickbetCreateReducer,
    updateQuickbetReducer: updateQuickbetReducer,
    quickbetRemovingReducer: quickbetRemovingReducer,
    superbetsReducer:superbetsReducer,
    superbetReducer:superbetReducer,
    removeSuperbetReducer: removeSuperbetReducer,
    updateSuperbetReducer: updateSuperbetReducer,
    updateQuicktipReducer:updateQuicktipReducer,
    updateSmartBetsReducer:updateSmartBetsReducer,
    marketGroupsReducer:marketGroupsReducer,
    marketDetailsReducer:marketDetailsReducer,
    leaguesSearchReducer:leaguesSearchReducer,
    countriesReducer: countriesReducer,
    countryReducer:countryReducer,
    updateCountryReducer:updateCountryReducer,
    countryLeaguesReducer: countryLeaguesReducer,
    updateLeagueReducer: updateLeagueReducer,
    teamsReducer: teamsReducer,
    leagueReducer:leagueReducer,
    resultsReducer: resultsReducer,
    resultReducer: resultReducer,
    resultRemoveGoalReducer:resultRemoveGoalReducer,
    confirmResultsFullTimeReducer: confirmResultsFullTimeReducer,
    confirmResultsHalfTimeReducer: confirmResultsHalfTimeReducer,
    addGoalReducer:addGoalReducer,
    getEventBetReducer:getEventBetReducer,
    betengineTicketsReducer:betengineTicketsReducer,
    betengineTicketReducer:betengineTicketReducer,
    operatorsReducer:operatorsReducer,
    operatorReducer:operatorReducer,
    adminNotificationReducer:adminNotificationReducer,
    superOperatorReducer:superOperatorReducer,
    editeSuperOperatorReducer:editeSuperOperatorReducer,
    editAdminOperatorReducer:editAdminOperatorReducer,
    adminOperatorReducer:adminOperatorReducer,
    operatorCreateReducer:operatorCreateReducer,
    editeProfileReducer:editeProfileReducer,
    profileReducer:profileReducer,
    timezonesReducer:timezonesReducer,
    adminApiReducer:adminApiReducer,
    editApiOperatorReducer:editApiOperatorReducer,
    dailySalesTodayReducer:dailySalesTodayReducer,
    dailySalesLastSevenDaysReducer:dailySalesLastSevenDaysReducer,
    dailySalesCurrentMonthReducer:dailySalesCurrentMonthReducer,
    dailyTicketsLastSevenDaysReducer:dailyTicketsLastSevenDaysReducer,
    dailyGGRLastSevenDaysReducer:dailyGGRLastSevenDaysReducer,
    monthTableReducer:monthTableReducer,
    dailyGGRCurrentMonthReducer:dailyGGRCurrentMonthReducer,
    dailyGGRCurrentMonthGraphReducer:dailyGGRCurrentMonthGraphReducer,
    updateNotificationReducer:updateNotificationReducer,
    updateBetPublisherReducer:updateBetPublisherReducer,
    getBetPublisherReducer:getBetPublisherReducer,
    uploadLinkLogoReducer:uploadLinkLogoReducer
});

export type RootState = ReturnType<typeof rootReducer>
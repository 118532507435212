/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, getOperatorData} from '../core/_requests'
import {useIntl} from 'react-intl';
import {useAuth} from '../core/Auth'

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const intl = useIntl();
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email(`${intl.formatMessage({id: 'notification.email.wrongformat'})}`)
            .min(3,`${intl.formatMessage({id: 'notification.email.minsymbols'})}`)
            .max(50, `${intl.formatMessage({id: 'notification.email.maxsymbols'})}`)
            .required(`${intl.formatMessage({id: 'notification.email.required'})}`),
        password: Yup.string()
            .min(3, `${intl.formatMessage({id: 'notification.password.minsymbols'})}`)
            .max(50, `${intl.formatMessage({id: 'notification.password.maxsymbols'})}`)
            .required(`${intl.formatMessage({id: 'notification.password.required'})}`),
    })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        try {
          const result = await login(values.email, values.password)
            saveAuth(result)
            setCurrentUser(result)
        } catch (error) {
          console.error(error);
          saveAuth(undefined)
          setStatus(`${intl.formatMessage({id: 'notification.credentials.incorrect'})}`)
          setSubmitting(false)
          setLoading(false)
        }
    },
  })

    return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
        <div className="card-body">
            <div className="text-start mb-10">
                <h1 className="text-gray-900 mb-3 fs-3x" data-kt-translate="sign-in-title">
                    {intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.TITLE'})}
                </h1>
                <div className="text-gray-500 fw-semibold fs-6" data-kt-translate="general-desc">
                    {intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.HINT'})}
                </div>
            </div>
            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : null}
            <div className='fv-row mb-8 fv-plugins-icon-container'>
                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.EMAIL.FIELDTITLE'})}</label>
                <input
                    placeholder={intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.EMAIL.FIELDTITLE'})}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    ) + ' form-control form-control-solid'}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.PASSWORD.FIELDTITLE'})}</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* begin::Link */}
                <Link to='/auth/forgot-password' className='link-primary'>
                    {intl.formatMessage({id: 'AUTH.LOGIN.SIGHNIN.FORGOTPASS'})}
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'BTN.CONTINUE'})}</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'notification.loading'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </div>

    </form>
  )
}

import {Dispatch} from "redux";
import {QuicktipsActionTypes, QuicktipsAction} from "../../../types/composer/Quicktip/quicktipsTypes";
import axios from "axios";


export const getQuicktipsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/quicktips?operator=${operator}` : `/api/composer/quicktips`;
    return async (dispatch: Dispatch<QuicktipsAction>) => {
        try {
            dispatch({type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_ERROR, payload: 'Something wend wrong check Quicktips action controller'});
        }
    }
}
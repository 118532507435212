import {Dispatch} from "redux";
import {SmartBetsCreateActionTypes, SmartBetsCreateAction} from "../../../types/composer/SmartBet/smartBetsCreate";
import axios from "axios";
export const getSmartBetsCreateData = (): any => {
    return async (dispatch: Dispatch<SmartBetsCreateAction>) => {
        try {
            dispatch({type: SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA});
            const response = await axios({
                method: 'get',
                url: '/api/composer/smartbets/new',
                withCredentials: false
            });
            dispatch({type: SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(' ');
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({
                type: SmartBetsCreateActionTypes.FETCH_SMART_BETS_CRATE_DATA_ERROR,
                payload: errorMessage
            });
        }
    }
}

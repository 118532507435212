export interface confirmResultsFullTimeState {
    confirmResultsFullTimeData: any[];
    loading: boolean;
    error: null | string;
}


export enum confirmResultsFullTimeActionTypes {
    FETCH_CONFIRM_RESULTS_FULL_TIME_DATA = "FETCH_CONFIRM_RESULTS_FULL_TIME_DATA",
    FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_SUCCESS = "FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_SUCCESS",
    FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_ERROR = "FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_ERROR",
}

export type confirmResultsFullTimeAction =
    | { type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA }
    | { type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_SUCCESS; payload: any[] }
    | { type: confirmResultsFullTimeActionTypes.FETCH_CONFIRM_RESULTS_FULL_TIME_DATA_ERROR; payload: string };
import {SmartBetsRemovingActionTypes, SmartBetsRemovingAction, SmartBetsRemovingState} from "../../../types/composer/SmartBet/smartBetsRemove";

const initialState: SmartBetsRemovingState = {
    smartBetsRemovingData: [],
    loading: false,
    error: null,
};

export const smartBetsRemovingReducer = (state = initialState, action: SmartBetsRemovingAction): SmartBetsRemovingState => {
    switch (action.type) {
        case SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA:
            return { ...state, loading: true, error: null, smartBetsRemovingData: [] };
        case SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_SUCCESS:
            return { ...state, loading: false, error: null, smartBetsRemovingData: action.payload };
        case SmartBetsRemovingActionTypes.FETCH_SMART_BETS_REMOVING_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, smartBetsRemovingData: [] };
        default:
            return state;
    }
};

import {monthTableAction, monthTableState, monthTableActionTypes} from "../../types/dashboard/monthTable";

const initialState: monthTableState = {
    monthTableData: {},
    loading: false,
    error: null,
};

export const monthTableReducer = (state = initialState, action: monthTableAction): monthTableState => {
    switch (action.type) {
        case monthTableActionTypes.FETCH_MONTH_TABLE_DATA:
            return { ...state, loading: true, error: null, monthTableData: {} };
        case monthTableActionTypes.FETCH_MONTH_TABLE_DATA_SUCCESS:
            return { ...state, loading: false, error: null, monthTableData: action.payload };
        case monthTableActionTypes.FETCH_MONTH_TABLE_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, monthTableData: {} };
        default:
            return state;
    }
};

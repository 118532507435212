export interface confirmResultsHalfTimeState {
    confirmResultsHalfTimeData: any[];
    loading: boolean;
    error: null | string;
}


export enum confirmResultsHalfTimeActionTypes {
    FETCH_CONFIRM_RESULTS_HALF_TIME_DATA = "FETCH_CONFIRM_RESULTS_HALF_TIME_DATA",
    FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_SUCCESS = "FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_SUCCESS",
    FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_ERROR = "FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_ERROR",
}

export type confirmResultsHalfTimeAction =
    | { type: confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA }
    | { type: confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_SUCCESS; payload: any[] }
    | { type: confirmResultsHalfTimeActionTypes.FETCH_CONFIRM_RESULTS_HALF_TIME_DATA_ERROR; payload: string };
export interface SuperOperatorState {
    superOperatorData: any;
    loading: boolean;
    error: null | string;
}


export enum SuperOperatorActionTypes {
    FETCH_SUPER_OPERATOR_DATA = "FETCH_SUPER_OPERATOR_DATA",
    FETCH_SUPER_OPERATOR_DATA_SUCCESS = "FETCH_SUPER_OPERATOR_DATA_SUCCESS",
    FETCH_SUPER_OPERATOR_DATA_ERROR = "FETCH_SUPER_OPERATOR_DATA_ERROR",
}

export type SuperOperatorAction =
    | { type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA }
    | { type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_SUCCESS; payload: any[] }
    | { type: SuperOperatorActionTypes.FETCH_SUPER_OPERATOR_DATA_ERROR; payload: string };


import {Dispatch} from "redux";
import {QuickbetRemovingAction, QuickbetRemovingActionTypes} from "../../../types/composer/Quickbet/quickbetRemoveTypes";
import axios from "axios";


export const quickbetRemovingData = (id:string): any => {
    return async (dispatch: Dispatch<QuickbetRemovingAction>) => {
        try {
            dispatch({type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA});
            const response = await axios({
                method: 'delete',
                url: `/api/composer/quickbets/${id}`,
                withCredentials: false
            });
            dispatch({type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: QuickbetRemovingActionTypes.FETCH_QUICKBET_REMOVING_DATA_ERROR, payload: 'Something wend wrong check Quickbets Removing action controller'});
        }
    }
}
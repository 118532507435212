import React from "react";

export const updateDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[dateObj.getMonth()];

    return {
        formattedDate: `${hours}:${minutes}:${seconds}  ${day}-${monthName}`,
        date: `${year}-${month}-${day}`,
        time: `${hours}:${minutes}`
    };
};

export const getCountries = (obj: any) => {
    for (let key in obj) {
        if(key === 'countries'){
            if (obj[key].length > 0) {
                return obj[key]
            } else {
                return false
            }
        }
    }
}

export const getEventData = (home: any, away: any) => {

    return <span>{home} - {away}</span>
}

const period = {
    first_half: 1,
    half_time: 2,
    second_half: 3,
    break_before_extra: 4,
    first_half_extra_time: 5,
    break_time: 6,
    second_half_extra_time: 7,
    penalties_shootout: 8
};

export const selectPeriod = (side: string, handleInputChange: any) => {
    const options = [];

    for (let minute = 1; minute <= 120; minute++) {
        const periodValue = getPeriodValue(minute);
        options.push(
            <option key={minute} value={minute} data-period={periodValue}>
                {minute}
            </option>
        );

        if (minute === 45) {
            for (let i = 1; i <= 10; i++) {
                let extraMinute = "45" + ` + ${i}`;
                let extraPeriodValue = periodValue;
                options.push(
                    <option
                        key={minute + i  + 'extra'}
                        value={extraMinute}
                        data-period={extraPeriodValue}
                    >
                        {extraMinute}
                    </option>
                );
            }
        } else if (minute === 90) {
            for (let i = 1; i <= 10; i++) {
                let extraMinute = "90" + ` + ${i}`;
                let extraPeriodValue = periodValue;
                options.push(
                    <option
                        key={minute + i  + 'extra'}
                        value={extraMinute}
                        data-period={extraPeriodValue}
                    >
                        {extraMinute}
                    </option>
                );
            }
        }
        else if (minute === 105) {
            for (let i = 1; i <= 5; i++) {
                let extraMinute = "105" + ` + ${i}`;
                let extraPeriodValue = periodValue;
                options.push(
                    <option
                        key={minute + i + 'extra'}
                        value={extraMinute}
                        data-period={extraPeriodValue}
                    >
                        {extraMinute}
                    </option>
                );
            }
        }
        else if (minute === 120) {
            for (let i = 1; i <= 5; i++) {
                let extraMinute = "120" + ` + ${i}`;
                let extraPeriodValue = periodValue;
                options.push(
                    <option
                        key={minute + i + 'extra'}
                        value={extraMinute}
                        data-period={extraPeriodValue}
                    >
                        {extraMinute}
                    </option>
                );
            }
        }
    }

    function getPeriodValue(minute: number) {
        if (minute <= 45) {
            return period.first_half;
        } else if (minute <= 90) {
            return period.second_half;
        } else if (minute <= 105) {
            return period.first_half_extra_time;
        } else if (minute <= 120) {
            return period.second_half_extra_time;
        } else {
            return 0;
        }
    }

    const handleChange = (event:any) => {
        const selectedOption = event.target.selectedOptions[0];
        const dataPeriod = selectedOption.getAttribute('data-period');

        handleInputChange(event, dataPeriod, side);
    };

    return (
        <select
            id={side}
            className='form-control select2 select2-hidden-accessible'
            name='time'
            onChange={handleChange}
        >
            {options}
        </select>
    );
};
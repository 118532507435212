import {Dispatch} from "redux";
import {ProfileActionTypes, ProfileAction} from "../../../types/admins/profile/profileTypes";
import axios from "axios";


export const getProfileData = (): any => {
    return async (dispatch: Dispatch<ProfileAction>) => {
        try {
            dispatch({type: ProfileActionTypes.FETCH_PROFILE_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/users/me`,
                withCredentials: false
            });
            dispatch({type: ProfileActionTypes.FETCH_PROFILE_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: ProfileActionTypes.FETCH_PROFILE_DATA_ERROR, payload: 'Something wend wrong check Profile action controller'});
        }
    }
}
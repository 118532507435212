import clsx from 'clsx'
import {ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core';
import {HeaderUserMenu} from "../../../partials/layout/header-menus/HeaderUserMenu";
import React from "react";
import './Navbar.css'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout();
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
        <div className={clsx('app-navbar-item', itemClass)}>
            <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
            >
                <i className="ki-duotone ki-user-square">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                </i>
            </div>
            <HeaderUserMenu />
        </div>
    </div>
  )
}

export {Navbar}

import {Dispatch} from "redux";
import axios from "axios";
import {LeagueAction, LeagueActionTypes} from "../../types/sportsdata/leagueTypes";

export const getLeagueData = (id:string): any => {
    return async (dispatch: Dispatch<LeagueAction>) => {
        try {
            dispatch({type: LeagueActionTypes.FETCH_LEAGUE_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/sportsdata/leagues/${id}`,
                withCredentials: false
            });
            dispatch({type: LeagueActionTypes.FETCH_LEAGUE_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: LeagueActionTypes.FETCH_LEAGUE_DATA_ERROR, payload: 'Something wend wrong check LEAGUE action controller'});
        }
    }
}
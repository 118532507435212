import React, {FC, useEffect, useState} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {MonthTable} from "../../componenets/MonthTable";
interface TI {
    currency: string,
    operator: string
}

const MonthTableContainer: FC<TI> = ({currency, operator}) => {
    const {getMonthTableData} = useAction();
    const {monthTableData} = useTypedSelector((state) => state.monthTableReducer);
    useEffect(()=> {
        if (operator) {
            getMonthTableData(operator);
        }
    }, [operator])
    return(
        <>
            {
                monthTableData.length > 0 ?
                    <MonthTable data={monthTableData} currencyVal={currency} />
                    :null
            }
        </>
    )
}

export default MonthTableContainer;
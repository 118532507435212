import {createRoot} from 'react-dom/client'

// import Redux Provider and Redux store
import {Provider} from "react-redux";
import {store} from "./store";

// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import {AuthProvider, setupAxios} from './app/modules/auth'

// Новий імпорт для react-router-dom
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";

// Імпортуйте ваші компоненти
import {AppRoutes} from './app/routing/AppRoutes'

// Налаштування axios
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

// Визначте ваші маршрути
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/*" element={<AppRoutes />} />
    )
)

if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <MetronicI18nProvider>
                    <AuthProvider>
                        <RouterProvider router={router} />
                    </AuthProvider>
                </MetronicI18nProvider>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

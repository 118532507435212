export interface ResultsState {
    resultsData: any[];
    loading: boolean;
    error: null | string;
}


export enum ResultsActionTypes {
    FETCH_RESULTS_DATA = "FETCH_RESULTS_DATA",
    FETCH_RESULTS_DATA_SUCCESS = "FETCH_RESULTS_DATA_SUCCESS",
    FETCH_RESULTS_DATA_ERROR = "FETCH_RESULTS_DATA_ERROR",
}

export type ResultsAction =
    | { type: ResultsActionTypes.FETCH_RESULTS_DATA }
    | { type: ResultsActionTypes.FETCH_RESULTS_DATA_SUCCESS; payload: any[] }
    | { type: ResultsActionTypes.FETCH_RESULTS_DATA_ERROR; payload: string };
import {Dispatch} from "redux";
import axios from "axios";

import {TeamsActionTypes, TeamsAction} from "../../types/sportsdata/teamsTypes";

export const getTeamsData = (): any => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        try {
            dispatch({type: TeamsActionTypes.FETCH_TEAMS_DATA});
            const response = await axios({
                method: 'get',
                url: '/api/sportsdata/teams',
                withCredentials: false
            });
            dispatch({type: TeamsActionTypes.FETCH_TEAMS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: TeamsActionTypes.FETCH_TEAMS_DATA_ERROR, payload: 'Something wend wrong check TEAMS action controller'});
        }
    }
}
export interface JackpotsState {
    jackpotsData: any[];
    loading: boolean;
    error: null | string;
}


export enum JackpotsActionTypes {
    FETCH_JACKPOS_DATA = "FETCH_JACKPOS_DATA",
    FETCH_JACKPOS_DATA_SUCCESS = "FETCH_JACKPOS_DATA_SUCCESS",
    FETCH_JACKPOS_DATA_ERROR = "FETCH_JACKPOS_DATA_ERROR",
}

export type JackpotsAction =
    | { type: JackpotsActionTypes.FETCH_JACKPOS_DATA }
    | { type: JackpotsActionTypes.FETCH_JACKPOS_DATA_SUCCESS; payload: any[] }
    | { type: JackpotsActionTypes.FETCH_JACKPOS_DATA_ERROR; payload: string };


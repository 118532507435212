import {Dispatch} from "redux";
import {ErrorLogsActionTypes, ErrorLogsAction} from "../../types/composer/errorLogsTypes";
import axios from "axios";


export const getErrorLogsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/betcard_errors?operator=${operator}` : `/api/composer/betcard_errors`;
    return async (dispatch: Dispatch<ErrorLogsAction>) => {
        try {
            dispatch({type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_ERROR, payload: 'Something wend wrong check Error Log action controller'});
        }
    }
}
import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `/api/auth/login`
export const REGISTER_URL = `/api/auth/sign-up`
export const REQUEST_PASSWORD_URL = '/api/auth/forgot-password'
let test = 'user@example.com';
// Server should return AuthModel

/*export function login(username: string, password: string) {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    return axios.post<AuthModel>(LOGIN_URL, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}*/


// Server should return AuthModel
/*export function register(
  email: string,
  password: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    password,
  })
}*/

export async function register(email: string, password: string) {
    try {
        const response = await axios.post(REGISTER_URL, {
            email,
            password,
        });

        // Check if the response status is 200 or 201
        if (response.status === 200 || response.status === 201) {
            // Return the response data
            return response.data;
        } else {
            // Handle other response statuses if needed
            // For now, just return the response data
            return response.data;
        }
    } catch (error) {
        // Handle errors
        console.error(error);
        throw error;
    }
}
export async function resetPassword(password: string, token: string) {
    try {
        const response = await axios.post('/api/auth/reset-password', {
            password: password,
            token: token
        });
        if (response.status === 200 || response.status === 201) {
            // Return the response data
            return true;
        } else {
            return false;
        }
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.detail) {
            const errorMessage = error.response.data.detail;
            console.error(errorMessage);
            return {status: false, message: errorMessage};
        } else {
            throw error;
        }
    }
}

export async function forgotPassword(userEmail: string) {
    try {
        const response = await axios.post('/api/auth/forgot-password', {
            email: userEmail,
        });

        // Check if the response status is 200 or 201
        if (response.status === 200 || response.status === 202) {
            // Return the response data
            return {data: true};
        } else {
            // Handle other response statuses if needed
            // For now, just return the response data
            return {data: false};
        }
    } catch (error) {
        // Handle errors
        console.error(error);
        throw error;
    }
}



// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(userEmail: string) {
    return axios.post<{ result: boolean }>('/api/auth/forgot-password', {
        email: userEmail,
    });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
export async function login(username: string, password: string) {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    const response = await axios.post<AuthModel>(LOGIN_URL, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    // Check if the response status is 200 or 204
    if (response.status === 200 || response.status === 204) {
        // Call getOperatorData and return its result
        return await getOperatorData();
    } else {
        // Handle other response statuses if needed
        // For now, just return the response data
        return response.data;
    }
}

export const getOperatorData = async () => {
    const response = await axios.get(`/api/users/me`);
    return response.data;
}
export const logoutDataBase = async () => {
    const response = await axios.post(`/api/auth/logout`);
    return response.data;
}



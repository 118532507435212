export interface updateNotificationState {
    updateNotificationData: any;
    response: boolean;
    error: null | string;
}


export enum UpdateNotificationActionTypes {
    FETCH_UPDATE_NOTIFICATION_DATA = "FETCH_UPDATE_NOTIFICATION_DATA",
    FETCH_UPDATE_NOTIFICATION_DATA_SUCCESS = "FETCH_UPDATE_NOTIFICATION_DATA_SUCCESS",
    FETCH_UPDATE_NOTIFICATION_DATA_ERROR = "FETCH_UPDATE_NOTIFICATION_DATA_ERROR",
    CLEAR_STATE_UPDATE_NOTIFICATION_DATA_ERROR = "CLEAR_STATE_UPDATE_NOTIFICATION_DATA_ERROR",
}

export type updateNotificationAction =
    | { type: UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA }
    | { type: UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA_SUCCESS; payload: any[] }
    | { type: UpdateNotificationActionTypes.FETCH_UPDATE_NOTIFICATION_DATA_ERROR; payload: string }
    | { type: UpdateNotificationActionTypes.CLEAR_STATE_UPDATE_NOTIFICATION_DATA_ERROR; payload?: any; };
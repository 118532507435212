import {CountriesState} from "../../types/sportsdata/countriesTypes";
import {CountriesActionTypes} from "../../types/sportsdata/countriesTypes";
import {CountriesAction} from "../../types/sportsdata/countriesTypes";


const initialState: CountriesState = {
    countriesData: [],
    loading: false,
    error: null,
};

export const countriesReducer = (state = initialState, action: CountriesAction): CountriesState => {
    switch (action.type) {
        case CountriesActionTypes.FETCH_COUNTRIES_DATA:
            return { ...state, loading: true, error: null, countriesData: [] };
        case CountriesActionTypes.FETCH_COUNTRIES_DATA_SUCCESS:
            return { ...state, loading: false, error: null, countriesData: action.payload };
        case CountriesActionTypes.FETCH_COUNTRIES_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, countriesData: [] };
        default:
            return state;
    }
};


/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core';
import {SportsdataPage} from "../components/SportsdataPage";

const Sportsdata: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Sportsdata</PageTitle>
            <SportsdataPage />
        </>
    )
}

export {Sportsdata}

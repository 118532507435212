import {FC} from "react";

const SportsdataPage: FC = () => (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12 mb-xl-12'>
                Index Sportsdata module page
            </div>
            {/* end::Col */}

        </div>
        {/* end::Row */}
    </>
)

export {SportsdataPage};
import {Dispatch} from "redux";
import { SuperbetsAction, SuperbetsActionTypes } from "../../../types/composer/Superbet/superbetsTypes";
import axios from "axios";


export const getSuperbetsData = (operator: string): any => {
    const apiURL = operator ? `/api/composer/superbets?operator=${operator}` : `/api/composer/superbets`;
    return async (dispatch: Dispatch<SuperbetsAction>) => {
        try {
            dispatch({type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA});
            const response = await axios({
                method: 'get',
                url: apiURL,
                withCredentials: false
            });
            dispatch({type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_ERROR, payload: 'Something wend wrong check Superbets action controller'});
        }
    }
}
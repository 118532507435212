export interface ResultRemoveGoalState {
    resultRemoveGoalData: any;
    loading: boolean;
    error: null | string;
}


export enum ResultRemoveGoalActionTypes {
    FETCH_RESULT_REMOVE_GOAL_DATA = "FETCH_RESULT_REMOVE_GOAL_DATA",
    FETCH_RESULT_REMOVE_GOAL_DATA_SUCCESS = "FETCH_RESULT_REMOVE_GOAL_DATA_SUCCESS",
    FETCH_RESULT_REMOVE_GOAL_DATA_ERROR = "FETCH_RESULT_REMOVE_GOAL_DATA_ERROR",
}

export type ResultRemoveGoalAction =
    | { type: ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA }
    | { type: ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA_SUCCESS; payload: any[] }
    | { type: ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA_ERROR; payload: string };
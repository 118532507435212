import {Dispatch} from "redux";
import {dailySalesCurrentMonthActionTypes, dailySalesLastSevenDaysAction} from "../../types/dashboard/dailySalesCurrentMonth";
import axios from "axios";


export const dailySalesCurrentMonth = (operator:string): any => {
    return async (dispatch: Dispatch<dailySalesLastSevenDaysAction>) => {
        try {
            dispatch({type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_sales_current_month_cumulative?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_SEVEN_DAYS_DATA_ERROR, payload: 'Something wend wrong check Profile action controller'});
        }
    }
}
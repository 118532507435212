import {Dispatch} from "redux";
import {JackpotRemovingAction, JackpotRemovingActionTypes} from "../../../types/composer/jackpot/jackpotRemoveTypes";
import axios from "axios";


export const jackpotRemovingData = (id:string): any => {
    return async (dispatch: Dispatch<JackpotRemovingAction>) => {
        try {
            dispatch({type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA});
            const response = await axios({
                method: 'delete',
                url: `/api/composer/jackpots/${id}`,
                withCredentials: false
            });
            dispatch({type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: JackpotRemovingActionTypes.REMOVE_JACKPOT_DATA_ERROR, payload: 'Something wend wrong check Jackpot Removing action controller'});
        }
    }
}
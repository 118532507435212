export interface dailySalesCurrentMonthState {
    dailySalesCurrentMonthData: any;
    loading: boolean;
    error: null | string;
}


export enum dailySalesCurrentMonthActionTypes {
    FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA = "FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA",
    FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA_SUCCESS = "FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA_SUCCESS",
    FETCH_DAIYLY_SALES_CURRENT_MONTH_SEVEN_DAYS_DATA_ERROR = "FETCH_DAIYLY_SALES_CURRENT_MONTH_SEVEN_DAYS_DATA_ERROR",
}

export type dailySalesLastSevenDaysAction =
    | { type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA }
    | { type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_DAYS_DATA_SUCCESS; payload: any[] }
    | { type: dailySalesCurrentMonthActionTypes.FETCH_DAIYLY_SALES_CURRENT_MONTH_SEVEN_DAYS_DATA_ERROR; payload: string };


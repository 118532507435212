import {TimezonesAction, TimezonesActionTypes, TimezonesState} from "../../types/admins/timezones";

const initialState: TimezonesState = {
    timezonesData: {},
    loading: false,
    error: null,
};

export const timezonesReducer = (state = initialState, action: TimezonesAction): TimezonesState => {
    switch (action.type) {
            case TimezonesActionTypes.FETCH_TIMEZONES_DATA:
            return { ...state, loading: true, error: null, timezonesData: {} };
        case TimezonesActionTypes.FETCH_TIMEZONES_DATA_SUCCESS:
            return { ...state, loading: false, error: null, timezonesData: action.payload };
        case TimezonesActionTypes.FETCH_TIMEZONES_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, timezonesData: {} };
        default:
            return state;
    }
};

import {ResultRemoveGoalState, ResultRemoveGoalAction, ResultRemoveGoalActionTypes} from "../../types/sportsdata/resultRemoveGoalTypes";


const initialState: ResultRemoveGoalState = {
    resultRemoveGoalData: [],
    loading: false,
    error: null,
};

export const resultRemoveGoalReducer = (state = initialState, action: ResultRemoveGoalAction): ResultRemoveGoalState => {
    switch (action.type) {
        case ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA:
            return { ...state, loading: true, error: null, resultRemoveGoalData: [] };
        case ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA_SUCCESS:
            return { ...state, loading: false, error: null, resultRemoveGoalData: action.payload };
        case ResultRemoveGoalActionTypes.FETCH_RESULT_REMOVE_GOAL_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, resultRemoveGoalData: [] };
        default:
            return state;
    }
};

import {OperatorsAction, OperatorsActionTypes, OperatorsState} from "../../../types/admins/operators/operatorsTypes";

const initialState: OperatorsState = {
    operatorsData: [],
    loading: false,
    error: null,
};

export const operatorsReducer = (state = initialState, action: OperatorsAction): OperatorsState => {
    switch (action.type) {
            case OperatorsActionTypes.FETCH_OPERATORS_DATA:
            return { ...state, loading: true, error: null, operatorsData: [] };
        case OperatorsActionTypes.FETCH_OPERATORS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, operatorsData: action.payload };
        case OperatorsActionTypes.FETCH_OPERATORS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, operatorsData: [] };
        default:
            return state;
    }
};

import {QuicktipRemovingActionTypes, QuicktipRemovingAction, QuicktipRemovingState} from "../../../types/composer/Quicktip/quicktipRemoveTypes";

const initialState: QuicktipRemovingState = {
    quicktipRemovingData: [],
    loading: false,
    error: null,
};

export const quicktipRemovingReducer = (state = initialState, action: QuicktipRemovingAction): QuicktipRemovingState => {
    switch (action.type) {
        case QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA:
            return { ...state, loading: true, error: null, quicktipRemovingData: [] };
        case QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quicktipRemovingData: action.payload };
        case QuicktipRemovingActionTypes.FETCH_QUICKTIP_REMOVING_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quicktipRemovingData: [] };
        default:
            return state;
    }
};

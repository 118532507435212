import {Dispatch} from "redux";
import {QuicktipCreateActionTypes, QuicktipCreateAction} from "../../../types/composer/Quicktip/quicktipCreate";
import axios from "axios";


export const quicktipCreateData = (data: object): any => {
    return async (dispatch: Dispatch<QuicktipCreateAction>) => {
        try {
            dispatch({type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA});
            const response = await axios({
                method: 'post',
                url: '/api/composer/quicktips',
                data:data,
                withCredentials: false
            });
            dispatch({type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_SUCCESS, payload: response.data})
        }catch (error: any) {
            let errorMessage = 'Something went wrong. Please try again.';

            if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail.map((err: any) => err.msg).join(' ');
            } else if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            dispatch({
                type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CRATE_DATA_ERROR,
                payload: errorMessage
            });
        }
    }
};
export const clearQuicktipCreateDataError = () => {

    return (dispatch: Dispatch<QuicktipCreateAction>) => {
        dispatch({
            type: QuicktipCreateActionTypes.CLEAR_QUICKTIP_CRATE_DATA_ERROR,
            payload: {}
        });
    };
};
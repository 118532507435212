import {Dispatch} from "redux";
import {
    dailyTicketsLastSevenDaysActionTypes,
    dailyTicketsLastSevenDaysAction
} from "../../types/dashboard/dailyTicketsLastSevenDays";
import axios from "axios";

export const dailyTicketsLastSevenDays = (operator:string): any => {
    return async (dispatch: Dispatch<dailyTicketsLastSevenDaysAction>) => {
        try {
            dispatch({type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/widgets/daily_tickets_last_seven_days?operator=${operator}`,
                withCredentials: false
            });
            dispatch({type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_LAST_SEVEN_DAYS_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: dailyTicketsLastSevenDaysActionTypes.FETCH_DAIYLY_TICKETS_TODAY_LAST_SEVEN_DAYS_DATA_ERROR, payload: 'Something wend wrong check Profile action controller'});
        }
    }
}
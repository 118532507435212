import {ErrorLogsAction, ErrorLogsActionTypes, ErrorLogsState} from "../../types/composer/errorLogsTypes";


const initialState: ErrorLogsState = {
    errorLogsData: [],
    loading: false,
    error: null,
};

export const errorLogsReducer = (state = initialState, action: ErrorLogsAction): ErrorLogsState => {
    switch (action.type) {
        case ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA:
            return { ...state, loading: true, error: null, errorLogsData: [] };
        case ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, errorLogsData: action.payload };
        case ErrorLogsActionTypes.FETCH_ERROR_LOGS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, errorLogsData: [] };
        default:
            return state;
    }
};

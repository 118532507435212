export interface CountriesState {
    countriesData: any[];
    loading: boolean;
    error: null | string;
}


export enum CountriesActionTypes {
    FETCH_COUNTRIES_DATA = "FETCH_COUNTRIES_DATA",
    FETCH_COUNTRIES_DATA_SUCCESS = "FETCH_COUNTRIES_DATA_SUCCESS",
    FETCH_COUNTRIES_DATA_ERROR = "FETCH_COUNTRIES_DATA_ERROR",
}

export type CountriesAction =
    | { type: CountriesActionTypes.FETCH_COUNTRIES_DATA }
    | { type: CountriesActionTypes.FETCH_COUNTRIES_DATA_SUCCESS; payload: any[] }
    | { type: CountriesActionTypes.FETCH_COUNTRIES_DATA_ERROR; payload: string };
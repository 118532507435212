import {Dispatch} from "redux";
import {EditProfileActionTypes, EditProfileAction} from "../../../types/admins/profile/editProfileTypes";
import axios from "axios";

export const editProfileData = (data: any): any => {
    return async (dispatch: Dispatch<EditProfileAction>) => {
        try {
            dispatch({type: EditProfileActionTypes.EDIT_PROFILE_DATA});
            const response = await axios({
                method: 'patch',
                url: `/api/users/me`,
                data: data,
                withCredentials: false
            });
            dispatch({type: EditProfileActionTypes.EDIT_PROFILE_DATA_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: EditProfileActionTypes.EDIT_PROFILE_DATA_ERROR, payload: 'Something wend wrong check Edite Profile action controller'});
        }
    }
}
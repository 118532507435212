import {EditProfileAction, EditProfileActionTypes, EditProfileState} from "../../../types/admins/profile/editProfileTypes";

const initialState: EditProfileState = {
    editProfileData: [],
    loading: false,
    error: null,
};

export const editeProfileReducer = (state = initialState, action: EditProfileAction): EditProfileState => {
    switch (action.type) {
            case EditProfileActionTypes.EDIT_PROFILE_DATA:
            return { ...state, loading: true, error: null, editProfileData: [] };
        case EditProfileActionTypes.EDIT_PROFILE_DATA_SUCCESS:
            return { ...state, loading: false, error: null, editProfileData: action.payload };
        case EditProfileActionTypes.EDIT_PROFILE_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, editProfileData: [] };
        default:
            return state;
    }
};

import {AdminOperatorActionTypes, AdminOperatorAction, AdminOperatorState} from "../../../../types/admins/operators/admin/adminOperatorTypes";

const initialState: AdminOperatorState = {
    adminOperatorData: [],
    loading: false,
    error: null,
};

export const adminOperatorReducer = (state = initialState, action: AdminOperatorAction): AdminOperatorState => {
    switch (action.type) {
            case AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA:
            return { ...state, loading: true, error: null, adminOperatorData: [] };
        case AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, error: null, adminOperatorData: action.payload };
        case AdminOperatorActionTypes.FETCH_ADMIN_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, adminOperatorData: [] };
        default:
            return state;
    }
};

export interface RemoveSuperbetState {
    removeSuperbetData: any[];
    loading: boolean;
    error: null | string;
}


export enum RemoveSuperbetActionTypes {
    FETCH_REMOVE_SUPERBET_DATA = "FETCH_REMOVE_SUPERBET_DATA",
    FETCH_REMOVE_SUPERBET_DATA_SUCCESS = "FETCH_REMOVE_SUPERBET_DATA_SUCCESS",
    FETCH_REMOVE_SUPERBET_DATA_ERROR = "FETCH_REMOVE_SUPERBET_DATA_ERROR",
}

export type RemoveSuperbetAction =
    | { type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA }
    | { type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_SUCCESS; payload: any[] }
    | { type: RemoveSuperbetActionTypes.FETCH_REMOVE_SUPERBET_DATA_ERROR ; payload: string };


interface ErrorResponse {
    code: number;
    message: string;
}

export interface UpdateSuperbetState {
    updateSuperbetResponce: any[];
    loading: boolean;
    error: ErrorResponse | null;
}

export enum UpdateSuperbetActionTypes {
    FETCH_UPDATE_SUPERBET_DATA = "FETCH_UPDATE_SUPERBET_DATA",
    FETCH_UPDATE_SUPERBET_DATA_SUCCESS = "FETCH_UPDATE_SUPERBET_DATA_SUCCESS",
    FETCH_UPDATE_SUPERBET_DATA_ERROR = "FETCH_UPDATE_SUPERBET_DATA_ERROR",
}

export type UpdateSuperbetAction =
    | { type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA }
    | { type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_SUCCESS; payload: any[] }
    | { type: UpdateSuperbetActionTypes.FETCH_UPDATE_SUPERBET_DATA_ERROR ; payload: ErrorResponse };

import React from "react";
import {useIntl} from 'react-intl';
import {SidebarMenuItemWithSub} from "../SidebarMenuItemWithSub";
import {SidebarMenuItem} from "../SidebarMenuItem";
import {getStoragedCurrentOperator, useAuth} from "../../../../../../app/modules/auth";
import {getSessionStoragedCurrentOperator} from "../../../../../../app/modules/auth";
import './SidebarMenuComposer.css'

const SidebarMenuComposer = () => {
    const { currentUser } = useAuth();
    const sessionOperator = getSessionStoragedCurrentOperator();
    const currentOperator = getStoragedCurrentOperator();
    const intl = useIntl();
    return(
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2 bet-composer-title'>
                    <span className='menu-section text-muted text-uppercase fs-9 ls-1'>{intl.formatMessage({id: 'SIDEBAR.TITLE.BETCOMPOSER'})}</span>
                </div>
            </div>
            {
                currentUser?.is_superuser ? null
                    :
                    <SidebarMenuItemWithSub
                        to='/composer/bet-card'
                        title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.BETCARD'})}
                        icon='abstract-26'
                        fontIcon='bi-person'
                    >
                        <SidebarMenuItem
                            to='composer/bet-card/quicktip'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKTIP'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/smart-bet'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SMARTBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/superbets'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SUPERBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/quickbet'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/jackpot'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.JACKPOT'})}
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
            }
            {
                sessionOperator && currentUser?.is_superuser ?
                    <SidebarMenuItemWithSub
                        to='/composer/bet-card'
                        title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.BETCARD'})}
                        icon='abstract-26'
                        fontIcon='bi-person'
                    >
                        <SidebarMenuItem
                            to='composer/bet-card/quicktip'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKTIP'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/smart-bet'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SMARTBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/superbets'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SUPERBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/quickbet'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKBET'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/bet-card/jackpot'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.JACKPOT'})}
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    : null
            }
            {
                currentUser?.is_superuser || currentOperator ?
                    <SidebarMenuItemWithSub
                        to='/composer/logs'
                        title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATIONTITLE.LOGS'})}
                        icon='some-files'
                        fontIcon='bi-person'
                    >
                        <SidebarMenuItem
                            to='composer/logs/live-log'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.LIVELOG'})}
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='composer/logs/error-log'
                            title={intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.ERRORLOG'})}
                            hasBullet={true}
                        />

                    </SidebarMenuItemWithSub>
                    :null
            }
        </>

    );
};

export {SidebarMenuComposer};
import {updateLeagueAction, updateLeagueActionTypes, updateLeagueState} from "../../types/sportsdata/updateLeagueTypes";

const initialState: updateLeagueState = {
    updateLeagueData: [],
    responceStatus: false,
    error: null,
};

export const updateLeagueReducer = (state = initialState, action: updateLeagueAction): updateLeagueState => {
    switch (action.type) {
        case updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA:
            return { ...state, responceStatus: true, error: null, updateLeagueData: [] };
        case updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA_SUCCESS:
            return { ...state, responceStatus: false, error: null, updateLeagueData: action.payload };
        case updateLeagueActionTypes.FETCH_UPDATE_LEAGUE_DATA_ERROR:
            return { ...state, responceStatus: false, error: action.payload, updateLeagueData: [] };
        default:
            return state;
    }
};